import { S3File } from "../Models";
import { KTSVG } from "../../../../_metronic/helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useAuth } from "../../auth";

interface PropsDeletable {
  file: S3File
  deletable: true
  onDelete: (uuid: string) => void
  onUndo: (uuid: string) => void
}

interface PropsNotDeletable {
  file: S3File
  deletable: false
}

const File = (props: PropsDeletable | PropsNotDeletable) => {
  const [deleting, setDeleting] = useState<boolean>(false)
  const auth = useAuth()
  const disableUpdate = !auth.hasPermission('invoice-update')

  useEffect(() => {
    if (!props.deletable) return

    if (deleting) {
      props.onDelete(props.file.uuid)
    } else {
      props.onUndo(props.file.uuid)
    }
  }, [deleting])

  const onDeleteHandler = () => {
    if (!props.deletable) return

    setDeleting(!deleting)
  }

  return (
    <>
      <div className="row my-2">
        <div className="col">
          <a
            className='btn btn-light px-4 py-3 d-flex justify-content-between align-items-center text-dark'
            href={deleting ? '' : props.file.temporary_url}
            target='_blank'
            style={
              deleting
                ? {
                  textDecoration: 'line-through',
                  pointerEvents: 'none'
                }
                : {}
            }
          >
            <span className='text-start'>{props.file.file_name}</span>
            {!deleting && <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='ms-3 svg-icon-3 me-0' />}
          </a>
        </div>
        {
          !disableUpdate && props.deletable &&
          <div className="col-auto ps-0">
            <OverlayTrigger overlay={<Tooltip>{deleting ? 'Restore' : 'Delete'}</Tooltip>} placement='right'>
              <button
                type='button'
                className={clsx(
                  'btn btn-icon btn-sm',
                  { 'btn-outline-danger': !deleting },
                  { 'btn-outline-primary': deleting }
                )}
                onClick={onDeleteHandler}
              >
                {
                  deleting
                    ? <i className="bi bi-arrow-counterclockwise fs-2 pt-1"></i>
                    : <KTSVG path='media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                }
              </button>
            </OverlayTrigger>
          </div>
        }
      </div>
    </>
  )
}

export default File