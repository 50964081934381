import { ResponsePagination } from '../../helpers/types/api-types';
import { PaginationPropsParam } from './Models';
import PaginationList from './PaginationList';
import PaginationPerPage from './PaginationPerPage';

export const initialPagination: ResponsePagination = {
  current_page: 1,
  per_page: 15,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1
}

const TablePagination = ({ pagination, onPaginate, showPerPageOptions }: PaginationPropsParam) => {
  const paginationHandler = (page: number) => {
    onPaginate({
      paginate: 1,
      per_page: pagination.per_page,
      page: page
    })
  }

  const setPerPageHandler = (perPage: number) => {
    onPaginate({
      paginate: 1,
      page: pagination.current_page,
      per_page: perPage
    })
  }

  return (
    <div className="card">
      <div className="pt-0 pb-3 card-body d-flex flex-md-row justify-content-between flex-wrap">
          <PaginationList
            pagination={pagination}
            onPaginate={paginationHandler}
          />
          {
            (showPerPageOptions === undefined || showPerPageOptions) && // enable per page options by default
              <PaginationPerPage
                pagination={pagination}
                onSetPerPage={setPerPageHandler}
              />
          }
      </div>
    </div>
  )
}

export default TablePagination
