export interface InvoiceRow { // for index table row
  invoice_code: string
  external_code: string
  agent_username: string
  site_code: string
  currency_code: string
  invoice_name: string
  invoice_content: string
  amount: number
  payment_status: PaymentStatus
  release_date_time: string
  created_by?: string
  created_at?: string
  updated_by?: string
  updated_at?: string
  bills: S3File[]
  receipts: S3File[]
}

export interface Invoice { // for editing
  code: string
  external_code: string
  site_code: string
  currency_code: string
  name: string
  amount: number
  content: string
  payment_status: PaymentStatus
  release_date_time: string
  remark?: string
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
  bills: S3File[]
  receipts: S3File[]
}

export interface InvoiceIndexParams {
  date_time_option: DateTimeOptions
  start_date_time: string
  end_date_time: string
  agent_id?: number
  site_codes?: string[]
  invoice_code?: string
  invoice_name?: string
  currency_codes?: string[]
  payment_status?: PaymentStatus
  created_by?: string
  paginate?: number
  per_page?: number
  page?: number
  sort?: string
  order?: 'asc' | 'desc' | ''
}

export interface S3File {
  uuid: string
  file_name: string
  temporary_url: string
}

export enum PaymentStatus {
  Unpaid,
  Paid
}

export namespace PaymentStatus {
  export function values(): number[] {
    return Object.values(PaymentStatus).filter((value): value is number => typeof value === 'number')
  }
}

export enum DateTimeOptions {
  Released,
  Created,
  Updated
}

export namespace DateTimeOptions {
  export function values(): number[] {
    return Object.values(DateTimeOptions).filter((value): value is number => typeof value === 'number')
  }

  export function getLabel(option: DateTimeOptions): string {
    return DateTimeOptions[option] + " At"
  }
}

export interface InvoiceStoreParams {
  site_code: string
  currency_code: string
  name: string
  invoice_number: string
  amount: number
  content: string
  release_date_time: string
  payment_status: PaymentStatus
  remark?: string
}

export interface InvoiceUpdateParams {
  invoice_code: string
  external_code: string
  site_code: string
  currency_code: string
  name?: string
  amount: number
  content?: string
  release_date_time?: string
  payment_status?: PaymentStatus
  remark?: string
}

export interface InvoiceDeleteParams {
  invoice_code: string
}

export interface Agent {
  id: number
  username: string
}

export interface Site {
  agent_id: number
  code: string
  currency_codes: string[]
}

export interface InvoiceFilesModalProps {
  showing: boolean
  type: 'bill' | 'receipt' | ''
  files: S3File[]
  name: string
  code: string
}
