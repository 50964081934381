import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormControl, Modal, Spinner } from "react-bootstrap";
import { createPortal } from "react-dom";
import * as Yup from "yup"
import { KTSVG } from "../../../../../_metronic/helpers";
import {
  checkRequestResponse,
  getSelectOptions,
  removeEmptyFilters,
  SelectOptionModel
} from "../../../../components/Helpers";
import MessageModal from "../../../../components/MessageModal";
import { MaintenanceStatus, SgpMaintenance, SgpMaintenanceSetParams, SgpMaintenanceUpdateParams } from "../../Models";
import { sgpMaintenanceGet, sgpMaintenanceSet, sgpMaintenanceUpdate } from "../../Requests";

const shape = {
  site_code: Yup.string().required(),
  game_provider_code: Yup.string().required(),
  status: Yup.number().integer().oneOf(MaintenanceStatus.values()).required(),
  maintain_from: Yup.date().required(),
  maintain_to: Yup.date().optional(),
  currency_code: Yup.string().optional(),
  category_code: Yup.string().length(2).optional(),
}

const validationSchemaSet = Yup.object().shape(shape)
const validationSchemaUpdate = Yup.object().shape({ maintenance_id: Yup.number().required(), ...shape })

const initialValuesSet: SgpMaintenanceSetParams = {
  site_code: "",
  game_provider_code: "",
  maintain_from: "",
  maintain_to: "",
  status: MaintenanceStatus.Active,
  category_code: "",
  currency_code: ""
}

const initialValuesUpdate: SgpMaintenanceUpdateParams = {
  maintenance_id: 0,
  site_code: "",
  game_provider_code: "",
  maintain_from: "",
  maintain_to: "",
  status: MaintenanceStatus.Active,
  category_code: "",
  currency_code: ""
}

const defaultSgpMaintenance: SgpMaintenance = {
  category_code: "",
  created_at: "",
  created_by: "",
  currency_code: "",
  game_provider_code: "",
  maintain_from: "",
  maintain_to: "",
  maintenance_id: 0,
  site_code: "",
  status: MaintenanceStatus.Active,
  updated_at: "",
  updated_by: ""
}

interface Props {
  maintenance_id: number
  isUpdate: boolean
  show: boolean
  onClose: () => void
  onSuccess: () => void
}

const getDefaultSgpMaintenance = (): SgpMaintenance => { // to reset with current time
  return {
    ...defaultSgpMaintenance,
    maintain_from: moment().format('YYYY-MM-DD HH:mm:00'),
    maintain_to: moment().add(1, 'hour').format('YYYY-MM-DD HH:mm:59'),
  }
}

const SgpMaintenanceModal = (props: Props) => {
  const [sgpMaintenance, setSgpMaintenance] = useState<SgpMaintenance>(getDefaultSgpMaintenance())
  const [untilFurtherNotice, setUntilFurtherNotice] = useState<boolean>(false)

  const [siteOptions, setSiteOptions] = useState<SelectOptionModel[]>([])
  const [gameProviderOptions, setGameProviderOptions] = useState<SelectOptionModel[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])
  const [categoryOptions, setCategoryOptions] = useState<SelectOptionModel[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validationSchema, setValidationSchema] = useState<any>()
  const [initialValues, setInitialValues] = useState<SgpMaintenanceSetParams | SgpMaintenanceUpdateParams>(initialValuesSet)

  useEffect(() => {
    if (props.show) {
      setIsLoading(true)
      loadSelectOptions()

      if (props.isUpdate && props.maintenance_id != 0) {
        void loadSgpMaintenanceDetails()
      } else {
        setSgpMaintenance(getDefaultSgpMaintenance())
      }

      setIsLoading(false)
    }
  }, [props.show])

  useEffect(() => {
    setInitialValues(props.isUpdate ? initialValuesUpdate : initialValuesSet)
    setValidationSchema(props.isUpdate ? validationSchemaUpdate : validationSchemaSet)
  }, [props.isUpdate])

  useEffect(() => {
    formik.setValues(sgpMaintenance)
  }, [sgpMaintenance])

  useEffect(() => {
    if (untilFurtherNotice) {
      formik.setFieldValue('maintain_to', '')
    } else {
      if (formik.values.maintain_from) {
        const maintain_to = moment(formik.values.maintain_from).add(1, 'hour').format('YYYY-MM-DD HH:mm:59')
        formik.setFieldValue('maintain_to', maintain_to)
      } else {
        formik.setFieldValue('maintain_to', sgpMaintenance.maintain_to)
      }
    }
  }, [untilFurtherNotice]);

  const loadSelectOptions = () => {
    setSiteOptions(getSelectOptions('site_options', false))
    setGameProviderOptions(getSelectOptions('game_provider_options', false))
    setCurrencyOptions(getSelectOptions('currency_options'))
    setCategoryOptions(getSelectOptions('game_category_options'))
  }

  const loadSgpMaintenanceDetails = async () => {
    try {
      const response = await sgpMaintenanceGet({ maintenance_id: props.maintenance_id })

      if (!checkRequestResponse(response)) {
        handleClose()
        MessageModal({ type: 'failed', messages: 'Error loading maintenance details.' })
        return
      }

      const maintenance = response.data.data

      for (let key in maintenance) {
        if (maintenance[key] === null) maintenance[key] = ''
      }

      setSgpMaintenance(maintenance)
    } catch (error) {
      console.error(error)
      MessageModal({ type: 'failed', messages: 'Error loading maintenance details.' })
    }
  }

  const handleClose = () => {
    props.onClose()
    formik.setStatus(null)
    formik.resetForm()
    setSgpMaintenance(getDefaultSgpMaintenance())
    setUntilFurtherNotice(false)
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        values.maintain_from = moment(values.maintain_from).format('YYYY-MM-DD HH:mm:00')
        values.maintain_to = values.maintain_to ? moment(values.maintain_to).format('YYYY-MM-DD HH:mm:59') : ''

        const params = removeEmptyFilters(values)

        let response;

        if (props.isUpdate) {
          response = await sgpMaintenanceUpdate(params)
        } else {
          response = await sgpMaintenanceSet(params)
        }

        if (!checkRequestResponse(response)) {
          setSubmitting(false)
          return
        }

        const successMessage = props.isUpdate ? 'Maintenance updated successfully.' : 'Maintenance set successfully.'
        MessageModal({ type: 'success', messages: successMessage })

        setSubmitting(false)
        props.onSuccess()
        handleClose()
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        MessageModal({ type: 'failed' })
      }
    }
  })

  return createPortal(
    <Modal
      id='kt_modal_sgp_maintenance'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={props.show}
      scrollable
      onHide={handleClose}
    >
      <div className='modal-header px-10'>
        <h2>{props.isUpdate ? 'Update Site Provider Maintenance' : 'New Site Provider Maintenance'}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <form
        id='sgp-maintenance-form'
        className='modal-body px-lg-10'
        onSubmit={formik.handleSubmit}
      >
        <div className="row mb-6">
          <div className="col-md-3">
            <label className='form-label required'>Site</label>
            <select
              className='form-select form-select-solid'
              {...formik.getFieldProps('site_code')}
              value={formik.values.site_code}
              disabled={props.isUpdate}
            >
              {!props.isUpdate && <option disabled value=''>Select Site</option>}
              {siteOptions.map(site => <option key={site.value} value={site.value}>{site.label}</option>)}
            </select>
          </div>
          <div className="col-md-3">
            <label className='form-label required'>Game Provider</label>
            <select
              className='form-select form-select-solid'
              {...formik.getFieldProps('game_provider_code')}
              value={formik.values.game_provider_code}
              disabled={props.isUpdate}
            >
              {!props.isUpdate && <option disabled value=''>Select Game Provider</option>}
              {gameProviderOptions.map(gameProvider =>
                <option key={gameProvider.value} value={gameProvider.value}>{gameProvider.label}</option>)}
            </select>
          </div>
          <div className="col-md-3">
            <label className='form-label required'>Maintain From (GMT +8)</label>
            <FormControl
              type='datetime-local'
              className='form-control-solid'
              step={1}
              {...formik.getFieldProps('maintain_from')}
              value={formik.values.maintain_from}
            />
          </div>
          <div className="col-md-3">
            <label className='form-label required'>Maintain To (GMT +8)</label>
            <FormControl
              type='datetime-local'
              className='form-control-solid'
              step={1}
              {...formik.getFieldProps('maintain_to')}
              value={untilFurtherNotice ? '' : formik.values.maintain_to}
              disabled={untilFurtherNotice}
            />
          </div>
        </div>
        <div className="row mb-6">
          <div className="col-md-3">
            <label className='form-label required'>Status</label>
            <select
              className='form-select form-select-solid'
              {...formik.getFieldProps('status')}
              value={formik.values.status}
            >
              {
                MaintenanceStatus.values()
                  .sort((a, b) => MaintenanceStatus[a].localeCompare(MaintenanceStatus[b]))
                  .map(status =>
                    <option
                      key={`status-${MaintenanceStatus[status]}`}
                      value={status}
                    >
                      {MaintenanceStatus[status]}
                    </option>
                  )
              }
            </select>
          </div>
          <div className="col-md-3">
            <label className='form-label'>Currency</label>
            <select
              className='form-select form-select-solid'
              {...formik.getFieldProps('currency_code')}
              value={formik.values.currency_code}
            >
              {currencyOptions.map(currency =>
                <option key={currency.value} value={currency.value}>{currency.label}</option>)}
            </select>
          </div>
          <div className="col-md-3">
            <label className='form-label'>Category</label>
            <select
              className='form-select form-select-solid'
              {...formik.getFieldProps('category_code')}
              value={formik.values.category_code}
            >
              {categoryOptions.map(category =>
                <option key={category.value} value={category.value}>{category.label}</option>)}
            </select>
          </div>
          <div className="col-md-3 d-flex justify-content-end" style={{ flexDirection: 'column' }}>
            <div className='d-flex align-items-center my-3'>
              <input
                id='until-further-notice'
                type='checkbox'
                className='form-check-input cursor-pointer me-4'
                checked={untilFurtherNotice}
                onChange={e => setUntilFurtherNotice(e.currentTarget.checked)}
              />
              <label
                className="form-label user-select-none cursor-pointer m-0"
                htmlFor="until-further-notice"
              >
                Until Further Notice
              </label>
            </div>
          </div>
        </div>
      </form>

      <div className="modal-footer">
        <button
          type='button'
          disabled={formik.isSubmitting || Object.keys(formik.errors).length !== 0}
          className='btn btn-sm btn-primary me-3'
          onClick={() => formik.handleSubmit()}
        >
          {
            isLoading
              ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />Loading...</>
              : (props.isUpdate ? 'Save Changes' : 'Create')
          }
        </button>
        <button
          type='button'
          className='btn btn-sm btn-light-primary'
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default SgpMaintenanceModal
