import React from "react";
import { RequestSort } from "./types/api-types";

export const isEmpty = (value: any): boolean => { // true if '', null, undefined, [], {}
  if (value === '' || value === null || value === undefined) return true

  if (Array.isArray(value) && value.length === 0) return true

  return typeof value === 'object' && Object.keys(value).length === 0;
}

export const removeEmptyAttributes = <T extends object, >(targetObject: T): Partial<T> => {
  const result: Partial<T> = { ...targetObject }

  for (const key in result) {
    if (!result.hasOwnProperty(key)) continue

    if (isEmpty(result[key])) delete result[key]
  }

  return result
}

/**
 * Check if two arrays have same items. Ignore order.
 * <br>
 * Current limitation:
 * - Can only compare string[] or number[] arrays
 * - May be refined to a better approach.
 */
export const isEqualArray = <T extends string | number>(array1: T[], array2: T[]): boolean => {
  if (array1.length !== array2.length) return false

  const count1: { [value: string | number]: number } = {}
  const count2: { [value: string | number]: number } = {}

  array1.forEach((value: T) => count1[value] = (count1[value] ?? 0) + 1)
  array2.forEach((value: T) => count1[value] = (count2[value] ?? 0) + 1)

  for (const value in count1) {
    if (!(value in count2) || count1[value] !== count2[value]) {
      return false
    }
  }

  return true
}

export const getSort = <Column extends string = string>(event: React.MouseEvent<HTMLElement>): RequestSort<Column> => {
  const columnHeader: HTMLElement = event.currentTarget
  const currentOrder: string = columnHeader.getAttribute('data-order')!
  const targetColumn: string = columnHeader.getAttribute('data-sort')!

  let newSort: Column | '' = targetColumn as Column
  let newOrder: RequestSort['order']

  document.querySelectorAll<HTMLElement>('[data-sort]')
    .forEach((element: HTMLElement) => {
      element.setAttribute('data-order', '')
    })

  // asc -> desc -> '' -> asc -> ...
  switch (currentOrder) {
    case 'asc':
      newOrder = 'desc'
      break
    case 'desc':
      newOrder = ''
      newSort = ''
      break
    default:
      newOrder = 'asc'
  }

  columnHeader.setAttribute('data-order', newOrder)

  return { sort: newSort, order: newOrder }
}
