import moment from "moment";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { KTSVG } from "../../../../_metronic/helpers";
import {
  checkRequestResponse,
  getSelectOptions,
  removeEmptyFilters,
  SelectOptionModel
} from "../../../components/Helpers";
import MessageModal from "../../../components/MessageModal";
import { TableContent } from "../../../components/Table";
import TableFilter from "../../../components/table-filter/components/TableFilter";
import ToolBarButton from "../../../components/ToolBarButton";
import { DATE_TIME_FORMAT } from "../../../helpers/constants";
import { getSort } from "../../../helpers/functions";
import { ApiResponse, FinalRequest, RequestPagination, RequestSort, ResponsePagination } from "../../../helpers/types/api-types";
import { MaintenanceStatus, SgpMaintenance, SgpMaintenanceIndexParams } from "../Models";
import { sgpMaintenanceIndex, sgpMaintenanceIndexUrl } from "../Requests";
import SgpMaintenanceModal from "./modals/SgpMaintenanceModal";
import SgpMaintenanceIndexRow from "./SgpMaintenanceIndexRow";
import TablePagination, { initialPagination } from "../../../components/TablePagination/TablePagination";

const SgpMaintenanceTable = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [siteOptions, setSiteOptions] = useState<SelectOptionModel[]>([])
  const [gameProviderOptions, setGameProviderOptions] = useState<SelectOptionModel[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])
  const [categoryOptions, setCategoryOptions] = useState<SelectOptionModel[]>([])
  const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
  const [filters, setFilters] = useState<FinalRequest<SgpMaintenanceIndexParams>>({})
  const [sort, setSort] = useState<RequestSort>()
  const [sgpMaintenances, setSgpMaintenances] = useState<SgpMaintenance[]>([])

  // modal
  const [selectedMaintenanceId, setSelectedMaintenanceId] = useState<number>(0)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [maintenanceModalShowing, setMaintenanceModalShowing] = useState<boolean>(false)

  useEffect(() => {
    loadSelectOptions()
  }, []);

  const loadSelectOptions = () => {
    setSiteOptions(getSelectOptions('site_options'))
    setGameProviderOptions(getSelectOptions('game_provider_options'))
    setCurrencyOptions(getSelectOptions('currency_options'))
    setCategoryOptions(getSelectOptions('game_category_options'))
  }

  const showMaintenanceModal = (maintenance_id: number, isUpdate: boolean) => {
    setSelectedMaintenanceId(maintenance_id)
    setIsUpdate(isUpdate)
    setMaintenanceModalShowing(true)
  }

  const closeMaintenanceModal = () => {
    setSelectedMaintenanceId(0)
    setIsUpdate(false)
    setMaintenanceModalShowing(false)
  }
  const onFilterHandler = async (params: FinalRequest<SgpMaintenanceIndexParams>) => {
    setIsLoading(true)

    try {
      const response = await sgpMaintenanceIndex(removeEmptyFilters(params))

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        return
      }

      const apiResponse: ApiResponse<SgpMaintenance[]> = response.data

      onResponseHandler(apiResponse, params)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      MessageModal({ type: 'failed' })
    }
  }

  const onSortHandler = (event: React.MouseEvent<HTMLElement>) => {
    setSort(getSort(event))
  }

  const onResponseHandler = (response: ApiResponse<SgpMaintenance[]>, params: FinalRequest<SgpMaintenanceIndexParams>) => {
    let maintenances: SgpMaintenance[]
    let pagination: ResponsePagination

    if ('paginations' in response.data) {
      maintenances = response.data.rows ?? []
      pagination = response.data.paginations
    } else {
      maintenances = response.data
      pagination = initialPagination
    }

    setSgpMaintenances(maintenances)
    setPagination(pagination)
    setFilters(params)
    setIsLoading(false)
  }

  return (
    <>
      <ToolBarButton>
        <button
          className='btn btn-primary btn-sm'
          onClick={() => showMaintenanceModal(0, false)}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          New Maintenance
        </button>
      </ToolBarButton>

      <TableFilter<SgpMaintenanceIndexParams, SgpMaintenance[]>
        fields={[
          {
            label: 'Site',
            name: 'site_code',
            type: 'select',
            options: siteOptions
          },
          {
            label: 'Game Provider',
            name: 'game_provider_code',
            type: 'select',
            options: gameProviderOptions
          },
          {
            label: 'Start Date Time',
            name: 'start_date_time',
            type: 'date-time',
            defaultValue: moment().startOf("day").format(DATE_TIME_FORMAT)
          },
          {
            label: 'End Date Time',
            name: 'end_date_time',
            type: 'date-time',
            defaultValue: moment().endOf("day").format(DATE_TIME_FORMAT)
          },
          {
            label: 'Status',
            name: 'status',
            type: 'select',
            options: [
              { value: '', label: 'All' },
              { value: MaintenanceStatus.Active.toString(), label: MaintenanceStatus[MaintenanceStatus.Active] },
              { value: MaintenanceStatus.Inactive.toString(), label: MaintenanceStatus[MaintenanceStatus.Inactive] },
            ]
          },
          {
            label: 'Currency',
            name: 'currency_code',
            type: 'select',
            options: currencyOptions
          },
          {
            label: 'Game Category',
            name: 'category_code',
            type: 'select',
            options: categoryOptions
          },
        ]}
        validationSchema={Yup.object().shape({
          site_code: Yup.string().optional(),
          game_provider_code: Yup.string().optional(),
          currency_code: Yup.string().optional(),
          category_code: Yup.string().length(2).optional(),
          status: Yup.number().integer().oneOf(MaintenanceStatus.values()).optional(),
          start_date_time: Yup.date().required('Start date time is required'),
          end_date_time: Yup.date().required('End date time is required'),
        })}
        apiUrl={sgpMaintenanceIndexUrl}
        onSubmit={() => setIsLoading(true)}
        onResponse={onResponseHandler}
        sort={sort}
        submitOnInit={true}
        pagination={{
          paginate: 1,
          per_page: pagination.per_page,
          page: pagination.current_page
        }}
      />

      <TableContent
        name='maintenances'
        columns={[
          { title: 'Site', name: 'site_code', sort: true },
          { title: 'Game Provider', name: 'game_provider_code', sort: true },
          { title: 'Currency', name: 'currency_code', sort: false },
          { title: 'Category', name: 'category_code', sort: false },
          { title: 'Start (GMT +8)', name: 'maintain_from', sort: true },
          { title: 'End (GMT +8)', name: 'maintain_to', sort: true },
          { title: 'Status', name: 'status', sort: true },
          { title: 'Created By/At', name: 'created_at', sort: true },
          { title: 'Updated By/At', name: 'updated_at', sort: true },
          { title: 'Action', name: 'action', sort: false },
        ]}
        contents={sgpMaintenances}
        isLoading={isLoading}
        filters={filters}
        sortHandler={onSortHandler}
        tableRows={!isLoading && sgpMaintenances.map(maintenance =>
          <SgpMaintenanceIndexRow
            sgpMaintenance={maintenance}
            onUpdate={() => showMaintenanceModal(maintenance.maintenance_id, true)}
          />
        )
        }
        filterTable={onFilterHandler}
        setFilters={setFilters}
      />

{
        pagination &&
          <TablePagination
            pagination={pagination}
            onPaginate={(paginationData: RequestPagination) => {
              onFilterHandler({
                ...filters,
                ...paginationData
              })
              setFilters(prevFilters => ({
                ...prevFilters,
                ...paginationData
              }))
          }}
        />
      }

      <SgpMaintenanceModal
        isUpdate={isUpdate}
        maintenance_id={selectedMaintenanceId}
        show={maintenanceModalShowing}
        onClose={closeMaintenanceModal}
        onSuccess={() => onFilterHandler(filters)}
      />
    </>
  )
}

export default SgpMaintenanceTable
