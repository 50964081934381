import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { TestSession, TestSessionStatus } from "../TestSessionModels";

const TestSessionStatusBadge = ({ testSession }: { testSession: TestSession }) => {
  let badgeType: string, text: string, icon: JSX.Element

  // TODO: could be further refined with bootstrap progress bar
  if (testSession.status == TestSessionStatus.Scheduled) {
    badgeType = 'primary'
    text = 'Scheduled'
    icon = <i className='fas fa-hourglass text-light ms-2'></i>
  } else if (testSession.status == TestSessionStatus.Running) {
    badgeType = 'warning'
    text = 'Running'
    icon = <div className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></div>
  } else {
    if (testSession.failed_count == 0) {
      badgeType = 'success'
      text = 'Passed'
      icon = <i className='fas fa-check text-light ms-2'></i>
    } else {
      badgeType = 'danger'
      text = testSession.failed_count + ' test(s) failed'
      icon = <i className='fas fa-times text-light ms-2'></i>
    }
  }

  return (
    <>
      <div className={`badge badge-${badgeType}`}>
        {text}{icon}
      </div>
      {
        testSession.status == TestSessionStatus.Running && testSession.failed_count > 0 &&
        <OverlayTrigger
          placement='top'
          overlay={<Tooltip>{testSession.failed_count} test(s) failed.</Tooltip>}
        >
          <span>
            <KTSVG path='/media/icons/duotune/general/gen044.svg' className='ms-2 svg-icon-2 text-danger' />
          </span>
        </OverlayTrigger>
      }
    </>
  )
}

export default TestSessionStatusBadge
