import React, {useState} from 'react'

const ToolbarContext = React.createContext({
  toolbar: <div />,
  onSetToolbar: (toolbarElement: any) => {}
})

export const ToolbarContextProvider = (props: any) => {
  const [toolbar, setToolbar] = useState(<div />)

  const toolbarSetHandler = (toolbarElement: any) => {
    setToolbar(toolbarElement)
  }

  return (
    <ToolbarContext.Provider value={{toolbar: toolbar, onSetToolbar: toolbarSetHandler}}>
      {props.children}
    </ToolbarContext.Provider>
  )
}

export default ToolbarContext