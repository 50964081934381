import moment from "moment";
import React, { useEffect, useState } from "react";
import { DATE_TIME_FORMAT } from "../../../helpers/constants";
import { DateTimeShortcuts, SHORTCUTS_BY_TYPE } from "../date-time-shortcut-constants";
import { DateTimeShortcutProps, DateTimeShortcutType } from "../date-time-shortcut-types";
import DateTimeShortcutButton from "./DateTimeShortcutButton";

const DateTimeShortcut = (props: DateTimeShortcutProps) => {
  const {
    onChange,
    value,
    defaultValue,
    dateTimeFormat,
    types,
  } = props

  const [shortcuts, setShortcuts] = useState<DateTimeShortcuts[]>(Object.values(DateTimeShortcuts))
  const [selectedShortcut, setSelectedShortcut] = useState<DateTimeShortcuts>(DateTimeShortcuts.CustomRange)

  useEffect(() => {
    if (types === undefined) {
      setShortcuts(Object.values(DateTimeShortcuts))
      return
    }

    const shortcuts: DateTimeShortcuts[] = [DateTimeShortcuts.CustomRange]
    types.forEach((type: DateTimeShortcutType) => shortcuts.push(...SHORTCUTS_BY_TYPE[type]))
    setShortcuts(shortcuts)
  }, [types])

  useEffect(() => {
    if (value === undefined) return

    const shortcut: DateTimeShortcuts = getShortcut(value.start, value.end, dateTimeFormat)
    setSelectedShortcut(shortcut)
  }, [value])

  const selectShortcut = (shortcut: DateTimeShortcuts) => {
    const [start, end] = getDateRange(shortcut, dateTimeFormat)
    setSelectedShortcut(shortcut)

    if (value !== undefined && value.start === start && value.end === end) return

    setSelectedShortcut(shortcut)
    onChange({ start: start, end: end })
  }

  return (
    <div className='dt-shortcut d-flex gap-2'>
      {shortcuts.map((shortcut: DateTimeShortcuts) => (
        <DateTimeShortcutButton
          key={shortcut}
          dateTimeShortcut={shortcut}
          onClick={() => selectShortcut(shortcut)}
          selected={selectedShortcut === shortcut}
        />
      ))}
    </div>
  )
}

export default DateTimeShortcut

const getDateRange = (dateTimeShortcut: DateTimeShortcuts, dateTimeFormat?: string): [string, string] => {
  if (dateTimeShortcut === DateTimeShortcuts.CustomRange) return ['', '']

  let start: moment.Moment, end: moment.Moment

  switch (dateTimeShortcut) {
    case DateTimeShortcuts.Today:
      start = end = moment()
      break
    case DateTimeShortcuts.Yesterday:
      start = end = moment().subtract(1, 'd')
      break
    case DateTimeShortcuts.ThisWeek:
      start = moment().startOf('w')
      end = moment().endOf('w')
      break
    case DateTimeShortcuts.LastWeek:
      start = moment().subtract(1, 'w').startOf('w')
      end = moment().subtract(1, 'w').endOf('w')
      break
    case DateTimeShortcuts.Past7Days:
      start = moment().subtract(7, 'd')
      end = moment().subtract(1, 'd')
      break
    case DateTimeShortcuts.ThisMonth:
      start = moment().startOf('M')
      end = moment().endOf('M')
      break
    case DateTimeShortcuts.LastMonth:
      start = moment().subtract(1, 'M').startOf('M')
      end = moment().subtract(1, 'M').endOf('M')
      break
    case DateTimeShortcuts.Past30Days:
      start = moment().subtract(30, 'd')
      end = moment().subtract(1, 'd')
      break
    case DateTimeShortcuts.ThisYear:
      start = moment().startOf('y')
      end = moment().endOf('y')
      break
    case DateTimeShortcuts.LastYear:
      start = moment().subtract(1, 'y').startOf('y')
      end = moment().subtract(1, 'y').endOf('y')
      break
  }

  const format = dateTimeFormat ?? DATE_TIME_FORMAT
  const startDate: string = start.startOf('d').format(format)
  const endDate: string = end.endOf('d').format(format)

  return [startDate, endDate]
}

const getShortcut = (startDateTime: string, endDateTime: string, dateTimeFormat?: string): DateTimeShortcuts => {
  const shortcut: DateTimeShortcuts | undefined = Object.values(DateTimeShortcuts).find((shortcut: DateTimeShortcuts) => {
    const [start, end] = getDateRange(shortcut, dateTimeFormat)
    return start === startDateTime && end === endDateTime
  })

  if (shortcut === undefined) return DateTimeShortcuts.CustomRange

  return shortcut
}
