export interface Notification {
  id: number
  type: NotificationType
  message_title: string
  message_body?: string
  is_read: 0 | 1
  url?: string
  notification_time: string
}

export enum NotificationType {
  INFO,
  ERROR,
  WARNING,
}

export interface NotificationMarkAsReadParams {
  notification_id: number
}
