import { Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import TestSessionPageContent from "./components/TestSessionPageContent";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Test Plans',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TestSessionPage = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Test Sessions</PageTitle>
            <TestSessionPageContent />
          </>
        }
      />
    </Routes>
  )
}

export default TestSessionPage
