import { TestTemplate } from "../template/TestTemplateModels";
import { RequestParameters, RequestResponse } from "../TestModels";

export interface TestResultsGetParams {
  sort?: 'name' | 'route' | 'category' | 'group' | 'run_sequence' | 'status' | 'completed_at' | ''
  order?: 'asc' | 'desc' | ''
}

export interface TestResultsResponse {
  test_session_name: string
  test_results: TestResult[]
  test_session_domain: string
}

export interface TestResult {
  id: number
  test_template_id: number
  test_template: { id: number } & Omit<TestTemplate, 'required_parameters' | 'expected_response'>
  parameter: RequestParameters
  response: RequestResponse | null
  test_session_id: number
  status: TestResultStatus
  completed_at: string
}

export enum TestResultStatus {
  Failed,
  Success,
  Pending
}
