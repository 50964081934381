import React, { useEffect, useState } from "react";
import { checkRequestResponse } from "../Helpers";
import MessageModal from "../MessageModal";
import NotificationMenu from "./NotificationMenu";
import { Notification } from "./NotificationModels";
import {
  notificationGetRead,
  notificationGetUnread,
  notificationMarkAllAsRead,
  notificationMarkAsRead
} from "./NotificationRequests";

const NotificationButton = () => {
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([])
  const [readNotifications, setReadNotifications] = useState<Notification[]>([])

  useEffect(() => {
    refreshNotifications()
  }, [])

  const refreshNotifications = () => {
    void getUnreadNotifications()
    void getReadNotifications()
  }

  const getUnreadNotifications = async () => {
    try {
      const response = await notificationGetUnread()

      if (!checkRequestResponse(response)) return

      const unreadNotifications: Notification[] = response.data.data
      setUnreadNotifications(unreadNotifications)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error getting notifications.' })
    }
  }

  const getReadNotifications = async () => {
    try {
      const response = await notificationGetRead()

      if (!checkRequestResponse(response)) return

      const readNotifications: Notification[] = response.data.data
      setReadNotifications(readNotifications)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error getting notifications.' })
    }
  }

  const markAllAsRead = async () => {
    try {
      const response = await notificationMarkAllAsRead()

      if (!checkRequestResponse(response)) return

      const newUnread = unreadNotifications.map((unread: Notification) => {
        unread.is_read = 1
        return unread
      })

      setUnreadNotifications(newUnread)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error marking all as read.' })
    }
  }

  const markAsRead = async (notification: Notification) => {
    try {
      const response = await notificationMarkAsRead({ notification_id: notification.id })

      if (!checkRequestResponse(response)) return

      const notificationId: number = response.data.data.notification_id

      const newUnread = unreadNotifications.map((unread: Notification) => {
        if (unread.id == notificationId) {
          unread.is_read = 1
        }
        return unread
      })

      setUnreadNotifications(newUnread)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error marking all as read.' })
    }
  }

  const getTrueUnreadCount = (): number => {
    return unreadNotifications.filter((notification: Notification) => {
      return notification.is_read == 0
    }).length
  }

  return (
    <div className='d-flex align-items-center ms-1 ms-lg-3'>
      <div
        id='btn-header-notification'
        className='btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-40px h-md-40px'
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='bottom'
      >
        <i className="fas fa-bell fs-4"></i>
        {
          getTrueUnreadCount() > 0 &&
          <div
            className='badge badge-danger badge-circle position-absolute'
            style={{ top: '-5px', right: '-5px' }}
          >
            {getTrueUnreadCount()}
          </div>
        }
      </div>
      <NotificationMenu
        unreadNotifications={unreadNotifications}
        readNotifications={readNotifications}
        markAllAsRead={markAllAsRead}
        markAsRead={markAsRead}
        refreshNotifications={refreshNotifications}
      />
    </div>
  )
}

export default NotificationButton
