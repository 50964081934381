import TableRow, { TR_STYLE } from "../../../../components/TableRow";
import "../../styles.scss"
import { TestTemplate } from "../TestTemplateModels";

export const TestTemplateIndexRow = (props: { testTemplate: TestTemplate }) => {
  const required_parameters: string = JSON.stringify(props.testTemplate.required_parameters, null, 2)
  const expected_response: string = JSON.stringify(props.testTemplate.expected_response, null, 2)

  return (
    <TableRow
        key={props.testTemplate.name}
        contents={[
            {detail: props.testTemplate.name, key: props.testTemplate.name + '_name'},
            {detail: props.testTemplate.route, key: props.testTemplate.name + '_route', noBold: true},
            {key: props.testTemplate.name + '_required_parameters', noBold: true, extra: <pre className='p-2 code-snippet'>{required_parameters}</pre>},
            {key: props.testTemplate.name + '_expected_response', noBold: true, extra: <pre className='p-2 code-snippet'>{expected_response}</pre>},
            {detail: props.testTemplate.category.replace(/[-_]/g, ' '), key: props.testTemplate.name + '_category', noBold: true, capitalise: true},
            {detail: props.testTemplate.group, key: props.testTemplate.name + '_group', noBold: true, capitalise: true},
            {detail: props.testTemplate.run_sequence, key: props.testTemplate.name + '_run_sequence', noBold: true},
            {detail: props.testTemplate.tag, key: props.testTemplate.name + '_tag', noBold: true, capitalise: true}
        ]}
        styles={[TR_STYLE.HOVER_ROW, TR_STYLE.BORDER_ROW]}
    />
  )
}

export default TestTemplateIndexRow
