import moment from "moment"

const ValidationHelpers = () => {

}

export const VALIDATE_DAY = 'days'
export const VALIDATE_DATE = 'date'

type DateTimeValidationType = 'days' | 'date'

export const validateDateTimeMin = (element: HTMLInputElement, value: string, type: DateTimeValidationType, sub?: number) => {
    let dateTime = moment(value)
    switch(type) {
        case 'days':
            let minDateTime = dateTime.subtract(sub ?? 1, 'days')
            element.min = element.type == 'datetime-local' ? minDateTime.format('yyyy-MM-DD HH:mm:ss') : minDateTime.format('yyyy-MM-DD')
            break
        case 'date':
            element.min = element.type == 'datetime-local' ? dateTime.format('yyyy-MM-DD HH:mm:ss') : dateTime.format('yyyy-MM-DD')

            break
    }
}

export const validateDateTimeMax = (element: HTMLInputElement, value: string, type: DateTimeValidationType, add?: number) => {

    let dateTime = moment(value)
    switch(type) {
        case 'days':
            let maxDateTime = dateTime.add(add ?? 1, 'days')
            element.max = element.type == 'datetime-local' ? maxDateTime.format('yyyy-MM-DD HH:mm:ss') : maxDateTime.format('yyyy-MM-DD')

            break
        case 'date':
            element.max = element.type == 'datetime-local' ? dateTime.format('yyyy-MM-DD HH:mm:ss') : dateTime.format('yyyy-MM-DD')
            
            break
    }
}

export default ValidationHelpers