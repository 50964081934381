import { useState } from "react";
import { sleep } from "../../../../components/Helpers";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import { TableContent } from "../../../../components/Table";
import { TestResult, TestResultsGetParams } from "../TestResultModels";
import TestResultRow from "./TestResultRow";
import TestExecutionLogModal from "../../execution-log/components/modals/TestExecutionLogModal";

interface Props {
  testResults: TestResult[]
  isLoading: boolean
  getTestResults: (params: TestResultsGetParams) => Promise<void>
  defaultSort: TestResultsGetParams
  domain: string
  sessionId: number
}

const TestResultPageContent = ({ testResults, isLoading, getTestResults, defaultSort, domain, sessionId }: Props) => {
  const [filters, setFilters] = useState<TestResultsGetParams>(defaultSort)
  const [showLog, setTestExecutionLogShowing] = useState<boolean>(false)
  const [selectedSessionId, setSelectedSessionId] = useState<number>(0)
  const [selectTestResult, setSelectedTestResult] = useState<number>()

  const onSortHandler = async (e: MouseEvent) => {
    const target = e.target as HTMLElement

    let column = target.getAttribute("data-sort")
    const order = target.getAttribute("data-order")
    let newOrder: 'asc' | 'desc' | '' = "desc"
    let newFilters: any

    document.querySelectorAll("[data-sort]") // reset all order data attributes
      .forEach(item => {
        item.setAttribute("data-order", "")
      })

    switch (order) {
      case 'asc':
        target.setAttribute("data-order", "desc")
        newOrder = "desc"
        break;
      case 'desc':
        target.setAttribute("data-order", "")
        newOrder = ""
        column = ''
        break;
      default:
        target.setAttribute("data-order", "asc")
        newOrder = "asc"
    }

    setFilters(() => {
      newFilters = { sort: column, order: newOrder }
      return newFilters
    })

    await sleep(100)
    await getTestResults(newFilters)
  }


  const viewExectionLog = (resultId: number) => {
    setTestExecutionLogShowing(true)
    setSelectedSessionId(sessionId)
    setSelectedTestResult(resultId)
  }

  const closeExecutionLog = () => {
    setTestExecutionLogShowing(false)
    setSelectedSessionId(0)
    setSelectedTestResult(0)
  }

  return isLoading && testResults.length == 0
    ? <LoadingIndicator />
    : (
      <>
      <TestExecutionLogModal
        show={showLog}
        onClose={closeExecutionLog}
        testSessionId={selectedSessionId}
        testResultId={selectTestResult}
      />
      <div className="card pt-3 pb-3 mb-5">
        <div className="card-body py-3 fs-6">
          <b>Domain:</b> {domain}
        </div>
      </div>
      <TableContent
        name='test results'
        columns={[
          { title: 'Name', name: 'name', sort: true },
          { title: 'Route', name: 'route', sort: true },
          { title: 'Parameters', name: 'parameters', sort: false },
          { title: 'Response', name: 'response', sort: false },
          { title: 'Category', name: 'category', sort: true },
          { title: 'Group', name: 'group', sort: true },
          { title: 'Run Sequence', name: 'run_sequence', sort: true },
          { title: 'Tag', name: 'tag', sort: false },
          { title: 'Test Status', name: 'status', sort: true },
          { title: 'Completed At (GMT +08:00)', name: 'completed_at', sort: true },
          { title: 'Action', name: 'action', sort: true },
        ]}
        contents={testResults}
        isLoading={isLoading}
        filters={filters}
        sortHandler={onSortHandler}
        tableRows={
          !isLoading && testResults.map((testResult: TestResult) =>
            <TestResultRow
              key={testResult.test_session_id + '_' + testResult.test_template.id}
              testResult={testResult}
              viewLog={viewExectionLog}
            />
          )
        }
        filterTable={getTestResults}
        setFilters={setFilters}
      />
      </>
      
    )
}

export default TestResultPageContent
