import { Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../_metronic/layout/core";
import InvoiceTable from "./components/InvoiceTable";

const InvoicePage = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle>Invoices</PageTitle>
            <InvoiceTable />
          </>
        }
      />
    </Routes>
  )
}

export default InvoicePage;