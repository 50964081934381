import { Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import TxnCountReport from './components/TxnCountReport';

const txnCountReportBreadcrumb: Array<PageLink> = [
  {
    title: 'Finance',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const TxnCountReportPage = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={txnCountReportBreadcrumb}>Txn Count Report</PageTitle>
            <TxnCountReport />
          </>
        }
      />
    </Routes>
  )
}

export default TxnCountReportPage