import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { checkRequestResponse, sortByKey } from "../../../../../components/Helpers";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import MessageModal from "../../../../../components/MessageModal";
import { TableContent } from "../../../../../components/Table";
import {
  REQUEST_PARAMS_ORDER,
  REQUEST_RESPONSE_ORDER,
  RequestParameters,
  RequestResponse
} from "../../../TestModels";
import { TestExecutionLog, TestExecutionLogParams } from "../../TestExecutionLogModels";
import { testExecutionLogIndex } from "../../TestExecutionLogRequests";
import TestExecutionLogRow from "../TestExecutionLogRow";
import TablePagination, { initialPagination } from "../../../../../components/TablePagination/TablePagination";
import { RequestPagination, ResponsePagination } from "../../../../../helpers/types/api-types";

interface Props {
  show: boolean
  onClose: () => void
  testSessionId: number
  testResultId?: number
}

const TestExecutionLogModal = ({ show, onClose, testSessionId, testResultId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
  const [filters, setFilters] = useState<TestExecutionLogParams>({ test_session_id: 0 })
  const [testExecutionLog, setTestExecutionLog] = useState<TestExecutionLog[]>([])

  useEffect(() => {
    if (testSessionId == 0) return

    void getExecutionLog({
      test_session_id: testSessionId,
      paginate: 1,
      per_page: 15,
      page: 1,
    })
  }, [testSessionId])

  const handleClose = () => {
    setTestExecutionLog([])
    onClose()
  }

  const getExecutionLog = async (params: TestExecutionLogParams) => {
    setIsLoading(true)

    try {
      if (testResultId) params = {...params, test_result_id: testResultId}
      const response = await testExecutionLogIndex(params)

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        return
      }

      const response_data = response.data.data
      let data: TestExecutionLog[]

      if (params.paginate) {
        setPagination(response_data.paginations)

        data = response_data.rows ?? []
      } else {
        data = response_data
      }

      data = data.map((row: TestExecutionLog) => {
        let sorted: RequestParameters | RequestResponse

        switch (row.event) {
          case 'api_request':
            sorted = sortByKey<RequestParameters>(row.data as RequestParameters, REQUEST_PARAMS_ORDER)
            break
          case 'api_response':
            sorted = sortByKey<RequestResponse>(row.data as RequestResponse, REQUEST_RESPONSE_ORDER)
            break
          default:
            console.error(`Unhandled event: ${row.event} (${row.event_string})`)
            return row
        }

        return {
          ...row,
          data: sorted
        }
      })

      setTestExecutionLog(data)
      setFilters(params)
    } catch (e) {
      console.log(e)

      MessageModal({ type: 'failed' })
    } finally {
      setIsLoading(false)
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_test_execution_log'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-100 mx-20'
      scrollable
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header px-10'>
        <h2>Test Execution Log</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={onClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body p-0'>
        {
          isLoading &&
          <div className='my-10'>
            <LoadingIndicator spinnerSize='2.5rem'/>
          </div>
        }
        {
          !isLoading &&
          <TableContent
            name='test executions'
            columns={[
              { title: "Test Name", name: "test_template_name", sort: false },
              { title: "Date Time (GMT +08:00)", name: "date_time", sort: false },
              { title: "Event", name: "event_string", sort: false },
              { title: "URL", name: "url", sort: false },
              { title: "Parameters/Response", name: "data", sort: false },
            ]}
            contents={testExecutionLog}
            isLoading={isLoading}
            filters={filters}
            sortHandler={null}
            tableRows={
              testExecutionLog.map((row: TestExecutionLog) => (
                <TestExecutionLogRow key={row._id} row={row} />
              ))
            }
            filterTable={getExecutionLog}
            setFilters={setFilters}
          />
        }
        {
            pagination &&
              <TablePagination
                pagination={pagination}
                onPaginate={(paginationData: RequestPagination) => {
                  getExecutionLog({
                    ...filters,
                    ...paginationData
                  })
                  setFilters(prevFilters => ({
                    ...prevFilters,
                    ...paginationData
                  }))
              }}
            />
          }
      </div>

      <div className="modal-footer">
        <button
          type='button'
          className='btn btn-sm btn-light-primary'
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default TestExecutionLogModal
