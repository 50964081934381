export enum TH_ALIGNMENT {
    ALIGN_RIGHT = 'text-end',
    ALIGN_CENTER = 'text-center',
    ALIGN_LEFT = 'text-start'
}

interface ColumnHeader {
    title: string, 
    name: string, 
    sort: boolean, 
    width?: number,
    nowrap?: boolean,
    align?: TH_ALIGNMENT
}
interface propsParam {
    name: string,
    columns: ColumnHeader[],
    contents: {}[],
    isLoading: boolean,
    filters: any,
    sortHandler: any,
    tableRows: any,
    filterTable: any,
    setFilters: any,
    freezeHeader?: boolean
}

const TableContent = (props: propsParam) => {
    let freezeHeaderClass: string = props.freezeHeader ? 'freeze-header-bg-light' : ''

    const tableHeaderStyle = (width?: number, wrap?: boolean, align?: TH_ALIGNMENT  ) => { 
        let thStyleClass: string = 'ps-4 text-uppercase'

        let td_width = width ?? 100
        thStyleClass = thStyleClass + ` min-w-${td_width}px`

        if (wrap) thStyleClass = thStyleClass + ' nowrap-text'
        if (align) thStyleClass = thStyleClass +   ` ${align}`
        return thStyleClass
    }

    return (
        <div className={'card pt-5'}>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className={`table-responsive ${freezeHeaderClass}`}>
                    {/* begin::Table */}
                    <table className='table striped align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                            <tr className='fw-bold text-muted bg-light'>
                            {/* GENERATE TABLE COLUMN */}
                            {props.columns.map((column: any) => {

                                if (column.sort == true) {
                                    const order = (props.filters.sort == column.name && props.filters.order !== undefined) ? props.filters.order : ""
                                    return (
                                            <th className={tableHeaderStyle(column?.width, column?.wrap, column?.align) + ` table-sort-${props.filters.sort === column.name && props.filters.order}`}
                                                data-sort={column.name} data-order={order} role="button" onClick={props.sortHandler}
                                                key={column.name}>{column.title}</th>
                                        )
                                } else {
                                    return (
                                        <th className={tableHeaderStyle(column?.width, column?.wrap, column?.align)} key={column.name}>{column.title}</th>
                                    )
                                }
                            })}
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {props.isLoading && <tr>
                                <td className={'text-center text-muted'} colSpan={12}>Loading...</td>
                            </tr>}
                            {!props.isLoading && props.contents.length === 0 && <tr>
                                <td className={'text-center text-muted'} colSpan={12}>No {props.name} Found.</td>
                            </tr>}
                                {props.tableRows}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                {/* end::Table */}
                </div>
            {/* end::Table container */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { TableContent }
