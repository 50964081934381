import axios from "axios";
import {
  SgpMaintenanceGetParams,
  SgpMaintenanceIndexParams,
  SgpMaintenanceSetParams,
  SgpMaintenanceUpdateParams
} from "./Models";

const API_URL = process.env.REACT_APP_API_URL

export const sgpMaintenanceIndexUrl: string = `${API_URL}/maintenance/site-game-provider/index`
export const sgpMaintenanceGetUrl: string = `${API_URL}/maintenance/site-game-provider/get`
export const sgpMaintenanceSetUrl: string = `${API_URL}/maintenance/site-game-provider/set`
export const sgpMaintenanceUpdateUrl: string = `${API_URL}/maintenance/site-game-provider/update`

export const sgpMaintenanceIndex = (params: SgpMaintenanceIndexParams) => {
  return axios.post(sgpMaintenanceIndexUrl, params)
}

export const sgpMaintenanceGet = (params: SgpMaintenanceGetParams) => {
  return axios.post(sgpMaintenanceGetUrl, params)
}

export const sgpMaintenanceSet = (params: SgpMaintenanceSetParams) => {
  return axios.post(sgpMaintenanceSetUrl, params)
}

export const sgpMaintenanceUpdate = (params: SgpMaintenanceUpdateParams) => {
  return axios.post(sgpMaintenanceUpdateUrl, params)
}
