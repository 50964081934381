import { useState, useEffect } from "react"
import { PaginationListProps, PageData } from "./Models"
import PaginationButton from "./PaginationButton"

const PaginationList = ({ pagination, onPaginate }: PaginationListProps) => {
  const initialPageDataArray: PageData[] = [{
    label: '1',
    isCurrentPage: true,
    clickHandler: undefined
  }]

  const [pageDataArray, setPageDataArray] = useState<PageData[]>(initialPageDataArray) // meta data for pagination button component

  useEffect(() => {
    let tempPageDataArray: PageData[] = []
    let pagesToExclude: number[] = []

    if (pagination.total >= 5) {
      let pagesToInclude: number[] = [1, pagination.current_page - 2, pagination.current_page - 1, pagination.current_page, pagination.current_page + 1, pagination.current_page + 2, pagination.last_page]

      for (let i = 1; i <= pagination.last_page; i++) {
        if (!pagesToInclude.includes(i)) pagesToExclude.push(i)
      }
    }

    for (let i = 1; i <= pagination.last_page; i++) {
      if (pagesToExclude.includes(i)) {
        if (pagesToExclude.length >= 1 && (i === 2 || i === pagination.last_page - 1)) // append pagination truncate button
          tempPageDataArray.push({
            label: '...',
            clickHandler: undefined
          })

        continue
      }

      tempPageDataArray.push({
        label: i.toString(),
        value: i.toString(),
        isCurrentPage: i === pagination.current_page,
        clickHandler: (typeof i === 'number' && i !== pagination.current_page) ? () => {onPaginate(i)} : undefined
      })
    }

    if (tempPageDataArray.length <= 1) tempPageDataArray = [{label: '1', isCurrentPage: true}]

    setPageDataArray(tempPageDataArray)
  }, [pagination])

  return (
      <div className="d-flex">
        <ul className="pagination mt-2">
          <li className={`page-item previous ${pagination.current_page <= 1 ? 'disabled' : ''}`}>
            <button
              onClick={
                pagination.current_page > 1 
                  ? () => {onPaginate(pagination.current_page - 1)} 
                  : undefined
                } 
              type='button' 
              className="page-link"
            >
              <i className="previous" />
            </button>
          </li>
          {
              pageDataArray.map((pageData: PageData) => {
                return <PaginationButton
                  key={pageData.label}
                  label={pageData.label}
                  value={pageData.value}
                  isCurrentPage={pageData.isCurrentPage}
                  clickHandler={pageData.clickHandler}
                />
              })
          }
            <li className={`page-item next ${pagination.current_page >= pagination.last_page ? 'disabled' : ''}`}>
              <button 
                onClick={
                  pagination.current_page < pagination.last_page 
                    ? () => {onPaginate(pagination.current_page + 1)} 
                    : undefined
                } 
                type='button' 
                className="page-link"
              >
                <i className="next" />
              </button>
            </li>
        </ul>
      </div>
  )
}

export default PaginationList
