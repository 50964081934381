import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { checkRequestResponse, removeEmptyFilters, sortByKey } from "../../../components/Helpers";
import MessageModal from "../../../components/MessageModal";
import { REQUEST_PARAMS_ORDER, REQUEST_RESPONSE_ORDER, RequestParameters, RequestResponse } from "../TestModels";
import TestResultPageContent from "./components/TestResultPageContent";
import { TestResult, TestResultsGetParams, TestResultsResponse } from "./TestResultModels";
import { testResultsGet } from "./TestResultRequests";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Test Plans',
    path: '',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Test Sessions',
    path: 'test-plan/sessions',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const defaultSort: TestResultsGetParams = {
  sort: 'run_sequence',
  order: 'asc'
}

const TestResultPage = () => {
  const { testSessionId } = useParams()

  const [sessionName, setSessionName] = useState<string>('Loading...')
  const [sessionDomain, setSessionDomain] = useState<string>('')
  const [testResults, setTestResults] = useState<TestResult[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    void getTestResults(defaultSort)
  }, [])

  const getTestResults = async (params: TestResultsGetParams) => {
    setIsLoading(true)

    try {
      const response = await testResultsGet(testSessionId!, removeEmptyFilters(params))

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        return
      }

      const data: TestResultsResponse = response.data.data

      data.test_results = data.test_results.map((result: TestResult) => {
        return {
          ...result,
          parameter: sortByKey<RequestParameters>(result.parameter, REQUEST_PARAMS_ORDER),
          response: result.response === null ? null : sortByKey<RequestResponse>(result.response, REQUEST_RESPONSE_ORDER)
        }
      })

      setTestResults(data.test_results)
      setSessionName(data.test_session_name)
      setSessionDomain(data.test_session_domain)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error retrieving test results.' })
      setIsLoading(false)
    }
  }

  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>{sessionName}</PageTitle>
            <TestResultPageContent
              isLoading={isLoading}
              testResults={testResults}
              getTestResults={getTestResults}
              defaultSort={defaultSort}
              domain={sessionDomain}
              sessionId={parseInt(testSessionId!)}
            />
          </>
        }
      />
    </Routes>
  )
}

export default TestResultPage
