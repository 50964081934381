import { createPortal } from "react-dom";
import { FormControl, Modal, Spinner } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import * as Yup from "yup";
import { InvoiceStoreParams, PaymentStatus, Site } from "../../Models";
import MessageModal from "../../../../components/MessageModal";
import { invoiceBillsUpload, invoiceCreate, invoiceReceiptsUpload, invoiceStore } from "../../Requests";
import { checkRequestResponse, regexDecimalPlaces, removeEmptyFilters, SelectOptionModel } from "../../../../components/Helpers";
import moment from "moment";
import clsx from "clsx";

const validationSchema = Yup.object().shape({
  site_code: Yup.string().nullable(),
  currency_code: Yup.string().nullable(),
  name: Yup.string().required('Invoice name is required'),
  invoice_number: Yup.string().required('Invoice No. is required')
    .matches(/^[a-zA-Z0-9-_]+$/, {
      message: 'Invoice No. must be alpha dash'
    }),
  amount: Yup.number().positive().moreThan(0, 'Invoice amount required')
    .test(
      "is-decimal",
      "Amount can only have maximum 4 decimal places",
      (val: any) => {
        if (val != undefined) {
          return  regexDecimalPlaces(4).test(val)
        }
        return true
      }
    ).required(),
  content: Yup.string().optional(),
  release_date_time: Yup.date().required('Release date time is required'),
  payment_status: Yup.number().integer().oneOf(PaymentStatus.values()).required(),
  remark: Yup.string().optional()
})

const initialValues: InvoiceStoreParams = {
  content: "",
  currency_code: "",
  name: "",
  invoice_number: "",
  amount: 0,
  payment_status: PaymentStatus.Unpaid,
  release_date_time: moment().startOf('day').format('YYYY-MM-DD 00:00:00'),
  site_code: ""
}

const initialBillInputs = [
  <input
    key='bill-0'
    name='bills[]'
    className='form-control col-6 mt-1'
    type='file'
    accept='.pdf,.jpg,.jpeg,.png,.xls,.xlsx'
  />
]

const initialReceiptInputs = [
  <input
    key='receipt-0'
    name='receipts[]'
    className='form-control col-6 mt-1'
    type='file'
    accept='.pdf,.jpg,.jpeg,.png,.xls,.xlsx'
  />
]

interface Props {
  show: boolean
  onClose: () => void
  onCreate: () => void
}

const InvoiceCreateModal = (props: Props) => {
  const [data, setData] = useState<InvoiceStoreParams>(initialValues)
  const [sites, setSites] = useState<Site[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])
  const [selectedSiteCode, setSelectedSiteCode] = useState<string>('')
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>('')
  const [availableCurrencies, setAvailableCurrencies] = useState<SelectOptionModel[]>(currencyOptions)
  const [billInputs, setBillInputs] = useState<JSX.Element[]>(initialBillInputs)
  const [receiptInputs, setReceiptInputs] = useState<JSX.Element[]>(initialReceiptInputs)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    void loadOptions()
  }, [])

  useEffect(() => {
    setSelectedCurrencyCode('')

    const supportedCurrencies = sites.find(site => site.code == selectedSiteCode)?.currency_codes ?? []

    setAvailableCurrencies(supportedCurrencies.map(currency => {
      return { value: currency, label: currency }
    }))

    if (supportedCurrencies.includes('USD')) {
      setSelectedCurrencyCode('USD')
    }
  }, [selectedSiteCode])

  const loadOptions = async () => {
    const createResponse = await invoiceCreate()

    if (!checkRequestResponse(createResponse)) return false

    const createData = createResponse.data.data

    setSites(createData.sites)

    const localStorageCurrencyOptions = localStorage.getItem('currency_options')
    const tempCurrencies = JSON.parse(localStorageCurrencyOptions ?? '[{}]')
    tempCurrencies.sort((a: SelectOptionModel, b: SelectOptionModel) => a.value.localeCompare(b.value))
    tempCurrencies.length > 0 && setCurrencyOptions(tempCurrencies)
  }

  const handleClose = () => {
    props.onClose()
    formik.setStatus(null)
    formik.resetForm()
    setData(initialValues)
    setSelectedSiteCode('')
    setSelectedCurrencyCode('')
    setBillInputs(initialBillInputs)
    setReceiptInputs(initialReceiptInputs)
  }

  const addBillInput = () => {
    setBillInputs([
      ...billInputs,
      <input
        key={`bill-${billInputs.length}`}
        name='bills[]'
        className='form-control col-6 mt-2'
        type='file'
        accept='.pdf,.jpg,.jpeg,.png,.xls,.xlsx'
      />
    ])
  }

  const addReceiptInput = () => {
    setReceiptInputs([
      ...receiptInputs,
      <input
        key={`receipt-${receiptInputs.length}`}
        name='receipts[]'
        className='form-control col-6 mt-2'
        type='file'
        accept='.pdf,.jpg,.jpeg,.png,.xls,.xlsx'
      />
    ])
  }

  const uploadBills = async (invoiceCode: string) => {
    const billInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="file"][name="bills[]"]')
    const bills = Array.from(billInputs)
      .map(input => input.files?.item(0) as Blob)
      .filter(bill => bill) // remove null

    const formData = new FormData()

    formData.append('invoice_code', invoiceCode)
    bills.forEach(bill => formData.append("files[]", bill))
    formData.append('notification', 'create')

    return checkRequestResponse(await invoiceBillsUpload(formData))
  }

  const uploadReceipts = async (invoiceCode: string) => {
    const receiptInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="file"][name="receipts[]"]')
    const receipts = Array.from(receiptInputs)
      .map(input => input.files?.item(0) as Blob)
      .filter(receipt => receipt) // remove null

    if (!receipts.length) return true

    const formData = new FormData()

    formData.append('invoice_code', invoiceCode)
    receipts.forEach(receipt => formData.append("files[]", receipt))

    return checkRequestResponse(await invoiceReceiptsUpload(formData))
  }

  const uploadFiles = async (invoiceCode: string) => {
    return await uploadBills(invoiceCode) && await uploadReceipts(invoiceCode)
  }

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      setIsLoading(true)

      try {
        let billCount = 0
        const billInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="file"][name="bills[]"]')
        billInputs.forEach(billInput => billCount += billInput.files?.length ?? 0)

        if (!billCount) {
          MessageModal({type: 'failed', messages: 'At least one (1) bill is required'})
          return false
        }

        values.release_date_time = moment(values.release_date_time).format('YYYY-MM-DD HH:mm:ss')

        const params = removeEmptyFilters(values)
        params.site_code = selectedSiteCode
        params.currency_code = selectedCurrencyCode

        const response = await invoiceStore(params)

        if (!checkRequestResponse(response)) {
          const errorMessages = response.data.messages

          console.error(errorMessages)
          MessageModal({ type: 'failed', messages: errorMessages })

          setSubmitting(false)
          setIsLoading(false)

          return false
        }

        if (!await uploadFiles(response.data.data.invoice_code)) {
          MessageModal({ type: 'failed', messages: 'File(s) upload failed' })
          return false
        }

        MessageModal({ type: 'success', messages: 'Invoice created successfully.' })

        props.onCreate()
        setSubmitting(false)
        setIsLoading(false)
        handleClose()
      } catch (error) {
        console.error(error)
        MessageModal({ type: 'failed' })
      }
    }
  })

  return createPortal(
    <Modal
      id='kt_modal_invoice_create'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={props.show}
      scrollable
      onHide={handleClose}
    >
      <div className='modal-header px-10'>
        <h2>New Invoice</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <form className='modal-body px-lg-10' onSubmit={formik.handleSubmit} id='invoice-create-form'>
        <div className='container-md-6'>
          <div className='row'>

            <div className='col-md-6'>
              <div className="mb-6">
                <label className='form-label required'>Site</label>
                <select
                  {...formik.getFieldProps('site_code')}
                  className='form-select form-select-solid'
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setSelectedSiteCode(e.currentTarget.value)
                    formik.handleChange(e)
                  }}
                  value={selectedSiteCode}
                >
                  <option disabled value={''}>Select site</option>
                  {sites.map(site => (
                    <option key={site.code} value={site.code}>
                      {site.code}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-6">
                <label className='form-label required'>Invoice No.</label>
                <input
                  type='text'
                  {...formik.getFieldProps('invoice_number')}
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.invoice_number && formik.errors.invoice_number},
                    {'is-valid': formik.touched.invoice_number && !formik.errors.invoice_number},
                  )}
                  placeholder='Enter invoice number'
                />
                {formik.touched.invoice_number && formik.errors.invoice_number && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.invoice_number}
                    </span>
                  </div>
                )}
              </div>

              <div className='mb-6'>
                <div>
                  <label className='form-label required'>Invoice Name</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control form-control-solid',
                      {'is-invalid': formik.touched.name && formik.errors.name},
                      {'is-valid': formik.touched.name && !formik.errors.name,}
                    )}
                    placeholder='Enter invoice name'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.name}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='mb-6'>
                <label className='form-label required'>Currency</label>
                <select
                  {...formik.getFieldProps('currency_code')}
                  className='form-select form-select-solid'
                  disabled={selectedSiteCode == ''}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    setSelectedCurrencyCode(e.currentTarget.value)
                    formik.handleChange(e)
                  }}
                  value={selectedCurrencyCode}
                >
                  <option value=''>{selectedSiteCode == '' ? 'Select site first' : 'All Currencies'}</option>
                  {
                    selectedSiteCode != '' &&
                    availableCurrencies.map(currency =>
                      <option key={currency.value} value={currency.value}>{currency.label}</option>
                    )
                  }
                </select>
              </div>

              <div className='mb-6'>
                <label className='form-label required'>Invoice Amount</label>
                <input
                  {...formik.getFieldProps('amount')}
                  type='number'
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.amount && formik.errors.amount},
                    {'is-valid': formik.touched.amount && !formik.errors.amount,}
                  )}
                  placeholder='Enter invoice amount'
                />
                {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>{formik.errors.amount}</span>
                    </div>
                )}
              </div>

              <div className='mb-6'>
                <label className='form-label'>Invoice Content <span className='text-muted fs-7'>(Optional)</span></label>
                <textarea
                  {...formik.getFieldProps('content')}
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.content && formik.errors.content},
                    {'is-valid': formik.touched.content && !formik.errors.content,}
                  )}
                  placeholder='Enter invoice details'
                />
                {formik.touched.content && formik.errors.content && (
                  <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-danger'>{formik.errors.content}</span>
                  </div>
                )}
              </div>

              <div className='mb-6'>
                <label className='form-label required'>Release Date</label>
                <FormControl
                  {...formik.getFieldProps('release_date_time')}
                  type='datetime-local'
                  className='form-control-solid'
                  step={1}
                />
              </div>

              <div className='mb-6'>
                  <label className='form-label'>Remark <span className='text-muted fs-7'>(Optional)</span></label>
                  <textarea
                    {...formik.getFieldProps('remark')}
                    className='form-control form-control-solid'
                    placeholder='Enter remark'
                  />
              </div>
            </div>

            <div className='col-md-6'>
              <div className="mb-6">
                <div className='row'>
                  <label className='form-label required col mb-0 mt-1'>Bills</label>
                  <div className='col d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-active-color-primary py-1 px-2 fs-7'
                      onClick={addBillInput}
                    >
                      + Add Bill
                    </button>
                  </div>
                </div>
                {billInputs}
              </div>

              <div className="mb-6">
                <div className='row'>
                  <label className='form-label col mb-0 mt-1'>Receipts <span className='text-muted fs-7'>(Optional)</span></label>
                  <div className='col d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-active-color-primary py-1 px-2 fs-7'
                      onClick={addReceiptInput}
                    >
                      + Add Receipt
                    </button>
                  </div>
                </div>
                {receiptInputs}
              </div>
            </div>

          </div>
        </div>
        
      </form>
      <div className='modal-footer'>
        <button
          type='button'
          disabled={formik.isSubmitting || Object.keys(formik.errors).length !== 0}
          className='btn btn-sm btn-primary me-3'
          onClick={() => formik.handleSubmit()}
        >
          {isLoading ? 
            <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Saving... </>
            : "Create"
          }
        </button>
        <button
          type='button'
          className='btn btn-sm btn-light-primary'
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default InvoiceCreateModal
