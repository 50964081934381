import React from "react"
import ActionButtons, { BUTTON_ICONS } from "../../../../components/ActionButtons"
import { PDPDIndexRow } from "../Models"

const PDPDTableRow = (props: {row: PDPDIndexRow, index: number, show_jp_col: boolean, reportActionLog: VoidFunction}) => {

    const getTextColor = (value: number | undefined) => {
        return value !== undefined && value !== 0 ? { color: 'red' } : {};
    };

    return (
        <tr>
            <td className='text-center'>
                {props.row.date}
            </td>
            <td className='text-center'>
                {props.row.game_provider_code}
            </td>
            <td className='text-center' >
                {props.row.currency}
            </td>
            <td className='text-end'>
                <div className='d-flex justify-content-between align-items-center'>
                    <span>B : </span>
                    {props.row.initial_count_diff !== undefined && (
                        <span>
                            {props.row.initial_count_diff}
                        </span>
                    )}
                </div>
                <hr/>
                <div className='d-flex justify-content-between align-items-center'>
                    <span>A : </span>
                    <span style={getTextColor(props.row.current_count_diff)}>
                        {props.row.current_count_diff}
                    </span>
                </div>
            </td>
            <td className='text-end'>
                {props.row.initial_turnover_diff !== undefined && <>{props.row.initial_turnover_diff} <hr/></>}
                <span style={getTextColor(props.row.current_turnover_diff)}>
                    {props.row.current_turnover_diff}
                </span>
            </td>
            <td className='text-end'>
                {props.row.initial_win_lose_diff !== undefined && <>{props.row.initial_win_lose_diff} <hr/></>}
                <span style={getTextColor(props.row.current_win_lose_diff)}>
                    {props.row.current_win_lose_diff}
                </span>
            </td>
            {props.show_jp_col && <React.Fragment key={props.index + '_jp_td'}>
                    <td className='text-end'>
                        {props.row.initial_jackpot_contribution_diff !== undefined && <>{props.row.initial_jackpot_contribution_diff} <br/><hr/></>}
                        <span style={getTextColor(props.row.current_jackpot_contribution_diff)}>
                            {props.row.current_jackpot_contribution_diff}
                        </span>
                    </td>
                    <td className='text-end'>
                        {props.row.initial_jackpot_win_diff !== undefined && <>{props.row.initial_jackpot_win_diff} <br/><hr/></>}
                        <span style={getTextColor(props.row.current_jackpot_win_diff)}>
                            {props.row.current_jackpot_win_diff}
                        </span>
                    </td>
                </React.Fragment> 
            }
            <ActionButtons
                buttons={[
                    {key: props.index + 'action_button', function: props.reportActionLog, icon: BUTTON_ICONS.LOG_ICON, overlay: 'Report Action Log'},
                ]}
                removeDflex={true}
                alignCenter={true}
            />
        </tr>
    )
}

export default PDPDTableRow