import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { checkRequestResponse, isEmpty, SelectOptionModel } from "../../../../../components/Helpers";
import MessageModal from "../../../../../components/MessageModal";
import TableFilter from "../../../../../components/table-filter/components/TableFilter";
import ToolBarButton from "../../../../../components/ToolBarButton";
import { ApiResponse, FinalRequest } from "../../../../../helpers/types/api-types";
import { Currency } from "../../../../agents/Models";
import { agentPtUpdate, INDEX_AGENT_PT_URL } from "../../../../agents/Requests";
import { AgentPt, AgentPtIndexItem, AgentPtIndexParams, AgentPtSubmission } from "../../Models";
import AgentPtRow from "./AgentPtRow";

const validationSchema = Yup.object().shape({
  agent_id: Yup.number().required(),
  currency_code: Yup.string().length(3).required(),
  position_takings: Yup.object().required()
})

const submissionInitialState: AgentPtSubmission = {
  agent_id: 0,
  currency_code: '',
  position_takings: {}
}

const AgentPtTable = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])
  const [selectedCurrency, setSelectedCurrency] = useState<string>('')
  const [editCount, setEditCount] = useState<number>(0)
  const [agentPts, setAgentPts] = useState<AgentPt[]>([])
  const [agentPtSubmission, setAgentPtSubmission] = useState<AgentPtSubmission>(submissionInitialState)
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false)

  useEffect(() => {
    if (!location.state) {
      navigate('/agents')
      return
    }

    setIsLoading(true)

    const agent: any = location.state

    // set currencies supported by the selected agent
    const supportedCurrencies: SelectOptionModel[] = [];

    agent.currencies.forEach((currency: Currency) => {
      const currencyOption: SelectOptionModel = { label: currency.code, value: currency.code }
      supportedCurrencies.push(currencyOption)
    })

    supportedCurrencies.sort((a: SelectOptionModel, b: SelectOptionModel) => a.value.localeCompare(b.value))
    setCurrencyOptions(supportedCurrencies)

    setIsLoading(false)
  }, [])

  useEffect(() => {
    setAgentPtSubmission({
      agent_id: parseInt((location.state as any).id),
      currency_code: selectedCurrency,
      position_takings: {}
    })
  }, [selectedCurrency])

  useEffect(() => {
    setEditCount(Object.keys(agentPtSubmission.position_takings).length)
  }, [agentPtSubmission])

  const onPtInputChangeHandler = (gameProviderCode: string, positionTaking: number) => {
    const gameProviderPt = agentPts.find(pt => pt.game_provider_code == gameProviderCode)

    if (gameProviderPt === undefined) {
      const errorMessage = `Invalid game provider code: ${gameProviderCode}`
      console.error(errorMessage)
      MessageModal({ type: 'failed', messages: errorMessage })
      return
    }

    const initialPt = gameProviderPt.position_taking
    const newPts = agentPtSubmission.position_takings

    if (initialPt == positionTaking) {
      delete newPts[gameProviderCode]
      removeGpCodeFormikError(gameProviderCode)
    } else {
      newPts[gameProviderCode] = positionTaking

      if (positionTaking < 0 || positionTaking > gameProviderPt.max_pt) {
        formik.setFieldError(gameProviderCode, `Position taking of ${gameProviderCode} must be between 0 and ${gameProviderPt.max_pt}.`)
        validationSchema.fields[gameProviderCode] = Yup.number().min(0).max(gameProviderPt.max_pt)
      } else {
        removeGpCodeFormikError(gameProviderCode)
      }
    }

    setAgentPtSubmission({
      agent_id: parseInt((location.state as any).id),
      currency_code: agentPtSubmission.currency_code,
      position_takings: newPts
    })
  }

  const onPtInputCancelHandler = (gameProviderCode: string) => {
    const newPts = agentPtSubmission.position_takings
    delete newPts[gameProviderCode]

    removeGpCodeFormikError(gameProviderCode)

    setAgentPtSubmission({
      agent_id: parseInt((location.state as any).id),
      currency_code: agentPtSubmission.currency_code,
      position_takings: newPts
    })
  }

  const removeGpCodeFormikError = (gameProviderCode: string) => {
    const errors: any = formik.errors
    delete errors[gameProviderCode]

    formik.setErrors(errors)

    // if (validate) formik.validateField(gameProviderCode) // TODO: triggering error when value is correct

    delete validationSchema.fields[gameProviderCode]
  }

  const fireDiscardChangesModal = async () => {
    return (await Swal.fire<boolean>({
      title: 'Discard Changes?',
      html: 'You have edited one or more values. By proceeding you will lose all your current changes. Continue?',
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary'
      }
    })).value
  }

  const resetData = () => {
    const newPts = agentPtSubmission.position_takings
    let newAgentPts: AgentPt[] = []

    Object.keys(newPts).forEach(gameProviderCode => {
      newAgentPts = agentPts.map(pt => {
        if (pt.game_provider_code == gameProviderCode) {
          pt.position_taking = newPts[gameProviderCode]
          pt.enabled = true
        }

        return pt
      })
    })

    setAgentPts(newAgentPts)

    setAgentPtSubmission({
      agent_id: parseInt((location.state as any).id),
      currency_code: selectedCurrency,
      position_takings: {}
    })
  }

  const formik = useFormik({
    initialValues: agentPtSubmission,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        const response = await agentPtUpdate(values)

        if (checkRequestResponse(response)) {
          MessageModal({
            type: 'success',
            messages: 'Successfully updated agent position takings.'
          })

          resetData()
          setUpdateSuccess(true)
        }

        setSubmitting(false)
        formik.resetForm()
      } catch (error) {
        console.error(error)
        MessageModal({ type: 'failed' })
      }
    }
  })

  const submitForm = () => {
    (document.getElementById('agent-pt-form') as HTMLFormElement).requestSubmit()
  }

  const onIndexSubmitHandler = async (params: AgentPtIndexParams): Promise<boolean | AgentPtIndexParams> => {
    setIsLoading(true)

    if (editCount) {
      const discardChanges = await fireDiscardChangesModal()

      if (!discardChanges) {
        setIsLoading(false)
        return false
      }
    }

    setAgentPts([])

    return {
      ...params,
      agent_id: parseInt((location.state as any).id)
    }
  }

  const onResponseHandler = (response: ApiResponse<AgentPtIndexItem[]>, params: FinalRequest<AgentPtIndexParams>) => {
    const items: AgentPtIndexItem[] = response.data as AgentPtIndexItem[]

    const agentPts: AgentPt[] = items.map((item: AgentPtIndexItem): AgentPt => ({
      game_provider_code: item.game_provider_code,
      game_provider_name: item.game_provider_name,
      position_taking: +item.position_taking,
      max_pt: item.max_pt,
      enabled: item.status == 1,
    }))


    setAgentPts(agentPts)
    setSelectedCurrency(params.currency_code)
    setIsLoading(false)
  }

  return (
    <>
      <ToolBarButton>
        <Link
          type="button"
          className='btn btn-sm btn-secondary'
          to='/agents/'
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2' />
          Back to Agent Page
        </Link>
      </ToolBarButton>

      <TableFilter<AgentPtIndexParams, AgentPtIndexItem[]>
        fields={[
          {
            label: 'Currency Code',
            name: 'currency_code',
            type: 'select',
            options: currencyOptions
          },
          {
            label: 'Game Provider Code',
            name: 'game_provider_code',
            type: 'text',
          },
        ]}
        validationSchema={Yup.object().shape({
          currency_code: Yup.string().required('Currency code is required'),
          game_provider_code: Yup.string().typeError('Game provider code must be a string')
        })}
        apiUrl={INDEX_AGENT_PT_URL}
        onSubmit={onIndexSubmitHandler}
        onResponse={onResponseHandler}
      />

      <form id='agent-pt-form' className='card pt-5' onSubmit={formik.handleSubmit}>
        <table className='table table-row-bordered gs-7'>
          <thead>
          <tr className='fw-bold'>
            <th className='text-uppercase'>Game Provider Code</th>
            <th className='text-uppercase'>Game Provider Name</th>
            <th className='text-uppercase'>Enabled</th>
            <th className='text-uppercase'>Position Taking / Max Position Taking (%)</th>
            <th className='text-uppercase position-relative'>
              <div className='position-absolute top-50 start-25' style={{ transform: 'translateY(-50%)' }}>
                Edit
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <style>
            {
              `input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
                -webkit-appearance: none
              }`
            }
          </style>
          {
            agentPts.map(pt =>
              <AgentPtRow
                key={pt.game_provider_code}
                data={pt}
                onChange={onPtInputChangeHandler}
                onCancel={onPtInputCancelHandler}
                edited={pt.game_provider_code in agentPtSubmission.position_takings}
                valid={!(pt.game_provider_code in formik.errors)}
                updateSuccess={updateSuccess}
                resetUpdateSuccess={() => setUpdateSuccess(false)}
              />
            )
          }
          {
            !agentPts.length &&
            <tr>
              <td className='text-center text-muted' colSpan={5}>
                {
                  isLoading
                    ? 'Loading...'
                    : (selectedCurrency == '' ? 'Please select a currency' : 'No records found')
                }
              </td>
            </tr>
          }
          </tbody>
        </table>
        {
          !!editCount && isEmpty(formik.errors) &&
          <div className='card-footer d-flex justify-content-end py-5 sticky-bottom bg-light'>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              onClick={submitForm}
            >
              Save Changes ({editCount})
            </button>
          </div>
        }
      </form>
    </>
  )
}

export default AgentPtTable
