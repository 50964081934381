import { Route, Routes } from "react-router-dom";
import { PageTitle } from "../../../../_metronic/layout/core";
import AgentPtTable from "./components/table/AgentPtTable";

const AgentPtPage = () => {

  return (
    <Routes>
      <Route
        path='/:agentId'
        element={
        <>
          <PageTitle>Agent Position Takings</PageTitle>
          <AgentPtTable />
        </>
        }
      />
    </Routes>
  )
}

export default AgentPtPage