import axios from "axios";
import { TestExecutionLogParams } from "./TestExecutionLogModels";

const API_URL = process.env.REACT_APP_API_URL

export const TEST_EXECUTION_LOG_INDEX_URL = `${API_URL}/log/test-execution`

export const testExecutionLogIndex = (params: TestExecutionLogParams) => {
  return axios.post(TEST_EXECUTION_LOG_INDEX_URL, params)
}
