import moment from "moment";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormikContext } from "formik";

export enum DateTimeShortcuts {
  CustomRange = 'Custom Range',
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  PastWeek = 'Past 7 Days',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  PastMonth = 'Past 30 Days',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
}

interface Props {
  default: DateTimeShortcuts
  formik: any
  preset: string[]
}

const defaultPresets = ['Day', 'Week', 'Month']

const DateTimeShortcutBar = (props: Props) => {
  const [selected, setSelected] = useState<DateTimeShortcuts>(props.default)
  const [presets, setPresets] = useState<string[]>(defaultPresets)

  useEffect(() => {
    setSelected(getSelectedShortcut(props.formik.values.start_date_time, props.formik.values.end_date_time))

    const final_presets = ['CustomRange', ...props.preset.length != 0 ? props.preset : defaultPresets]
    setPresets(final_presets)

  }, [props.formik.values.start_date_time, props.formik.values.end_date_time])

  const getDateRange = (dateTimeShortcut: DateTimeShortcuts, includeTime: boolean): [string, string] => {
    let start, end;

    const startFormat = includeTime ? 'yyyy-MM-DD 00:00:00' : 'yyyy-MM-DD';
    const endFormat = includeTime ? 'yyyy-MM-DD 23:59:59' : 'yyyy-MM-DD';

    switch (dateTimeShortcut) {
      case DateTimeShortcuts.Today:
        start = moment().format(startFormat)
        end = moment().format(endFormat)
        break
      case DateTimeShortcuts.Yesterday:
        start = moment().subtract(1, 'd').format(startFormat)
        end = moment().subtract(1, 'd').format(endFormat)
        break

      case DateTimeShortcuts.ThisWeek:
        start = moment().startOf('w').format(startFormat)
        end = moment().endOf('w').format(endFormat)
        break
      case DateTimeShortcuts.LastWeek:
        start = moment().subtract(1, 'w').startOf('w').format(startFormat)
        end = moment().subtract(1, 'w').endOf('w').format(endFormat)
        break
      case DateTimeShortcuts.PastWeek:
        start = moment().subtract(7, 'd').format(startFormat)
        end = moment().subtract(1, 'd').format(endFormat)
        break
      case DateTimeShortcuts.ThisMonth:
        start = moment().startOf('M').format(startFormat)
        end = moment().endOf('M').format(endFormat)
        break
      case DateTimeShortcuts.LastMonth:
        start = moment().subtract(1, 'M').startOf('M').format(startFormat)
        end = moment().subtract(1, 'M').endOf('M').format(endFormat)
        break
      case DateTimeShortcuts.PastMonth:
        start = moment().subtract(30, 'd').format(startFormat)
        end = moment().subtract(1, 'd').format(endFormat)
        break;
      case DateTimeShortcuts.ThisYear:
        start = moment().startOf('y').format(startFormat)
        end = moment().endOf('y').format(endFormat)
        break;
      case DateTimeShortcuts.LastYear:
        start = moment().subtract(1, 'y').startOf('y').format(startFormat)
        end = moment().subtract(1, 'y').endOf('y').format(endFormat)
        break;
      case DateTimeShortcuts.CustomRange:
      default:
        return ['', '']
    }

    return [start, end];
  }

  const getSelectedShortcut = (startDateTime: string, endDateTime: string) => {
    const includeDateTime = /^.*(\d{2}:\d{2})(?::\d{2})?$/.test(startDateTime)

    if (includeDateTime) {
      startDateTime = moment(startDateTime).format('yyyy-MM-DD HH:mm:ss')
      endDateTime = moment(endDateTime).format('yyyy-MM-DD HH:mm:ss')
    }

    for (const dateTimeShortcut of Object.values(DateTimeShortcuts)) {
      const [start, end] = getDateRange(dateTimeShortcut, includeDateTime)

      if (start === startDateTime && end === endDateTime) return dateTimeShortcut
    }

    return DateTimeShortcuts.CustomRange
  }

  const pickShortcut = (dateTimeShortcut: DateTimeShortcuts) => {
    setSelected(dateTimeShortcut)

    const dateTimeInput = document.getElementById('start_date_time') as HTMLInputElement
    dateTimeInput.removeAttribute('min')
    const [start, end] = getDateRange(dateTimeShortcut, dateTimeInput.type == 'datetime-local')

    if (start == '' && end == '') return

    const endDateTimeInput = document.getElementById('end_date_time') as HTMLInputElement
    endDateTimeInput.removeAttribute('min')

    props.formik.setFieldValue('start_date_time', start)
    props.formik.setFieldValue('end_date_time', end)
  }

  return (
    <div className='container p-0'>
      <div className='row w-100'>
        <div className='card-toolbar'>
          {
            Object.entries(DateTimeShortcuts).map(dateTimeShortcut => 
              <div key={`dt-shortcut-${dateTimeShortcut[1]}-div`} >
                {
                  presets.find((preset) => dateTimeShortcut[0].toUpperCase().includes(preset.toUpperCase())) &&
                  <button
                    key={`dt-shortcut-${dateTimeShortcut[1]}`}
                    type='button'
                    className={
                      clsx(
                        'col-auto btn btn-sm btn-light me-2 mt-2',
                        { 'border border-primary border-2 text-primary fw-bold': selected == dateTimeShortcut[1] }
                      )
                    }
                    onClick={() => pickShortcut(dateTimeShortcut[1])}
                  >
                    {dateTimeShortcut[1]}
                  </button>
                }
              </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DateTimeShortcutBar