import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, PermissionModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {checkLogin, signOut, userInfo} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  permissions: PermissionModel | undefined,
  setPermissions: (auth: PermissionModel | undefined) => void
  hasPermission: (permission: string) => boolean
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  permissions: undefined,
  setPermissions: () => {},
  hasPermission: () => {return false},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [permissions, setPermissions] = useState<PermissionModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const hasPermission = (permission: string) => {
    if (!permissions)
      return false

    return (
      permissions.role === 'super-admin' || permissions.permissions.find(item => {
        return item === permission
      }) !== undefined
    )
  }

  const logout = async() => {
      try{
        const signOutResponse = await signOut()
        if(signOutResponse.data.status !== 1){
          console.error(signOutResponse.data.messages)

        } else {
          console.log('Logout Success')
          saveAuth(undefined)
          setCurrentUser(undefined)
          setPermissions(undefined)
          localStorage.clear()

        }
        
      } catch (error) {
        console.error(error)

      }
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, permissions, setPermissions, hasPermission, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser, setPermissions} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data} = await checkLogin()
          if (data.status !== 1) {
            localStorage.clear()
            logout()

          } else {
            setCurrentUser(data)

            const userInfoResponse = await userInfo()

            if (userInfoResponse.data.status === 1)
              setPermissions(userInfoResponse.data.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
