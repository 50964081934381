import { TestSession } from "../TestSessionModels";
import TestSessionStatusBadge from "./TestSessionStatusBadge";
import TableRow, { TR_STYLE } from "../../../../components/TableRow";
import ActionButtons, { BUTTON_ICONS } from "../../../../components/ActionButtons";

interface propsParam {
    testSession: TestSession;
    onUpdate: (arg0: number) => void;
    onShowExecutionLog: (testSessionId: number) => void
}

const TestSessionIndexRow = (props: propsParam) => {
    const { testSession } = props

    return (
        <TableRow
            key={testSession.id}
            styles={[TR_STYLE.HOVER_ROW]}
            contents={[
                {key: testSession.id + '_name', detail: <div className="fw-bold">{testSession.name}</div>, extra: <p><small>{testSession.remark ?? ''}</small></p>},
                {key: testSession.id + '_start_at', detail: testSession.start_at},
                {key: testSession.id + '_completed_at', detail: testSession.completed_at ?? '-'},
                {key: testSession.id + '_status', extra: <TestSessionStatusBadge testSession={props.testSession} />},
                {key: testSession.id + '_created_at', detail: testSession.created_at, extra: <p>{props.testSession.created_by.name}</p>}
            ]}
            action={
                <ActionButtons
                    buttons={[
                        {key: testSession.id + '_view_button', link: testSession.id + '/results', target: '_blank', icon: <i className="bi bi-card-checklist fs-3"></i>, overlay: 'View Results'},
                        {key: testSession.id + '_edit_button', function: () => props.onUpdate(testSession.id), icon: BUTTON_ICONS.EDIT_ICON, overlay: 'Edit Test Session', disabled: testSession.status != 0},
                        {key: testSession.id + '_log_button', function: () => props.onShowExecutionLog(testSession.id), icon: BUTTON_ICONS.LOG_ICON, overlay: 'View Execution Log'}
                    ]}
                />
            }
        />
    )
}

export default TestSessionIndexRow
