import axios from "axios";
import { ApiResponse } from "../../helpers/types/api-types";
import {
  SiteMonthlyGgrReport,
  MonthlyGgrReportParams,
  MonthlyGgrReport,
  CurrencyMonthlyGgrReport
} from "./dashboard-types";

const API_URL = process.env.REACT_APP_API_URL;

export const MONTHLY_GGR_REPORT_URL = `${API_URL}/statistics/monthly-ggr`;
export const CURRENCY_MONTHLY_GGR_REPORT_URL = `${API_URL}/statistics/monthly-ggr/currency`;
export const SITE_MONTHLY_GGR_REPORT_URL = `${API_URL}/statistics/monthly-ggr/site`;

export const monthlyGgrReport = (params: MonthlyGgrReportParams) => {
  return axios.post<ApiResponse<MonthlyGgrReport>>(MONTHLY_GGR_REPORT_URL, params);
}

export const currencyMonthlyGgrReport = (params: MonthlyGgrReportParams) => {
  return axios.post<ApiResponse<CurrencyMonthlyGgrReport>>(CURRENCY_MONTHLY_GGR_REPORT_URL, params);
}

export const siteMonthlyGgrReport = (params: MonthlyGgrReportParams) => {
  return axios.post<ApiResponse<SiteMonthlyGgrReport>>(SITE_MONTHLY_GGR_REPORT_URL, params);
}
