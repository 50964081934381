import { useFormik } from "formik";
import { ReactSelect } from "./ReactSelect";
import { KTSVG } from "../../_metronic/helpers";
import { FormControl } from 'react-bootstrap';
import clsx from "clsx";
import moment from "moment";
import { Fragment, useEffect } from "react";
import DateTimeShortcutBar, { DateTimeShortcuts } from "./DateTimeShortcutBar";
import { VALIDATE_DATE, VALIDATE_DAY, validateDateTimeMax, validateDateTimeMin } from "../helpers/interface/ValidationHelpers";

const TableFilterComponent = (
  props: {
    isLoading: boolean;
    fields: any[];
    filterSchema: any;
    onLoad: (arg0: boolean) => void;
    onFilter: any
    filterBtn: string
    exportBtn?: any
    disableExport?: any
    pendingBtn?: any
    syncBtn?: any
    message?: string
    dtShortCutPreset?: any[]
  }
) => {
  useEffect(() => {
    initiateCheckbox()
  }, [])

  const initialValues: { [key: string]: any } = {
    order: '',
    sort: '',
  }
  props.fields.map((field: any) => {
    return { ...initialValues[field.name] = field.initialValue ?? "" }
  })

  const validateDateRange = (event: any) => {
    let name = event.currentTarget.name
    let value = event.currentTarget.value

    var start_date_time = document.getElementById('start_date_time') as HTMLInputElement
    var end_date_time = document.getElementById('end_date_time') as HTMLInputElement

    if (name === 'start_date_time') {
      validateDateTimeMin(end_date_time, value, VALIDATE_DATE)
      validateDateTimeMax(end_date_time, value, VALIDATE_DAY, 45)
    }

    if (name === 'end_date_time') {
      validateDateTimeMax(start_date_time, value, VALIDATE_DATE)
      validateDateTimeMin(start_date_time, value, VALIDATE_DAY, 45)
    }
  }

  const initiateCheckbox = () => {
    props.fields.filter(field => field.type == 'checkbox').forEach(field => {
      formik.setFieldValue(field.name, field.checked)
    })
  }

  const handleCheckboxValue = (event: any) => {
    let name = event.currentTarget.name
    let value = event.currentTarget.value
    formik.setFieldValue(name, value)

    const checkboxField = document.getElementById(name) as HTMLInputElement

    if (name === 'daily') {
      var start_date_time = document.getElementById('start_date_time') as HTMLInputElement
      var end_date_time = document.getElementById('end_date_time') as HTMLInputElement

      var start_value = start_date_time.value
        ? (checkboxField.checked ? moment(start_date_time.value).format('yyyy-MM-DD') : moment(start_date_time.value).format('yyyy-MM-DDT00:00:00'))
        : "";

      var end_value = end_date_time.value
        ? (checkboxField.checked ? moment(end_date_time.value).format('yyyy-MM-DD') : moment(end_date_time.value).format('yyyy-MM-DDT23:59:59'))
        : "";

      start_date_time.type = checkboxField.checked ? 'date' : 'datetime-local';
      end_date_time.type = checkboxField.checked ? 'date' : 'datetime-local';

      formik.setFieldValue('start_date_time', start_value)
      formik.setFieldValue('end_date_time', end_value)

      //set >45 days validation
      validateDateTimeMin(end_date_time, start_value, VALIDATE_DATE)
      validateDateTimeMax(end_date_time, start_value, VALIDATE_DAY, 45)

      validateDateTimeMax(start_date_time, end_value, VALIDATE_DATE)
      validateDateTimeMin(start_date_time, end_value, VALIDATE_DAY, 45)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: props.filterSchema,
    // validateOnMount: true, // TODO: causing settlement index to be unresponsive when changing date
    onSubmit: async (values, { setSubmitting }) => {
      props.onLoad(true)
      await props.onFilter(values)
    }
  })

  const onInputHandler = (event: any, field: any) => {
    if (field.onInput) field.onInput(event, formik)
    if (field.validate) validateDateRange(event)
  }

  const renderFilterBody = (field: any) => {
    const fieldName = field.title ?? field.name;
    switch (field.type) {
      case 'text':
        return (
          <>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className={clsx(
                  'form-control form-control-solid ps-14',
                  { 'is-invalid': formik.touched[field.name] && formik.errors[field.name] }
                )}
                placeholder={field.placeholder ?? ('Search ' + fieldName)}
                {...formik.getFieldProps(field.name)}
                disabled={field.disable}
              />
            </div>
            {formik.touched[field.name] && formik.errors[field.name] && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger small'>{formik.errors[field.name]?.toString()}</span>
              </div>
            )}
          </>
        )

      case 'react-select':
        return (
          <div>
            <ReactSelect
              name={field.name}
              options={field.options}
              fixedOptions={field.fixedOptions ?? undefined}
              formik={formik}
              validate={false}
              isMulti={field.isMulti ?? false}
              placeholder={field.placeholder}
              clearable={field.clearable ?? false}
            />
          </div>
        )

      case 'select':
        let hide = field?.hideIf ? (formik.values[field?.hideIf?.name] != field?.hideIf?.equal) : false
        return (
          <select
            id={field.name}
            className='form-select form-select-solid fw-bold my-1'
            {...formik.getFieldProps(field.name)}
            onInput={(e: any) => onInputHandler(e, field)}
            disabled={field.disable}
            hidden={hide}
          >
            {field.options.length > 0 && field.options.map((option: any) => {
              return (
                <option value={option.value} key={option.value}>{option.label}</option>
              )
            })}
          </select>
        )

      case 'date':
        return (
          <div className="mb-3 col-sm">
            <FormControl
              id={field.name}
              type={field.time ? 'datetime-local' : 'date'}
              className="form-control-solid"
              {...formik.getFieldProps(field.name)}
              onInput={(e: any) => onInputHandler(e, field)}
              min={field.min ?? ''}
              step={1}
            />
            {formik.errors[field.name] && (
              <div className='fv-plugins-message-container'>
                <span role='alert' className='text-danger small'>{formik.errors[field.name]?.toString()}</span>
              </div>
            )}
          </div>
        )
      case 'checkbox':
        return (
          <div className="mb-3 col-sm form-check d-flex align-items-center form-control-plaintext">
            <input
              id={field.name}
              type='checkbox'
              data-kt-user-table-filter='search'
              className='form-check-input'
              {...formik.getFieldProps(field.name)}
              onClick={handleCheckboxValue}
              checked={formik.values[field.name]}
            />
            <label className='text-muted fw-semibold fs-7 text-uppercase ms-2 user-select-none' htmlFor={field.name}>{fieldName}</label>
          </div>
        )
    }
  }

  const resetFilter = () => {
    formik.resetForm();

    if (initialValues.daily) {
      const dailyCheckbox = document.getElementById('daily') as HTMLInputElement

      var yesterday_start = dailyCheckbox.checked ? moment().subtract(1, 'days').format('yyyy-MM-DD') : moment().subtract(1, 'days').format('yyyy-MM-DDT00:00:00')
      var yesterday_end = dailyCheckbox.checked ? moment().subtract(1, 'days').format('yyyy-MM-DD') : moment().subtract(1, 'days').format('yyyy-MM-DDT23:59:59')

      formik.setFieldValue('start_date_time', yesterday_start)
      formik.setFieldValue('end_date_time', yesterday_end)
      formik.setFieldValue('daily', dailyCheckbox.checked)

      var end_date_time = document.getElementById('end_date_time') as HTMLInputElement

      end_date_time.min = ""
      end_date_time.max = ""
    }

    initiateCheckbox()

    setTimeout(() => {
      formik.validateForm();
    }, 0);
  }

  return (
    <div className='card mb-5 py-2'>
      {/* begin::Header */}
      <form
        autoComplete="off"
        className='card-header border-0'
        onSubmit={formik.handleSubmit}
      >
        <div className="container p-0">
          <div className="row">
            {props.fields.length > 0 && props.fields.map((field: any) => {
              const fieldName = field.title ?? field.name;
              let required = (props.filterSchema.fields[field.name]?.exclusiveTests?.required ?? false) ? 'required' : ''
              let hide = field?.hideIf ? (formik.values[field?.hideIf?.name] != field?.hideIf?.equal) : false

              const colSizeLg = field.colSizeLg ?? 3

              return (
                <Fragment key={field.name}>
                  {
                    field.offset &&
                    [...Array(field.offset)].map((value, index) =>
                      <div key={`offset-${field.name}-${index}`} className='d-block col-md-12 col-lg-3 m-0 pe-5'></div>
                    )
                  }
                  <div className={`card-title d-block col-md-12 col-lg-${colSizeLg} m-0 pe-5`}>
                    <label className={`text-muted fw-semibold fs-7 text-uppercase ${required}`} hidden={hide}>
                    {field.type != 'checkbox' && fieldName}
                    </label>
                    {renderFilterBody(field)}
                  </div>
                </Fragment>
              )
            })}
          </div>
        </div>
        {
          props.fields.filter(field => field.type === 'date').length > 0 &&
          <DateTimeShortcutBar
            default={DateTimeShortcuts.CustomRange}
            formik={formik}
            preset={props.dtShortCutPreset ?? []}
          />
        }
        <div className="container p-0">
          <div className="row w-100">
            <div className='card-toolbar d-flex justify-content-between align-items-center'>
              <div>
                <button
                  disabled={formik.isSubmitting || props.isLoading || Object.keys(formik.errors).length > 0}
                  type='submit'
                  className='btn btn-sm btn-light-primary me-3'
                >
                  <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                  {props.filterBtn}
                </button>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary me-3 mt-sm-3 mt-lg-0'
                  onClick={() => {
                    resetFilter()
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
                  Clear Filters
                </button>
                {props.exportBtn ? (
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary me-3 mt-sm-3 mt-lg-0'
                    onClick={props.exportBtn}
                    disabled={props.disableExport}
                  >
                    <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-2' />
                    Export
                  </button>
                ) : null}
              </div>
              <div>
                {props.pendingBtn ? (
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary me-3 mt-sm-3 mt-lg-0'
                    onClick={() => {
                      resetFilter()
                      props.pendingBtn()
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/abstract/abs013.svg' className='svg-icon-2' />
                    Show Pending
                  </button>
                ) : null}
                {props.syncBtn ? (
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary'
                    onClick={props.syncBtn}
                    disabled={true}
                  >
                    <KTSVG path='/media/icons/duotune/files/fil019.svg' className='svg-icon-2' />
                    Sync Vendor
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-5"> 
          {props.message ? (<>
            <p className=" fw-bold fs-7">{props.message}</p>
          </>) : null}
          </div>
        </div>
      </form>
    </div>
  )
}

TableFilterComponent.defaultProps = {
  filterBtn: 'Search'
}

export { TableFilterComponent }
