import {Route, Routes} from "react-router-dom";
import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import SgpMaintenanceTable from "./components/SgpMaintenanceTable";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Support',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SgpMaintenancePage = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Site Provider Maintenance</PageTitle>
            <SgpMaintenanceTable />
          </>
        }
      />
    </Routes>
  )
}

export default SgpMaintenancePage
