import axios from 'axios'
import {ApiResponseModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/user/auth/login`
export const CHECK_LOGIN_URL = `${API_URL}/user/auth/login/check`
export const LOGOUT_URL = `${API_URL}/user/auth/logout`
export const CHANGE_PASS_URL = `${API_URL}/user/auth/change-password`
export const GET_USER_INFO = `${API_URL}/user/auth/getUserInfo`

// Server should return AuthModel
export function login(username: string, password: string) {
    return axios.post<ApiResponseModel>(LOGIN_URL, {
        username,
        password,
    })
}

export function checkLogin() {
    return axios.post<UserModel>(CHECK_LOGIN_URL)
}

export function signOut() {
    return axios.post(LOGOUT_URL)
}

export function changePassword(password: string, password_confirmation: string) {
    return axios.post(CHANGE_PASS_URL, {
        password,
        password_confirmation,
    })
}

export function userInfo() {
    return axios.post(GET_USER_INFO)
}
