import clsx from "clsx";
import { useEffect, useState } from "react";
import Select, {
  ActionMeta,
  ClassNamesConfig,
  ControlProps,
  CSSObjectWithLabel,
  OptionProps,
  SingleValue,
  SingleValueProps,
  Theme,
  ThemeConfig
} from "react-select";
import { SelectOptionModel } from "../../Helpers";
import "../react-select-styles.scss"
import { ReactSingleSelectProps } from "../react-select-types";

const ReactSelect = (props: ReactSingleSelectProps) => {
  const {
    name,
    options,
    value,
    defaultValue,
    disabled,
    isLoading,
    placeholder,
    onChange,
    onBlur,
  } = props

  const [allOptions, setAllOptions] = useState<SelectOptionModel[]>([])
  const [selectedOption, setSelectedOption] = useState<SelectOptionModel | null>(null)

  useEffect(() => {
    setAllOptions(options)

    const selectedValue: string = value ?? defaultValue ?? ''
    const selectedOption = options.find((option: SelectOptionModel): boolean => option.value == selectedValue)
    setSelectedOption(selectedOption ?? null)
  }, [options, value, defaultValue])

  const onChangeHandler = (newValue: SingleValue<SelectOptionModel>, _actionMeta: ActionMeta<SelectOptionModel>) => {
    if (newValue?.value === selectedOption?.value) return

    setSelectedOption(newValue)

    if (onChange !== undefined) {
      const selectedValue: string = newValue?.value ?? ''
      onChange(selectedValue)
    }
  }

  const getIsLoading = () => {
    if (isLoading !== undefined) return isLoading

    if (disabled) return false

    return !options.length
  }

  return (
    <Select
      id={`react-select-${name}`}
      name={name}
      options={allOptions}
      value={selectedOption}
      placeholder={placeholder}
      isDisabled={disabled}
      isLoading={getIsLoading()}
      onChange={onChangeHandler}
      onBlur={onBlur}
      className={clsx(
        'form-control form-control-solid p-1',
        { 'user-select-none': disabled },
      )}
      classNames={singleSelectClasses}
      theme={customTheme}
      styles={{
        menu: (base: CSSObjectWithLabel) => {
          return { ...base, zIndex: 9999 }
        }
      }}
    />
  )
}

export default ReactSelect

const singleSelectClasses: ClassNamesConfig<SelectOptionModel, false> = {
  control: (props: ControlProps<SelectOptionModel, false>): string => {
    const baseClasses = 'custom-react-select'
    return props.isDisabled ? baseClasses + ' disabled' : baseClasses
  },
  input: (): string => 'text-dark',
  menu: (): string => 'custom-react-select',
  singleValue: (props: SingleValueProps<SelectOptionModel, false>): string => {
    const baseClasses = 'custom-react-select'
    return props.isDisabled ? baseClasses + ' disabled' : baseClasses
  },
  option: (props: OptionProps<SelectOptionModel, false>): string => {
    return props.isFocused ? 'custom-option focused' : 'custom-option'
  },
}

export const customTheme: ThemeConfig = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--kt-primary)',
    primary50: '',
  }
})
