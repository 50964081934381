import axios from "axios";
import { PDPDReportFilter, ReportActionHistoryFilter } from "./Models";

const API_URL = process.env.REACT_APP_API_URL

export const reportStatusUrl = `${API_URL}/report/status`
export const reportActionHistoryUrl = `${API_URL}/report/status/history`

export const reportStatus = (filters: PDPDReportFilter) => {
    return axios.post(reportStatusUrl, {...filters})
}

export const getReportActionHistory = (filters: ReportActionHistoryFilter) => {
    return axios.post(reportActionHistoryUrl, {...filters})
}
