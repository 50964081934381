import clsx from "clsx";
import { TestExecutionLog } from "../TestExecutionLogModels";

const TestExecutionLogRow = ({ row }: { row: TestExecutionLog }) => {
  const data: string = JSON.stringify(row.data, null, 2)

  const EventIcon = ({ row }: { row: TestExecutionLog }) => {
    switch (row.event) {
      case 'api_request':
        return <i className="fa-solid fa-arrow-right-to-bracket me-3 fs-3 text-primary"></i>
      case 'api_response':
        return <i className="fa-solid fa-arrow-right-to-bracket fa-rotate-180 ms-1 me-2 fs-3 text-success"></i>
      default:
        return <i className="fa-regular fa-circle-question me-3 fs-3 text-warning"></i>
    }
  }

  return (
    <tr>
      <td className={clsx('py-4 fw-bold', { 'border-bottom': row.event == 'api_response' })}>
        {row.event == 'api_request' && row.test_template_name}
      </td>
      <td className='py-4 fw-bold border-bottom'>{row.date_time}</td>
      <td className='py-4 border-bottom'>
        <div className='d-flex align-items-center'>
          <EventIcon row={row} />
          {row.event_string}
        </div>
      </td>
      <td className='py-4 border-bottom'>{row.url}</td>
      <td className='py-4 border-bottom align-top position-relative min-w-400px mw-400px'>
        <pre className='p-2 code-snippet'>{data}</pre>
      </td>
    </tr>
  )
}

export default TestExecutionLogRow
