import axios from "axios";
import { TestTemplateIndexParams } from "./TestTemplateModels";

const API_URL = process.env.REACT_APP_API_URL

export const TEST_TEMPLATE_OPTIONS_URL = `${API_URL}/test-template/options`
export const TEST_TEMPLATE_INDEX_URL = `${API_URL}/test-template/index`

export const testTemplateOptions = () => {
  return axios.post(TEST_TEMPLATE_OPTIONS_URL)
}

export const testTemplateIndex = (params: TestTemplateIndexParams) => {
  return axios.post(TEST_TEMPLATE_INDEX_URL, params)
}
