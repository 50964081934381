import { S3File } from "../../Models";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import File from "../File";

interface Props {
  show: boolean
  type: 'bill' | 'receipt' | ''
  files: S3File[]
  onClose: () => void
  name: string
  code: string
}

const InvoiceFilesModal = (props: Props) => {

  return createPortal(
    <Modal
      id='kt_modal_invoice_files'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={props.show}
      scrollable
      onHide={props.onClose}
    >
      <div className='modal-header py-3'>
        <h2>
          {
            {
              'bill': 'Bill',
              'receipt': 'Receipt',
              '': ''
            }[props.type]
          } - {props.name} ({props.code})
        </h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.onClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-3'>
        {props.files.map(file => <File key={file.uuid} file={file} deletable={false} />)}
        {
          !props.files.length &&
          <div className='text-muted text-center my-2'>
            This invoice does not have any {props.type}.
          </div>
        }
      </div>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default InvoiceFilesModal