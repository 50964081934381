import clsx from "clsx";
import { useEffect, useState } from "react";
import Select, {
  ActionMeta,
  ClassNamesConfig,
  ClearIndicatorProps,
  ControlProps,
  CSSObjectWithLabel,
  MultiValue,
  MultiValueProps,
  OptionProps,
  StylesConfig
} from "react-select";
import { isEqualArray } from "../../../helpers/functions";
import { SelectOptionModel } from "../../Helpers";
import { ReactMultiSelectProps } from "../react-select-types";
import { customTheme } from "./ReactSelect";

const ReactMultiSelect = (props: ReactMultiSelectProps) => {
  const {
    name,
    options,
    value,
    fixedValues,
    disabled,
    isLoading,
    placeholder,
    clearable,
    onChange,
    onBlur,
  } = props

  const [allOptions, setAllOptions] = useState<SelectOptionModel[]>([])
  const [selectedOptions, setSelectedOptions] = useState<readonly SelectOptionModel[]>([])

  useEffect(() => {
    let selectedValues: string[] = value ?? []
    let selectedOptions: SelectOptionModel[] = options.filter((option: SelectOptionModel) => selectedValues.includes(option.value))

    if (fixedValues === undefined) {
      setAllOptions(options)
      setSelectedOptions(selectedOptions)
      return
    }

    const fixedOptions: SelectOptionModel[] = options
      .filter((option: SelectOptionModel) => fixedValues.includes(option.value))
      .map((fixedOption: SelectOptionModel): SelectOptionModel => ({ ...fixedOption, isFixed: true }))

    const allOptions: SelectOptionModel[] = sortOptions(mergeOptions(options, fixedOptions))
    selectedOptions = sortOptions(mergeOptions(selectedOptions, fixedOptions))

    setAllOptions(allOptions)
    setSelectedOptions(selectedOptions)
  }, [options, value, fixedValues])

  const onChangeHandler = (newValues: MultiValue<SelectOptionModel>, actionMeta: ActionMeta<SelectOptionModel>) => {
    const values: string[] = newValues.map((option: SelectOptionModel) => option.value)
    let selectedValues: string[] = selectedOptions.map((option: SelectOptionModel) => option.value)

    if (isEqualArray<string>(values, selectedValues)) return

    switch (actionMeta.action) {
      case "pop-value":
      case "remove-value":
        if (actionMeta.removedValue.isFixed) return
        break
      case "clear":
        if (fixedValues === undefined) break

        // prevent onChange from firing if all selected options are fixed
        if (selectedOptions.length == fixedValues.length) return

        newValues = allOptions.filter((value: SelectOptionModel) => value.isFixed)
        break
    }

    setSelectedOptions(newValues)

    if (onChange !== undefined) {
      selectedValues = newValues.map((option: SelectOptionModel) => option.value)
      onChange(selectedValues)
    }
  }

  const getIsLoading = () => {
    if (isLoading !== undefined) return isLoading

    if (disabled) return false

    return !options.length
  }

  return (
    <Select
      id={`react-select-${name}`}
      name={name}
      value={selectedOptions}
      options={allOptions}
      isMulti={true}
      closeMenuOnSelect={false}
      isDisabled={disabled}
      isLoading={getIsLoading()}
      placeholder={placeholder}
      isClearable={clearable}
      onChange={onChangeHandler}
      onBlur={onBlur}
      styles={multiSelectStyles}
      className={clsx(
        'form-control form-control-solid p-1',
        { 'user-select-none': disabled },
      )}
      classNames={multiSelectClasses}
      theme={customTheme}
    />
  )
}

export default ReactMultiSelect

const sortOptions = (options: SelectOptionModel[]) => { // move fixed options to the beginning of array
  const fixedOptions: SelectOptionModel[] = options.filter((value: SelectOptionModel) => value.isFixed)
  const nonFixedOptions: SelectOptionModel[] = options.filter((value: SelectOptionModel) => !value.isFixed)

  return fixedOptions.concat(nonFixedOptions)
}

const mergeOptions = (...options: SelectOptionModel[][]): SelectOptionModel[] => {
  const mergedOptions = options.reduce((accumulatedOptions: SelectOptionModel[], currentOptions: SelectOptionModel[]) => {
    return [...accumulatedOptions, ...currentOptions]
  })

  // filter out dupes if specified in both fixed options and default options
  const fixedValues: string[] = mergedOptions
    .filter((option: SelectOptionModel) => option.isFixed)
    .map((fixedOption: SelectOptionModel) => fixedOption.value)

  return mergedOptions.filter((option: SelectOptionModel) => !(fixedValues.includes(option.value) && !option.isFixed))
}

const multiSelectStyles: StylesConfig<SelectOptionModel, true> = {
  multiValue: (base: CSSObjectWithLabel, state: MultiValueProps<SelectOptionModel, true>) => {
    const backgroundColor: string = state.isDisabled ? '' : 'var(--kt-form-select-disabled-bg)!important'

    return state.data.isFixed
      ? { ...base, boxSizing: 'border-box', backgroundColor: backgroundColor }
      : { ...base, boxSizing: 'border-box' }
  },
  multiValueLabel: (base: CSSObjectWithLabel, state: MultiValueProps<SelectOptionModel, true>) => {
    return state.data.isFixed ? { ...base, paddingRight: 6 } : base
  },
  multiValueRemove: (base: CSSObjectWithLabel, state: MultiValueProps<SelectOptionModel, true>) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base
  },
  clearIndicator: (base: CSSObjectWithLabel, props: ClearIndicatorProps<SelectOptionModel, true>) => {
    return props.getValue().filter((option: SelectOptionModel) => option.isFixed === undefined).length > 0
      ? base
      : { ...base, display: 'none' }
  },
  menu: (base: CSSObjectWithLabel) => {
    return { ...base, zIndex: 9999 }
  },
}

const multiSelectClasses: ClassNamesConfig<SelectOptionModel, true> = {
  control: (props: ControlProps<SelectOptionModel, true>): string => {
    const baseClasses = 'custom-react-multi-select'
    return props.isDisabled ? baseClasses + ' disabled' : baseClasses
  },
  input: (): string => 'text-dark',
  menu: (): string => 'custom-react-multi-select',
  multiValue: (): string => 'me-1 custom-react-multi-select rounded border border-1 border-secondary',
  multiValueLabel: (): string => 'text-dark',
  multiValueRemove: (): string => 'bg-transparent',
  option: (props: OptionProps<SelectOptionModel, true>): string => {
    return props.isFocused ? 'custom-option focused' : 'custom-option'
  },
}
