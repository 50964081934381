import axios, { AxiosResponse } from "axios";
import { FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION } from "../../../helpers/constants";
import { removeEmptyAttributes } from "../../../helpers/functions";
import {
  ApiRequest,
  ApiResponse,
  FinalRequest,
  RequestPagination,
  RequestWithSort
} from "../../../helpers/types/api-types";
import { checkRequestResponse } from "../../Helpers";
import MessageModal from "../../MessageModal";
import {
  CheckboxGroup,
  DateInput,
  DateTimeInput,
  TableFilterButtonProps,
  TableFilterInput,
  TableFilterProps
} from "../table-filter-types";
import TableFilterButton from "./TableFilterButton";
import TableFilterCheckboxGroup from "./TableFilterCheckboxGroup";
import TableFilterDateTimeShortcut from "./TableFilterDateTimeShortcut";
import TableFilterField from "./TableFilterField";

const TableFilter = <Params extends ApiRequest, Response>(props: TableFilterProps<Params, Response>) => {
  type Props = TableFilterProps<Params, Response>

  const {
    fields,
    validationSchema,
    apiUrl,
    onSubmit,
    onResponse,
    setIsLoading,
    submitOnInit,
    pagination,
    sort,
    buttons,
    footer,
    values,
    children,
    hidden
  } = props

  const [submit, setSubmit] = useState<boolean>(false)

  useEffect(() => {
    if (submitOnInit) {
      formik.handleSubmit()
    }
  }, [])

  useEffect(() => {
    if (values === undefined) return

    if (values.resetOtherFields === true) {
      formik.resetForm()
    }

    for (const field in values) {
      if (field === 'submit') continue
      formik.setFieldValue(field, values[field])
    }

    // workaround to auto submit form using useEffect
    if (values.submitForm === true) {
      setSubmit(true)
    }
  }, [values])

  useEffect(() => {
    if (sort === undefined) return

    // submit every time sort changes (setSort when clicking on table column header)
    setSubmit(true)
  }, [sort])

  useEffect(() => {
    if (!submit) return

    formik.handleSubmit()

    setSubmit(false)
  }, [submit])

  const onSubmitHandler = async (params: Params): Promise<boolean> => {
    const onSubmitResult = await onSubmit(params)

    if (onSubmitResult === false) return true

    let finalParams: FinalRequest<Params> = params

    // if onSubmit returns a modified set of params, use it instead
    if (onSubmitResult !== true && onSubmitResult) finalParams = onSubmitResult

    if (sort !== undefined) finalParams = { ...finalParams, ...sort }

    if (pagination !== false || pagination !== undefined) finalParams = { ...finalParams, ...pagination, page: 1 }

    finalParams = removeEmptyAttributes<Params | RequestWithSort<Params>>(finalParams) as FinalRequest<Params>

    try {
      const axiosResponse: AxiosResponse<ApiResponse<Response>> = await axios.post<ApiResponse<Response>>(apiUrl, finalParams)

      if(setIsLoading) {
        setIsLoading(false)
      }
      
      if (!checkRequestResponse(axiosResponse)) return false

      const apiResponse: ApiResponse<Response> = axiosResponse.data
      onResponse(apiResponse, finalParams)

      return true
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed' })

      return false
    }
  }

  const formik: FormikProps<Params> = useFormik<Params>({
    initialValues: getFormikInitialValues(fields),
    validationSchema: validationSchema,
    onSubmit: onSubmitHandler
  })

  const onResetHandler = () => {
    formik.resetForm()
  }

  if (hidden) {
    return null; // Hide the filter bar when hidden is true
  }

  return (
    <>
      <div className="card mb-5">
        <form className="card-body" onSubmit={formik.handleSubmit}>
          <div className="row">
            {
              fields.map((field: TableFilterInput) => {
                if (field.type === 'checkbox-group') {
                  return (
                    <TableFilterCheckboxGroup<Params>
                      key={`cg-${field.label}`}
                      field={field}
                      formik={formik}
                    />
                  )
                }

                const isRequired: boolean = validationSchema.fields[field.name]?.exclusiveTests.required ?? false

                return (
                  <TableFilterField<Params>
                    key={field.name}
                    field={field}
                    required={isRequired}
                    formik={formik}
                  />
                )
              })
            }
          </div>
          <TableFilterDateTimeShortcut<Params>
            dateTimeFields={fields.filter((field: TableFilterInput) => field.type === 'date' || field.type === 'date-time') as DateInput[] | DateTimeInput[]}
            formik={formik}
          />
          <div className="row mt-1">
            <div className='col d-md-flex justify-content-md-between'>
              <div className='d-flex gap-2 mb-2 mb-md-0'>
                <TableFilterButton
                  type='submit'
                  label='Search'
                  icon={{ sourceType: "path", source: '/media/icons/duotune/general/gen021.svg' }}
                  disabled={formik.isSubmitting}
                />
                <TableFilterButton
                  type='button'
                  label='Reset Filters'
                  icon={{ sourceType: "class", source: 'fa-solid fa-rotate-left' }}
                  color='light-danger'
                  disabled={formik.isSubmitting}
                  onClick={onResetHandler}
                />
              </div>
              {buttons !== undefined && (
                <div className='d-flex gap-2'>
                  {buttons.map((buttonProps: TableFilterButtonProps, index: number) => (
                    <TableFilterButton
                      key={`tf-btn-${index}`}
                      {...buttonProps}
                      disabled={buttonProps.disabled ?? formik.isSubmitting}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          {footer &&
            <div className="row mt-1">
              <div className="col">
                {footer}
              </div>
            </div>
          }
        </form>
      </div>
      {children} {/* TODO: inject result table as a child */}
      {/**
       * TODO:
       * - place TablePagination here
       * - onPaginate changes requestPagination state & setSubmit(true)
       * - will be done in TablePagination refactor task
       */}
    </>
  )
}

export default TableFilter

const getFormikInitialValues = <Params extends ApiRequest>(fields: TableFilterInput[]): Params => {
  const formikInitialValues: ApiRequest = {}

  fields.forEach((field: TableFilterInput) => {
    if (field.type === 'checkbox-group') {
      for (const checkbox of field.checkboxes) {
        formikInitialValues[checkbox.name] = checkbox.defaultValue
      }

      return
    }

    if (field.type === 'multi-select' && field.fixedValues !== undefined) {
      formikInitialValues[field.name] = field.fixedValues.concat(field.defaultValue ?? [])
      return
    }

    if (field.defaultValue !== undefined) {
      formikInitialValues[field.name] = field.defaultValue
      return
    }

    formikInitialValues[field.name] = getEmptyFieldValue(field.type)
  })

  return formikInitialValues as Params
}

const getEmptyFieldValue = (fieldType: Exclude<TableFilterInput['type'], CheckboxGroup>): string | string[] | boolean => {
  switch (fieldType) {
    case "multi-select":
      return []
    default:
      return ''
  }
}
