export default class FileExport<Row> {
  private _name: string
  private _data: Row[]

  constructor(name: string, data: Row[]) {
    this._name = name
    this._data = data
  }

  public export() {
    const csv: string = this.asCsv()
    const blob = new Blob([csv], { type: 'text/csv' })

    const link: HTMLAnchorElement = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.download = this._name
    link.click()
  }

  private asCsv(): string {
    const headers: string = Object.keys(this._data[0]).join(',')
    const rows: string = this._data.map((data: Row) => Object.values(data).join(',')).join('\n')

    return headers + '\n' + rows
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get data(): Row[] {
    return this._data;
  }

  set data(value: Row[]) {
    this._data = value;
  }
}
