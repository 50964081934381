import { ResponsePagination } from "../../helpers/types/api-types";

export interface RequestParameters {
  [param: string]: string | number | object | any[]
}

export interface RequestResponse {
  status: 0 | 1
  messages: string[]
  data: any
}

export type Pagination = ResponsePagination

export const REQUEST_PARAMS_ORDER: (keyof RequestParameters)[] = [
  'url',
  'username',
  'signature_key',
  'other_key',
  'report_key',
  'decrypt_key',
  'prefix'
];
export const REQUEST_RESPONSE_ORDER: (keyof RequestResponse)[] = [
  'status',
  'messages',
  'data'
];
