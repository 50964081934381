import clsx from "clsx";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import * as Yup from "yup";
import { capitalize, checkRequestResponse, SelectOptionModel, sortByKey } from "../../../../../components/Helpers";
import MessageModal from "../../../../../components/MessageModal";
import TableFilter from "../../../../../components/table-filter/components/TableFilter";
import { ApiResponse, FinalRequest } from "../../../../../helpers/types/api-types";
import {
  TestTemplate,
  TestTemplateGroupable,
  TestTemplateGroups,
  TestTemplateIndexParams,
  TestTemplateOptions
} from "../../../template/TestTemplateModels";
import { TEST_TEMPLATE_INDEX_URL, testTemplateOptions } from "../../../template/TestTemplateRequests";
import { REQUEST_PARAMS_ORDER, REQUEST_RESPONSE_ORDER, RequestParameters, RequestResponse } from "../../../TestModels";
import "./test-session-modal-styles.scss"

interface Props {
  show: boolean
  selectedTestTemplates: TestTemplate[]
  onSelectTemplate: (testTemplate: TestTemplate) => void
}

const TestTemplateList = (props: Props) => {
  const {
    show,
    selectedTestTemplates,
    onSelectTemplate,
  } = props

  const [domReady, setDomReady] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [testCategoryOptions, setTestCategoryOptions] = useState<SelectOptionModel[]>([])
  const [testGroupOptions, setTestGroupOptions] = useState<SelectOptionModel[]>([])
  const [tagOptions, setTagOptions] = useState<SelectOptionModel[]>([])
  const [templateGroupBy, setTemplateGroupBy] = useState<TestTemplateGroupable>('category')
  const [testTemplates, setTestTemplates] = useState<TestTemplate[]>([])
  const [templateGroups, setTemplateGroups] = useState<TestTemplateGroups>({})

  useEffect(() => {
    setDomReady(true)
  }, [])

  useEffect(() => {
    void loadTemplateOptions()
  }, [show])

  useEffect(() => {
    const groups: TestTemplateGroups = {}

    for (const template of testTemplates) {
      const groupByValue: string = template[templateGroupBy]

      if (groups[groupByValue] === undefined) {
        groups[groupByValue] = [template]
        continue
      }

      groups[groupByValue].push(template)
    }

    setTemplateGroups(groups)
  }, [testTemplates, templateGroupBy])

  const loadTemplateOptions = async () => {
    try {
      const response = await testTemplateOptions()

      if (!checkRequestResponse(response)) {
        console.error('Error loading template options')
        return
      }

      const data: TestTemplateOptions = response.data.data

      const testCategoryOptions: SelectOptionModel[] = data.test_category.map((category: string): SelectOptionModel => {
        return { value: category, label: capitalize(category.replace(/[-_]/g, ' '), true) }
      }).sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))
      testCategoryOptions.unshift({ value: '', label: 'All' })

      const testGroupOptions: SelectOptionModel[] = data.test_groups.map((group: string): SelectOptionModel => {
        return { value: group, label: capitalize(group) }
      }).sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))
      testGroupOptions.unshift({ value: '', label: 'All' })

      const tagOptions: SelectOptionModel[] = Object.entries(data.tags).map(([key, tag]) => {
        return { value: key, label: capitalize(tag) }
      }).sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))
      tagOptions.unshift({ value: '', label: 'All' })

      setTestCategoryOptions(testCategoryOptions)
      setTestGroupOptions(testGroupOptions)
      setTagOptions(tagOptions)
    } catch (error) {
      console.error(error)
      MessageModal({ type: 'failed' })
    }
  }

  const onSelectGroup = (event: ChangeEvent<HTMLInputElement>, group: string) => {
    const adding: boolean = event.currentTarget.checked
    templateGroups[group].forEach(template => {
      const selected: boolean = selectedTestTemplates.some(selectedTemplate => selectedTemplate.id === template.id)

      if ((adding && selected) || (!adding && !selected)) {
        return
      }

      onSelectTemplate(template)
    })
  }

  const isGroupAllChecked = (group: string) => {
    const allIds: number[] = templateGroups[group].map(template => template.id)
    const selectedIds: number[] = selectedTestTemplates.map(template => template.id)

    return allIds.every(id => selectedIds.includes(id))
  }

  const onRowClickHandler = (event: React.MouseEvent) => {
    const clickedElement: HTMLElement = event.target as HTMLElement

    if (clickedElement.matches('input[type=checkbox]')) {
      return
    }

    const checkbox: HTMLInputElement = clickedElement.closest('tr')!.querySelector('input[type=checkbox]')!
    checkbox.click()
  }

  const onIndexResponseHandler = (response: ApiResponse<TestTemplate[]>, params: FinalRequest<TestTemplateIndexParams>) => {
    let templates: TestTemplate[]

    if ('paginations' in response.data) {
      templates = response.data.rows ?? []
    } else {
      templates = response.data
    }

    templates = templates.map((template: TestTemplate) => {
      return {
        ...template,
        required_parameters: sortByKey<RequestParameters>(template.required_parameters, REQUEST_PARAMS_ORDER),
        expected_response: sortByKey<RequestResponse>(template.expected_response, REQUEST_RESPONSE_ORDER)
      }
    })

    setTestTemplates(templates)
    setIsLoading(false)
  }

  return (
    <>
      {/* table filter */}
      <div id='cts-table-filter' className='mb-8'></div>
      {domReady && createPortal(
        <TableFilter<TestTemplateIndexParams, TestTemplate[]>
          fields={[
            {
              label: 'Template Name',
              name: 'name',
              type: 'text',
              placeholder: 'Search template name'
            },
            {
              label: 'Route',
              name: 'route',
              type: 'text',
              placeholder: 'Search route',
              colSizeLg: 6
            },
            {
              label: 'Test Category',
              name: 'category',
              type: 'select',
              options: testCategoryOptions,
              newRow: true
            },
            {
              label: 'Test Group',
              name: 'group',
              type: 'select',
              options: testGroupOptions
            },
            {
              label: 'Tag',
              name: 'tag',
              type: 'select',
              options: tagOptions
            },
          ]}
          validationSchema={Yup.object().shape({
            name: Yup.string().optional(),
            route: Yup.string().optional(),
            group: Yup.string().optional(),
            category: Yup.string().optional(),
            tag: Yup.number().optional()
          })}
          apiUrl={TEST_TEMPLATE_INDEX_URL}
          onSubmit={() => setIsLoading(true)}
          onResponse={onIndexResponseHandler}
          pagination={false}
          submitOnInit={true}
          buttons={[
            {
              label: templateGroupBy === 'group' ? 'Group by category' : 'Group by group',
              icon: {
                sourceType: 'class',
                source: 'fa-solid fa-arrow-right-arrow-left'
              },
              onClick: () => setTemplateGroupBy(templateGroupBy === 'group' ? 'category' : 'group')
            }
          ]}
        />, document.getElementById('cts-table-filter')!)
      }
      {/* template selection list */}
      <table className='table table-hover'>
        <thead>
        <tr className='fw-bold bg-secondary'>
          <th className='ps-5'>Template Name</th>
          <th>Route</th>
          <th>{templateGroupBy === 'group' ? 'Category' : 'Group'}</th>
          <th>Tag</th>
          <th className='text-end pe-5'>Select</th>
        </tr>
        </thead>
        <tbody>
        {!isLoading && Object.keys(templateGroups).map((group: string) => (
          <Fragment key={group}>
            <tr className='bg-light cursor-pointer user-select-none' onClick={onRowClickHandler}>
              <td className='text-capitalize ps-5 fw-bold' colSpan={4}>
                {templateGroupBy === 'group' ? 'Group - ' : 'Category - '}
                {group.replace(/[-_]/g, ' ')}
              </td>
              <td className='text-end pe-7'>
                <input
                  type='checkbox'
                  className="form-check-input cursor-pointer"
                  style={{ borderStyle: "solid", borderWidth: "1.5px", borderColor: "lightgrey" }}
                  checked={isGroupAllChecked(group)}
                  onChange={event => onSelectGroup(event, group)}
                />
              </td>
            </tr>
            {templateGroups[group].map((template: TestTemplate) => (
              <tr
                key={template.id}
                onClick={onRowClickHandler}
                className={clsx(
                  'cursor-pointer user-select-none',
                  { 'bg-light-primary': selectedTestTemplates.find(selectedTemplate => template.id === selectedTemplate.id) !== undefined },
                )}
              >
                <td className='text-capitalize ps-5'>{template.name.replace(/[-_]/g, ' ')}</td>
                <td>{template.route}</td>
                <td className='text-capitalize'>{templateGroupBy === 'group' ? template.category : template.group}</td>
                <td className='text-capitalize'>{template.tag}</td>
                <td className='text-end pe-7'>
                  <input
                    type='checkbox'
                    className='form-check-input cursor-pointer'
                    style={{ borderStyle: "solid", borderColor: "lightgrey" }}
                    checked={selectedTestTemplates.find(selectedTemplate => template.id === selectedTemplate.id) !== undefined}
                    onChange={() => onSelectTemplate(template)}
                  />
                </td>
              </tr>
            ))}
          </Fragment>
        ))}
        </tbody>
      </table>
      {!isLoading && Object.keys(templateGroups).length === 0 &&
        <div className='text-muted text-center'>
          No templates found.
        </div>
      }
      {isLoading && <div className='text-muted text-center'>Loading...</div>}
    </>
  )
}

export default TestTemplateList
