import { FormikErrors, FormikProps } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { TestSessionParams, TestTemplate } from "../../../template/TestTemplateModels";
import { RequestParameters } from "../../../TestModels";
import { CommonParams } from "../../TestSessionModels";

interface Props {
  selectedTemplates: TestTemplate[]
  commonParams: CommonParams
  formik: FormikProps<TestSessionParams>
  isUpdate: boolean
}

const TestTemplateForm = (props: Props) => {
  const {
    selectedTemplates,
    commonParams,
    formik,
    isUpdate,
  } = props

  const [sortedTemplates, setSortedTemplates] = useState<TestTemplate[]>([])

  useEffect(() => {
    formik.setFieldValue('test_templates', [])

    const sorted: TestTemplate[] = selectedTemplates.sort((a, b) => {
      if (a.run_sequence === b.run_sequence) {
        return a.name.localeCompare(b.name)
      }

      return a.run_sequence - b.run_sequence
    })

    setSortedTemplates(sorted)
  }, [selectedTemplates])

  useEffect(() => {
    sortedTemplates.forEach((template: TestTemplate, index: number) => {
      let fieldValue: string
      const params: RequestParameters = template.required_parameters

      if (!isUpdate) {
        const paramsWithReplacedValues: RequestParameters = { ...params }

        for (const params in commonParams) {
          if (paramsWithReplacedValues[params] !== undefined) {
            paramsWithReplacedValues[params] = commonParams[params as keyof CommonParams].length === 0
              ? '-'
              : commonParams[params as keyof CommonParams]
          }
        }

        fieldValue = JSON.stringify(paramsWithReplacedValues, null, 2)
      } else {
        fieldValue = JSON.stringify(params, null, 2)
      }

      formik.setFieldValue(`test_templates.${index}.parameter`, fieldValue)
      formik.setFieldValue(`test_templates.${index}.test_template_id`, template.id)
    })
  }, [sortedTemplates, commonParams])

  const onParamsChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textArea: HTMLTextAreaElement = event.currentTarget
    formik.setFieldValue(textArea.name, textArea.value)

    textArea.style.height = 'auto'
    textArea.style.height = `calc(${textArea.scrollHeight}px + 0.7rem)`
  }

  const ErrorMessage = ({ index }: { index: number }) => {
    if (formik.touched.test_templates === undefined || formik.errors.test_templates === undefined) {
      return <></>
    }

    const errors = formik.errors.test_templates as FormikErrors<{
      test_template_id: number
      parameter: string
    }>[]

    const hasError = formik.touched.test_templates[index]?.parameter && errors[index]?.parameter

    if (!hasError) return <></>

    return <div className='text-danger'>{errors[index].parameter?.toString()}</div>
  }

  return (
    <div className='row justify-content-between'>
      {sortedTemplates.map((template: TestTemplate, index: number) => (
        <div key={template.id} className='col-12 col-md-6 mb-8'>
          <div>
            <Modal.Title>{template.name}</Modal.Title>
            <p>Route: {template.route}</p>
          </div>
          <div>
            <textarea
              className='form-control form-control-solid request-params-input'
              placeholder='Enter parameters'
              rows={10}
              {...formik.getFieldProps(`test_templates.${index}.parameter`)}
              onChange={onParamsChangeHandler}
            />
            <ErrorMessage index={index} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default TestTemplateForm
