import clsx from "clsx";
import { KTSVG } from "../../../../_metronic/helpers";
import { Icon, TableFilterButtonProps } from "../table-filter-types";

const TableFilterButton = (props: TableFilterButtonProps) => {
  const {
    label,
    icon,
    color,
  } = props

  const Icon = ({ sourceType, source }: Icon): JSX.Element => {
    switch (sourceType) {
      case "path":
        return <KTSVG path={source} className='svg-icon-2' />
      case "class":
        return <i className={`${source} me-2 fs-5`}></i>
    }
  }

  return (
    <button
      {...props}
      type={props.type ?? 'button'}
      className={clsx(
        `btn btn-sm btn-${color ?? 'light-primary'} d-flex align-items-center`,
        { 'ps-4': icon !== undefined },
      )}
    >
      {icon && <Icon sourceType={icon.sourceType} source={icon.source} />}
      {label}
    </button>
  )
}

export default TableFilterButton
