import moment from "moment"
import { getSelectOptions } from "../../../../components/Helpers"
import TableFilter from "../../../../components/table-filter/components/TableFilter";
import * as Yup from "yup";
import { DATE_FORMAT } from "../../../../helpers/constants";
import { useState } from "react";
import { reportStatusUrl } from "../Requests";
import { ApiResponse, FinalRequest } from "../../../../helpers/types/api-types";
import { IndexProps, PDPDColumns, PDPDIndexItem, PDPDIndexRow, PDPDReportFilter } from "../Models";
import PDPDIndexTable from "./PDPDIndexTable";
import PDPDTableRow from "./PDPDTableRow";
import ReportStatusHistoryModal from "./modals/ReportStatusHistoryModal";

const columns: PDPDColumns = {
    col_header: [
        {
            name: 'date',
            background: ''
        },
        {
            name: 'game_provider',
            background: ''
        },
        {
            name: 'currency',
            background: ''
        }
    ], 
    col_body: [
        {
            name: 'PDPD',
            sub_cols: [
                'Count',
                'Turnover',
                'Winlose'
            ],
            optional_cols: [
                'JP Cont',
                'JP Win'
            ],
            background: 'bg-light-primary'
          }
    ]
}

const initialParams = {
    start_date: moment().subtract(1,'d').format(DATE_FORMAT),
    end_date: moment().subtract(1,'d').format(DATE_FORMAT),
    hourly: false,
    game_provider_code: '',
    include_jp: false,
    resolved: false,
}

const gameProviderOptions = getSelectOptions('game_provider_options')

const PDPDIndex = (props: IndexProps) => {
    const { dashboard = false } = props
    const [data, setData] = useState<PDPDIndexRow[]>([])
    const [showJPCol, setShowJPCol] = useState<boolean>(false)
    const [reportActionHistoryShowing, setReportActionHistoryShowing] = useState<boolean>(false)
    const [params, setParams] = useState<PDPDReportFilter>(initialParams)
    const [selectedGameProviderCode, setSelectedGameProviderCode] = useState<string>('')
    const [selectedStartDate, setSelectedStartDate] = useState<string>('')
    const [selectedEndDate, setSelectedEndDate] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmitHandler = () => {
        setShowJPCol(false)
        setIsLoading(true)
    }

    const showReportActionHistoryModal = (gp_code: string, date: string) => {
        let end_date_time = params.hourly == true
            ? moment(date).add(1, 'h').subtract(1, 's').format('YYYY-MM-DD HH:mm:ss') 
            : moment(date).add(1, 'd').subtract(1, 's').format('YYYY-MM-DD HH:mm:ss')

        setSelectedStartDate(date)
        setSelectedEndDate(end_date_time)

        setSelectedGameProviderCode(gp_code)
        setReportActionHistoryShowing(true)
    }

    const closeReportStatusHistoryModal = () => {
        setSelectedGameProviderCode('')
        setReportActionHistoryShowing(false)
    }

    const onResponseHandler = (response: ApiResponse<PDPDIndexItem[]>, params: FinalRequest<PDPDReportFilter>) => {
        setParams(params)

        if (params.include_jp) {
            setShowJPCol(params.include_jp)
        }
        
        let items: PDPDIndexItem[] 
        if ('paginations' in response.data) {
            items = response.data.rows ?? []
        } else {
            items = response.data
        }

        const rows: PDPDIndexRow[] = items.map((item: PDPDIndexItem): PDPDIndexRow => {
            return {
                date: item.date,
                game_provider_code: item.game_provider_code,
                currency: item.currency ?? 'All',
                initial_count_diff: item.initial_count_diff,
                initial_turnover_diff: item.initial_turnover_diff,
                initial_win_lose_diff: item.initial_win_lose_diff,
                initial_jackpot_win_diff: item.initial_jackpot_win_diff,
                initial_jackpot_contribution_diff: item.initial_jackpot_contribution_diff,
                current_count_diff: item.current_count_diff,
                current_turnover_diff: item.current_turnover_diff,
                current_win_lose_diff: item.current_win_lose_diff,
                current_jackpot_win_diff: item.current_jackpot_win_diff,
                current_jackpot_contribution_diff: item.current_jackpot_contribution_diff,
            }
        })
        setData(rows)
        setIsLoading(false)
    }
    
    return (
        <>
            <TableFilter
                fields={[
                    {
                        label: 'Game Provider Code',
                        name: 'game_provider_code',
                        type: 'select',
                        options: gameProviderOptions,
                        placeholder: 'Select game provider',
                        hint: 'Required for hourly'
                    },
                    {
                        label: 'Start Date',
                        name: 'start_date',
                        type: 'date',
                        defaultValue: moment().subtract(1,'d').format(DATE_FORMAT),
                    },
                    {
                        label: 'End Date',
                        name: 'end_date',
                        type: 'date',
                        defaultValue: moment().subtract(1,'d').format(DATE_FORMAT),
                    },
                    {
                        label: 'Status',
                        name: 'resolved',
                        type: 'select',
                        options: [
                            { value: '', label: 'All' },
                            { value: '1', label: 'Resolved' },
                            { value: '0', label: 'Unresolved' },
                          ],
                        placeholder: 'Select status',
                    },
                    {
                        label: 'Filter',
                        type: 'checkbox-group',
                        checkboxes: [
                          {
                            label: 'hourly (Select One Game Provider)',
                            name: 'hourly',
                            checkedValue: true,
                            uncheckedValue: false,
                          },
                          {
                            label: 'Include JP',
                            name: 'include_jp',
                            checkedValue: true,
                            uncheckedValue: false,
                          },
                        ],
                        newRow: true,
                        colSizeLg: 6
                      },
                ]}
                validationSchema={Yup.object().shape({
                    game_provider_code: Yup.string()
                        .when("hourly", {
                            is: true,
                            then: Yup.string().required("Please Select Game Provider")
                        }),
                    start_date: Yup.string(),
                    end_date: Yup.string()
                })}
                apiUrl={reportStatusUrl}
                onSubmit={onSubmitHandler}
                onResponse={onResponseHandler}
                submitOnInit={true}
                pagination={false}
                hidden={dashboard}
            />

            <PDPDIndexTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                highlight_col={true}
                show_jp_col={showJPCol}
                tableRows={!isLoading && data.map((row: PDPDIndexRow, index: number) =>
                    <PDPDTableRow
                      key={index}
                      index={index}
                      row={row}
                      show_jp_col={showJPCol}
                      reportActionLog={() => showReportActionHistoryModal(row.game_provider_code, row.date)}
                    />
                  )}
            />

            <ReportStatusHistoryModal
                show={reportActionHistoryShowing}
                game_provider_code={selectedGameProviderCode}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                params={params}
                onClose={closeReportStatusHistoryModal}
            />
        </>
    )

}

export default PDPDIndex