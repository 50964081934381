import React from "react"
import { PDPDColBody, PDPDColHeader, PDPDColumns, PDPDIndexRow } from "../Models"

interface Props {
    columns: PDPDColumns,
    data: PDPDIndexRow[],
    isLoading: boolean,
    highlight_col: boolean,
    show_jp_col: boolean,
    tableRows: any,
}

const PDPDIndexTable = (props: Props) => {
    let no_data_colspan: number = props.show_jp_col ? 9 : 7

    const colspan = (column: PDPDColBody) => {
        return column.sub_cols.length + (props.show_jp_col ? column.optional_cols.length : 0)
    }

    return (
        <>
            <div className={'card pt-5 table-responsive'}>
                <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
                <table className="card-rounded table table-hover table-row-bordered table-row-gray-300 gs-7">
                    <colgroup>
                        {props.highlight_col && props.columns.col_header.length > 0 && props.columns.col_header.map((column: PDPDColHeader) => {
                            return <col key={column.name + "_col"}/>
                        })}
                        {props.highlight_col && props.columns.col_body.length > 0 && props.columns.col_body.map((column: PDPDColBody) => {
                            return <col key={column.name + "_col"} span={colspan(column)} className={column.background} />
                        })}
                    </colgroup>
                    <thead>
                    {props.columns.col_header.length > 0 &&
                        <tr className="fw-bolder fs-6 text-gray-800">
                            {props.columns.col_header.map((column: PDPDColHeader) => {
                                return <th 
                                    key={column.name} 
                                    rowSpan={2}
                                    className="align-bottom text-end text-capitalize text-center">{column.name.split('_').join(' ')}
                                </th>
                            })}
                            {props.columns.col_body.map((column: PDPDColBody) => {
                                return <th 
                                    key={column.name} 
                                    colSpan={colspan(column)}
                                    className="align-top text-end text-capitalize text-center">{column.name.split('_').join(' ')}
                                </th>
                            })}
                            <th 
                                key='action_col_header'
                                rowSpan={2}
                                className="align-bottom text-end text-capitalize text-center">Action
                            </th>
                        </tr>
                    }
                    {props.columns.col_body.length > 0 &&
                        <tr className="fw-bolder fs-6 text-gray-800">
                            {props.columns.col_body.map((column: PDPDColBody) => {
                                return <React.Fragment key={column.name + '_sub_row'}>
                                    {column.sub_cols.map((sub_col: string) => {
                                        return <th key={column.name + '_' + sub_col} className="text-center">{sub_col}</th>
                                    })}
                                    {props.show_jp_col && column.optional_cols.length > 0 && column.optional_cols.map((optional_col: string) => {
                                        return <th key={column.name + '_' + optional_col} className="text-center">{optional_col}</th>
                                    })}
                                </React.Fragment>
                            })}
                        </tr>
                    }
                    </thead>
                    <tbody style={{ fontFamily: 'Roboto' }}>
                    {
                        props.isLoading &&
                        <tr>
                            <td className={'text-center text-muted'} colSpan={no_data_colspan}>Loading...</td>
                        </tr>
                    }
                    {
                        !props.isLoading && props.data.length < 1 &&
                        <tr>
                            <td className={'text-center text-muted'} colSpan={no_data_colspan}>No records found</td>
                        </tr>
                    }
                    {props.tableRows}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default PDPDIndexTable