import clsx from "clsx";
import { KeyboardEvent, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
import { KTSVG } from "../../../../../_metronic/helpers";
import { checkRequestResponse } from "../../../../components/Helpers";
import MessageModal from "../../../../components/MessageModal";
import { invoiceDelete } from "../../Requests";

interface Props {
  show: boolean
  onClose: (success: boolean) => void
  invoiceCode: string
  externalCode?: string
}

const InvoiceDeleteModal = (props: Props) => {
  const [deletable, setDeletable] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleDelete = async () => {
    setIsSubmitting(true)

    try {
      const response = await invoiceDelete({ invoice_code: props.invoiceCode })

      setIsSubmitting(false)

      if (!checkRequestResponse(response)) {
        props.onClose(false)
        return
      }

      props.onClose(true)
      MessageModal({ type: 'success', messages: response.data.data })
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed' })
    }
  }

  const validateKeyword = (e: KeyboardEvent) => {
    const value: string = (e.currentTarget as HTMLInputElement).value

    if (value === 'Confirm Delete') {
      setDeletable(true)
    } else {
      setDeletable(false)
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_invoice_edit'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={props.show}
      scrollable
      onHide={() => props.onClose(false)}
    >
      <div className="modal-header px-10 row">
        <div className="col">
          <h2>Delete Invoice ({props.externalCode ?? props.invoiceCode})?</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => props.onClose(false)}
          disabled={isSubmitting}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <div className={clsx('modal-body px-lg-10', { 'overlay overlay-block': props.invoiceCode == '' })}>
        <div className='row mb-6'>
          <div className="col">
            Are you sure you want to delete this invoice? <b>Note:</b> This action cannot be undone.
          </div>
        </div>
        <div className='row mb-6'>
          <div className="col-md-6">
            <label className='form-label'>
              Type <b>Confirm Delete</b> below to confirm.
            </label>
            <input
              type='text'
              className='form-control form-control-solid'
              onKeyUp={validateKeyword}
            />
          </div>
        </div>
      </div>

      <div className='modal-footer'>
        <button
          type='button'
          disabled={!deletable || isSubmitting}
          className='btn btn-sm btn-danger me-3'
          onClick={handleDelete}
        >
          Delete
        </button>
        <button
          type='button'
          className='btn btn-sm btn-light-primary'
          onClick={() => props.onClose(false)}
          disabled={isSubmitting}
        >
          Cancel
        </button>
      </div>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default InvoiceDeleteModal
