export interface TestSessionIndexParams {
  start_date_time: string
  end_date_time: string
  status?: TestSessionStatus
  user_created_only: boolean
  remark?: string
  created_by?: string
  sort?: 'start_date_time' | 'created_by' | 'status'
  order?: 'asc' | 'desc' | ''
  page?: number
  per_page?: number
  paginate?: number
}

export interface TestSession {
  id: number
  name:string
  remark: string
  start_at: string
  completed_at?: string
  status?: TestSessionStatus.Scheduled | TestSessionStatus.Running | TestSessionStatus.Completed
  created_by: {
    id: number
    name: string
  }
  created_at: string
  failed_count: number
}

export enum TestSessionStatus {
  Scheduled,
  Running,
  Completed,
  Passed,
  Failed,
}

export namespace TestSessionStatus {
  export function values(): number[] {
    return Object.values(TestSessionStatus).filter((value): value is number => typeof value === 'number')
  }
}

export interface CommonParams {
  url: string
  forward_url: string
  username: string
  signature_key: string
  other_key: string
  prefix: string
  ga_username: string
  ga_password: string
  currency: string
}
