import axios from "axios";
import {AgentIndexParams} from "./Models";

const API_URL = process.env.REACT_APP_API_URL

export const INDEX_AGENT_URL = `${API_URL}/user/agent/index`
export const CREATE_AGENT_URL = `${API_URL}/user/agent/create`
export const STORE_AGENT_URL = `${API_URL}/user/agent/store`
export const EDIT_AGENT_URL = `${API_URL}/user/agent/edit`
export const UPDATE_AGENT_URL = `${API_URL}/user/agent/update`
export const SHOW_AGENT_URL = `${API_URL}/user/agent/show`
export const UPDATE_CREDIT_LIMIT_URL = `${API_URL}/user/agent/update-credit-limit`
export const TRANSFER_CASH_BALANCE_URL = `${API_URL}/user/agent/transfer-cash-balance`
export const UPDATE_SITES_URL = `${API_URL}/user/agent/update-site`
export const INDEX_AGENT_PT_URL = `${API_URL}/position-taking/agent/index`
export const UPDATE_AGENT_PT_URL = `${API_URL}/position-taking/agent/set`
export const UPDATE_CURRENCIES_URL = `${API_URL}/user/agent/update-currency`


export const agentIndex = (filters: AgentIndexParams) => {
    return axios.post(INDEX_AGENT_URL, {...filters})
}

export const agentShow = (agentId: number) => {
    return axios.post(SHOW_AGENT_URL, {agent_id: agentId})
}

export const agentCreate = () => {
    return axios.post(CREATE_AGENT_URL)
}

export const agentStore = (storeAgentParams: {}) => {
    return axios.post(STORE_AGENT_URL, storeAgentParams)
}

export const agentEdit = (editAgentParams: {}) => {
    return axios.post(EDIT_AGENT_URL, editAgentParams)
}

export const agentUpdate = (updateAgentParams: {}) => {
    // console.log(updateAgentParams)

    return axios.post(UPDATE_AGENT_URL, updateAgentParams)
}

export const updateCreditLimit = (updateCreditLimitParams: {}) => {
    return axios.post(UPDATE_CREDIT_LIMIT_URL, updateCreditLimitParams)
}

export const transferCashBalance = (transferCashBalanceParams: {}) => {
    return axios.post(TRANSFER_CASH_BALANCE_URL, transferCashBalanceParams)
}

export const agentUpdateSites = (updateSitesParams: {}) => {
    return axios.post(UPDATE_SITES_URL, updateSitesParams)
}

export const agentPtIndex = (params: {}) => {
    return axios.post(INDEX_AGENT_PT_URL, params)
}

export const agentPtUpdate = (params: {}) => {
    return axios.post(UPDATE_AGENT_PT_URL, params)
}

export const agentUpdateCurrencies = (updateCurrenciesParams: {}) => {
    return axios.post(UPDATE_CURRENCIES_URL, updateCurrenciesParams)
}