import { SelectOptionModel } from "../Helpers"
import { PaginationPerPageProps } from "./Models"

export const perPageOptionsArray: SelectOptionModel[] = [
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "200", value: "200" },
  ]

const PaginationPerPage = ({ pagination, onSetPerPage }: PaginationPerPageProps) => {
    const perPageSelectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
      onSetPerPage(event.currentTarget.options[event.currentTarget.selectedIndex].value)
    }

    return (
      <div className="d-flex">
        <select
          className="form-select form-select-sm my-1 me-3"
          style={{width: '75px'}}
          value={pagination.per_page}
          onChange={perPageSelectHandler}
        >
          {
            perPageOptionsArray.map((perPageOption: SelectOptionModel) => {
              return (
                <option 
                  value={perPageOption.value} 
                  key={perPageOption.value}
                >
                  {perPageOption.label}
                </option>
              )
            })
          }
        </select>
        <span className="py-3">Showing {Math.max(pagination.from, 0)} to {Math.max(pagination.to,0)} of {Math.max(pagination.total,0)} records</span>
      </div>
    )
}

export default PaginationPerPage