import axios from "axios";
import {SettlementReportFilter} from "./Models";

const API_URL = process.env.REACT_APP_API_URL

export const settlementReportURL = `${API_URL}/report/settlement/`
export const codeListURL = `${API_URL}/gf/code-list`
export const gpListURL = `${API_URL}/game-provider/timezones`


export const settlementReport = (filters: SettlementReportFilter) => {
    return axios.post(settlementReportURL, {...filters})
}

export const codeList = ($type: any = {}) => {
    return axios.post(codeListURL, $type)
}

export const gpList = ($type: any = {}) => {
    return axios.post(gpListURL, $type)
}