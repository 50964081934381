import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import moment from "moment";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormControl, FormLabel, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { createPortal } from "react-dom";
import * as Yup from "yup";
import { AnySchema, ArraySchema, ObjectSchema, StringSchema } from "yup";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { checkRequestResponse } from "../../../../../components/Helpers";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import MessageModal from "../../../../../components/MessageModal";
import { DATE_TIME_FORMAT } from "../../../../../helpers/constants";
import { removeEmptyAttributes } from "../../../../../helpers/functions";
import { ApiResponse } from "../../../../../helpers/types/api-types";
import { TestSessionDetails, TestSessionParams, TestTemplate } from "../../../template/TestTemplateModels";
import { CommonParams } from "../../TestSessionModels";
import { testSessionCreate, testSessionEdit, testSessionUpdate } from "../../TestSessionRequests";
import TestTemplateForm from "./TestTemplateForm";
import TestTemplateList from "./TestTemplateList";

interface PropParams {
  show: boolean
  onClose: () => void
  onSuccess: () => void
  selectedTestSessionId: number
  isUpdate: boolean
}

const initialValues: TestSessionParams = {
  start_date_time: moment().endOf('day').format(DATE_TIME_FORMAT),
  name: '',
  domain: '',
  remark: '',
  test_templates: []
}

const defaultCommonParams: CommonParams = {
  url: '',
  forward_url: '',
  username: '',
  signature_key: '',
  other_key: '',
  prefix: '',
  ga_username: '',
  ga_password: '',
  currency: '',
}

const MIN_STEP = 1
const MAX_STEP = 3

type SettingsValidationShape = {
  [field in keyof Omit<TestSessionParams, 'test_session_id' | 'test_templates'>]: StringSchema
}

type ParamsValidationShape = {
  [field in keyof Pick<TestSessionParams, 'test_templates'>]: ArraySchema<AnySchema>
}

const settingsValidation: ObjectSchema<SettingsValidationShape> = Yup.object().shape({
  name: Yup.string().required('Test session name is required'),
  start_date_time: Yup
    .string()
    .required()
    .test('isFiveMinsLater', 'Schedule run date time must be after 5 minutes from now.', value => {
      if (value === undefined) return false

      const selectedTime: moment.Moment = moment(value)
      const fiveMinsLater = moment().add(5, 'minutes')

      return !selectedTime.isBefore(fiveMinsLater);
    }),
  domain: Yup.string()
    .required('Domain is required')
    .matches(/^((http|https):\/\/)?[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+){1,2}(\/[a-zA-Z0-9_-]+)*\/?$/g, 'Domain must be a valid URL'),
  remark: Yup.string().optional(),
})

const parametersValidation: ObjectSchema<ParamsValidationShape> = Yup.object().shape({
  test_templates: Yup.array().of(
    Yup.object().shape({
      test_template_id: Yup.number().integer().required(),
      parameter: Yup
        .string()
        .required('Parameters are required')
        .test('json', 'Parameters must be in valid JSON format', value => {
          try {
            JSON.parse(value!)
            return true
          } catch (e) {
            return false
          }
        })
    })
  ).required()
})

type ValidationSchema = ObjectSchema<SettingsValidationShape> | ObjectSchema<ParamsValidationShape>

const TestSessionModal = (props: PropParams) => {
  const {
    show,
    onClose,
    onSuccess,
    selectedTestSessionId,
    isUpdate,
  } = props

  const formSessionDetails = useRef<HTMLDivElement>(null)
  const formTemplateSelection = useRef<HTMLDivElement>(null)
  const formRequestParams = useRef<HTMLDivElement>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedTemplates, setSelectedTemplates] = useState<TestTemplate[]>([])
  const [step, setStep] = useState<number>(0)
  const [formValues, setFormValues] = useState<TestSessionParams>(initialValues)
  const [validationSchema, setValidationSchema] = useState<ValidationSchema>(settingsValidation)
  const [commonParams, setCommonParams] = useState<CommonParams>(defaultCommonParams)

  useEffect(() => {
    if (!show) return

    setStep(MIN_STEP)
    if (isUpdate) void getTestSession()
  }, [show])

  useEffect(() => {
    if (formSessionDetails.current === null) return
    if (step < MIN_STEP || step > MAX_STEP) return

    switch (step) {
      case 1:
        setValidationSchema(settingsValidation)
        formSessionDetails.current.classList.remove('d-none')
        formTemplateSelection.current!.classList.add('d-none')
        formRequestParams.current!.classList.add('d-none')
        break
      case 2:
        formSessionDetails.current.classList.add('d-none')
        formTemplateSelection.current!.classList.remove('d-none')
        formRequestParams.current!.classList.add('d-none')
        break
      case 3:
        setValidationSchema(parametersValidation)
        formSessionDetails.current.classList.add('d-none')
        formTemplateSelection.current!.classList.add('d-none')
        formRequestParams.current!.classList.remove('d-none')
        break
    }
  }, [step])

  const getTestSession = async () => {
    setIsLoading(true)

    try {
      const response: AxiosResponse<ApiResponse<TestSessionDetails>> = await testSessionEdit({ test_session_id: selectedTestSessionId })

      if (!checkRequestResponse(response)) {
        MessageModal({ type: 'failed', messages: "Error loading selected test session details." })
        return
      }

      const data: TestSessionDetails = response.data.data as TestSessionDetails
      const formValues: TestSessionParams = {
        ...data,
        start_date_time: moment(data.start_date_time).add(8, "hours").format(DATE_TIME_FORMAT),
        remark: data.remark ?? '',
        test_templates: data.test_templates.map(template => {
          return {
            test_template_id: template.id,
            parameter: JSON.stringify(template.required_parameters, null, 2),
          }
        })
      }

      setSelectedTemplates(data.test_templates)
      setFormValues(formValues)
    } catch (error) {
      console.error(error)
      MessageModal({ type: 'failed' })
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    onClose()
    setSelectedTemplates([])
    setStep(MIN_STEP)
    setCommonParams(defaultCommonParams)
    formik.resetForm()
    setTimeout(formik.validateForm, 0)
  }

  const handleNext = () => {
    if (step === MAX_STEP) return
    setStep((prev: number) => prev + 1)
  }

  const handlePrev = () => {
    if (step === MIN_STEP) return
    setStep((prev: number) => prev - 1)
  }

  const onSelectTemplateHandler = (selectedTestTemplate: TestTemplate) => {
    const wasSelected: boolean = selectedTemplates.find((template: TestTemplate) => template.id == selectedTestTemplate.id) != undefined

    if (wasSelected) {
      setSelectedTemplates((prev: TestTemplate[]): TestTemplate[] => {
        return prev.filter((template: TestTemplate) => template.id !== selectedTestTemplate.id)
      })
    } else {
      setSelectedTemplates((prev: TestTemplate[]): TestTemplate[] => {
        return [...prev, selectedTestTemplate]
      })
    }
  }

  const formik = useFormik({
    initialValues: isUpdate ? formValues : initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values: TestSessionParams) => {
      const utcTime: string = moment(values.start_date_time).subtract(8, 'hours').format(DATE_TIME_FORMAT)

      const params: TestSessionParams = {
        ...values,
        start_date_time: utcTime,
        test_session_id: isUpdate ? selectedTestSessionId : undefined,
      }

      const finalParams = removeEmptyAttributes(params) as TestSessionParams

      try {
        const response = isUpdate
          ? await testSessionUpdate(finalParams)
          : await testSessionCreate(finalParams)

        if (!checkRequestResponse(response)) {
          return
        }

        handleClose()
        onSuccess()
        MessageModal({ type: 'success', messages: `Successfully ${isUpdate ? 'updated' : 'created'} test session.` })
      } catch (e) {
        console.error(e)
        MessageModal({ type: 'failed' })
      }
    }
  })

  const ErrorMessage = ({ field }: { field: keyof TestSessionParams }): JSX.Element => {
    const hasError = formik.touched[field] && formik.errors[field]

    if (!hasError) return <></>

    return <div className='text-danger'>{formik.errors[field]?.toString()}</div>
  }

  const onCommonParamsChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    const paramName = input.name as keyof CommonParams
    const newParams: CommonParams = {
      ...commonParams,
      [paramName]: input.value
    }

    setCommonParams(newParams)
  }

  return createPortal(
    <Modal
      id='kt_modal_test_session'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-100 mx-20'
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header className='px-10'>
        <h2>{isUpdate ? 'Update' : 'Create'} Test Session</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </Modal.Header>
      <Modal.Body>
        {isLoading &&
          <div className='position-absolute w-100 h-100 bg-light bg-opacity-50'>
            <LoadingIndicator />
          </div>
        }
        <form autoComplete='off' id='test_session_form' onSubmit={formik.handleSubmit}>
          {/* Test session details */}
          <div ref={formSessionDetails} className='container-fluid'>
            <div className="row mb-2">
              <Modal.Title>Test Session Settings</Modal.Title>
            </div>
            <div className="row mb-10" style={{ rowGap: '1.2rem' }}>
              <div className="col-12 col-md-3">
                <FormLabel>Test Session Name</FormLabel>
                <FormControl
                  type='text'
                  className='form-control-solid'
                  placeholder='Enter name'
                  {...formik.getFieldProps('name')}
                />
                <ErrorMessage field='name' />
              </div>
              <div className="col-12 col-md-3">
                <FormLabel>Schedule Run Date Time (GMT +08:00)</FormLabel>
                <FormControl
                  type='datetime-local'
                  className='form-control-solid'
                  step={1}
                  {...formik.getFieldProps('start_date_time')}
                />
                <ErrorMessage field='start_date_time' />
              </div>
              <div className="col-12 col-md-3">
                <FormLabel>Domain</FormLabel>
                <FormControl
                  type='text'
                  className='form-control-solid'
                  placeholder='Enter domain'
                  {...formik.getFieldProps('domain')}
                />
                <ErrorMessage field='domain' />
              </div>
              <div className="col-12 col-md-6">
                <FormLabel>Remark (optional)</FormLabel>
                <FormControl
                  type='text'
                  className='form-control-solid'
                  placeholder='Enter remark'
                  {...formik.getFieldProps('remark')}
                />
              </div>
            </div>
            {!isUpdate &&
              <>
                <div className="row mb-2 d-flex">
                  <Modal.Title className='d-flex align-items-center'>
                    Common Parameters
                    <OverlayTrigger
                      overlay={<Tooltip className='tooltip-nowrap'>Empty fields will be filled with "-".</Tooltip>}
                      placement='right'
                    >
                      <i className="las la-info-circle ms-1 fs-3"></i>
                    </OverlayTrigger>
                  </Modal.Title>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>url</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter url'
                      name='url'
                      value={commonParams.url}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>forward_url</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter forward_url'
                      name='forward_url'
                      value={commonParams.forward_url}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>username</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter username'
                      name='username'
                      value={commonParams.username}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>signature_key</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter signature_key'
                      name='signature_key'
                      value={commonParams.signature_key}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>other_key</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter other_key'
                      name='other_key'
                      value={commonParams.other_key}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>prefix</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter prefix'
                      name='prefix'
                      value={commonParams.prefix}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>ga_username</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter ga_username'
                      name='ga_username'
                      value={commonParams.ga_username}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>ga_password</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter ga_password'
                      name='ga_password'
                      value={commonParams.ga_password}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <FormLabel>
                      <pre className='code-snippet px-2 py-1'>currency</pre>
                    </FormLabel>
                    <FormControl
                      type='text'
                      className='form-control-solid'
                      placeholder='Enter currency'
                      name='currency'
                      value={commonParams.currency}
                      onChange={onCommonParamsChangeHandler}
                    />
                  </div>
                </div>
              </>
            }
          </div>
          {/* Template selection */}
          <div ref={formTemplateSelection} className='d-none container-fluid'>
            <TestTemplateList
              show={show}
              selectedTestTemplates={selectedTemplates}
              onSelectTemplate={onSelectTemplateHandler}
            />
          </div>
          {/* Request parameters input */}
          <div ref={formRequestParams} className='d-none container-fluid'>
            <TestTemplateForm
              selectedTemplates={selectedTemplates}
              commonParams={commonParams}
              formik={formik}
              isUpdate={isUpdate}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <div className='d-flex gap-3'>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={handlePrev}
            disabled={step <= MIN_STEP || formik.isSubmitting}
          >
            Back
          </button>
          <button
            type='button'
            className='btn btn-sm btn-light-primary'
            onClick={handleClose}
            disabled={formik.isSubmitting}
          >
            Cancel
          </button>
        </div>
        <div>
          {step !== MAX_STEP
            ? (
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={handleNext}
                disabled={
                  step >= MAX_STEP
                  || (step === 1 && Object.keys(formik.errors).length !== 0)
                  || (step === 2 && selectedTemplates.length === 0)
                }
              >
                Next
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={() => (document.getElementById('test_session_form') as HTMLFormElement).requestSubmit()}
                disabled={Object.keys(formik.errors).length !== 0 || formik.isSubmitting}
                data-kt-indicator="on"
              >
                {!formik.isSubmitting && 'Submit'}
                {formik.isSubmitting &&
                  <span className="indicator-progress">
                    {isUpdate ? 'Updating...' : 'Creating...'}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                }
              </button>
            )
          }
        </div>
      </Modal.Footer>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default TestSessionModal
