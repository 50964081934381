import moment from "moment";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Yup from "yup";
import { KTSVG } from "../../../../_metronic/helpers";
import { checkRequestResponse, removeEmptyFilters, roundOff, SelectOptionModel } from "../../../components/Helpers";
import MessageModal from "../../../components/MessageModal";
import { TableContent } from "../../../components/Table";
import TableFilter from "../../../components/table-filter/components/TableFilter";
import { TableFilterInput } from "../../../components/table-filter/table-filter-types";
import ToolBarButton from "../../../components/ToolBarButton";
import { DATE_TIME_FORMAT } from "../../../helpers/constants";
import { ApiResponse, FinalRequest, RequestPagination, ResponsePagination } from "../../../helpers/types/api-types";
import { agentIndex } from "../../agents/Requests";
import { useAuth } from "../../auth";
import { siteIndex } from "../../sites/Requests";
import {
  DateTimeOptions,
  InvoiceFilesModalProps,
  InvoiceIndexParams,
  InvoiceRow,
  PaymentStatus,
  S3File
} from "../Models";
import { INVOICE_INDEX_URL, invoiceIndex } from "../Requests";
import InvoiceCreateModal from "./modals/InvoiceCreateModal";
import InvoiceDeleteModal from "./modals/InvoiceDeleteModal";
import InvoiceEditModal from "./modals/InvoiceEditModal";
import InvoiceFilesModal from "./modals/InvoiceFilesModal";
import TablePagination, { initialPagination } from "../../../components/TablePagination/TablePagination";

const initialFilters: InvoiceIndexParams = {
  date_time_option: DateTimeOptions.Created,
  start_date_time: moment().subtract(1, 'M').startOf('M').format('YYYY-MM-DD 00:00:00'),
  end_date_time: moment().endOf('M').format('YYYY-MM-DD 23:59:59'),
  paginate: 1,
  page: 1,
  per_page: 15
}

const initialFilesModalProps: InvoiceFilesModalProps = {
  showing: false,
  type: '',
  files: [],
  name: '',
  code: ''
}

interface Filter extends InvoiceIndexParams {
  currencies: SelectOptionModel[] | ''
  site_code: string | ''
  sort?: string
  order?: "" | "asc" | "desc"
}

const notiBadge = {
  position: 'absolute' as 'absolute',
  top: '-10px',
  right: '-10px'
};

const InvoiceTable = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agentOptions, setAgentOptions] = useState<SelectOptionModel[]>([])
  const [siteOptions, setSiteOptions] = useState<SelectOptionModel[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])
  const [invoices, setInvoices] = useState<InvoiceRow[]>([])
  const [dateTimeOption, setDateTimeOption] = useState<DateTimeOptions>(DateTimeOptions.Created)
  const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
  const [filters, setFilters] = useState<InvoiceIndexParams>(initialFilters)
  const auth = useAuth()

  // modals
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceRow | null>(null)
  const [invoiceCreateShowing, setInvoiceCreateShowing] = useState<boolean>(false)
  const [invoiceEditShowing, setInvoiceEditShowing] = useState<boolean>(false)
  const [invoiceDeleteShowing, setInvoiceDeleteShowing] = useState<boolean>(false)
  const [filesModalProps, setFilesModalProps] = useState<InvoiceFilesModalProps>(initialFilesModalProps)

  useEffect(() => {
    void loadAgentOptions()
    void filterSiteOptions()
    void filterCurrencyOptions()
  }, [])

  const onFilterHandler = async (filterParams: Filter | InvoiceIndexParams) => {
    setIsLoading(true)

    try {
      const currency_codes = ('currencies' in filterParams && filterParams.currencies != '')
        ? filterParams.currencies.map((option: SelectOptionModel) => option.value)
        : []

      const site_codes = ('site_code' in filterParams && filterParams.site_code != '')
        ? [filterParams.site_code]
        : []

      filterParams.start_date_time = moment(filterParams.start_date_time).format('YYYY-MM-DD HH:mm:ss')
      filterParams.end_date_time = moment(filterParams.end_date_time).format('YYYY-MM-DD HH:mm:ss')

      const params = removeEmptyFilters({ currency_codes: currency_codes, site_codes: site_codes, ...filterParams })

      const response = await invoiceIndex(params)

      if (!checkRequestResponse(response)) return false

      if (params.paginate) setPagination(response.data.data.paginations)

      setInvoices(
        params.paginate
          ? response.data.data.rows ?? []
          : response.data.data
      )
      setDateTimeOption(+params.date_time_option)
      setIsLoading(false)
      setFilters(params)

      return true
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      MessageModal({ type: 'failed' })

      return false
    }
  }

  const loadAgentOptions = async () => {
    const agentIndexResponse = await agentIndex({})
    const agentOptions: SelectOptionModel[] = agentIndexResponse.data.data.map((agent: any) => {
      return { value: agent.id.toString(), label: agent.username }
    })
      .sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))

    agentOptions.unshift({ value: '', label: 'All' })

    setAgentOptions(agentOptions)
  }

  const filterSiteOptions = async () => { // TODO: filter sites by selected agent & currencies
    const siteIndexResponse = await siteIndex({});
    const siteOptions: SelectOptionModel[] = siteIndexResponse.data.data.map((site: any) => {
      return { value: site.code, label: site.code }
    })
      .sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))

    setSiteOptions(siteOptions)
  }

  const filterCurrencyOptions = async () => { // TODO: filter currencies by selected agent & site
    const localStorageCurrencyOptions = localStorage.getItem('currency_options')
    const tempCurrencies = JSON.parse(localStorageCurrencyOptions ?? '[{}]')
    tempCurrencies.sort((a: SelectOptionModel, b: SelectOptionModel) => a.value.localeCompare(b.value))
    tempCurrencies.length > 0 && setCurrencyOptions(tempCurrencies)
  }

  const showInvoiceFilesModal = (type: 'bill' | 'receipt', files: S3File[], invoice_name: string, invoice_code: string) => {
    setFilesModalProps({ showing: true, type: type, files: files, name: invoice_name, code: invoice_code })
  }

  const showEditModal = (invoice: InvoiceRow) => {
    setSelectedInvoice(invoice)
    setInvoiceEditShowing(true)
  }

  const closeEditModal = () => {
    setSelectedInvoice(null)
    setInvoiceEditShowing(false)
  }

  const showDeleteModal = (invoice: InvoiceRow) => {
    setSelectedInvoice(invoice)
    setInvoiceDeleteShowing(true)
  }

  const closeDeleteModal = (success: boolean) => {
    setInvoiceDeleteShowing(false)
    setSelectedInvoice(null)

    if (success) void onFilterHandler(filters)
  }

  const showHistoryModal = (invoiceCode: string) => {
    // TODO: To be implemented later
  }

  const sortColumnHandler = async (e: any) => {
    let column = e.target.getAttribute("data-sort")
    const order = e.target.getAttribute("data-order")
    let newOrder: 'asc' | 'desc' | '' = "desc"
    let newFilters: any;

    document.querySelectorAll("[data-sort]") // reset all order data attributes
      .forEach(item => {
        item.setAttribute("data-order", "")
      })

    if (order.length === 0) {
      e.target.setAttribute("data-order", "asc")
      newOrder = "asc"
    }

    if (order === "desc") {
      e.target.setAttribute("data-order", "")
      newOrder = ""
      column = ''
    }

    if (order === "asc") {
      e.target.setAttribute("data-order", "desc")
      newOrder = "desc"
    }

    setFilters((prevFilters) => {
      newFilters = { ...prevFilters, sort: column, order: newOrder };
      return newFilters
    })

    await onFilterHandler(newFilters)
  }

  const onResponseHandler = (response: ApiResponse<InvoiceRow[]>, params: FinalRequest<InvoiceIndexParams>) => {
    let invoices: InvoiceRow[]
    let pagination: ResponsePagination

    if ('paginations' in response.data) {
      invoices = response.data.rows ?? []
      pagination = response.data.paginations
    } else {
      invoices = response.data
      pagination = initialPagination
    }

    setInvoices(invoices)
    setPagination(pagination)
    setDateTimeOption(params.date_time_option!)
    setFilters(params as InvoiceIndexParams)

    setIsLoading(false)
  }

  const tableFilterFields: TableFilterInput[] = [
    {
      label: 'Agent',
      name: 'agent_id',
      type: 'select',
      options: agentOptions,
      defaultValue: '',
    },
    {
      label: 'Sites',
      name: 'site_codes',
      type: 'multi-select',
      options: siteOptions,
      placeholder: 'Select sites'
    },
    {
      label: 'Payment Status',
      name: 'payment_status',
      type: 'select',
      options: [
        { value: '', label: 'All' },
        { value: PaymentStatus.Unpaid.toString(), label: PaymentStatus[PaymentStatus.Unpaid] },
        { value: PaymentStatus.Paid.toString(), label: PaymentStatus[PaymentStatus.Paid] }
      ],
      defaultValue: '',
    },
    {
      label: 'Currencies',
      name: 'currencies',
      type: 'multi-select',
      options: currencyOptions,
      placeholder: 'Select currencies',
    },
    {
      label: 'Invoice No.',
      name: 'invoice_code',
      type: 'text',
      placeholder: 'Search invoice no.',
    },
    {
      label: 'Invoice Name',
      name: 'invoice_name',
      type: 'text',
      placeholder: 'Search invoice name',
    },
    {
      label: 'Created By',
      name: 'created_by',
      type: 'text',
      placeholder: 'Search username',
    },
    {
      label: 'Date Time Option',
      name: 'date_time_option',
      type: 'select',
      options: [
        { value: DateTimeOptions.Created.toString(), label: DateTimeOptions.getLabel(DateTimeOptions.Created) },
        { value: DateTimeOptions.Released.toString(), label: DateTimeOptions.getLabel(DateTimeOptions.Released) },
        { value: DateTimeOptions.Updated.toString(), label: DateTimeOptions.getLabel(DateTimeOptions.Updated) }
      ],
      defaultValue: DateTimeOptions.Created.toString(),
      newRow: true,
    },
    {
      label: 'Start Date Time',
      name: 'start_date_time',
      type: 'date-time',
      defaultValue: moment().subtract(1, 'M').startOf('M').format(DATE_TIME_FORMAT),
      shortcutTypes: ['month', 'year'],
    },
    {
      label: 'End Date Time',
      name: 'end_date_time',
      type: 'date-time',
      defaultValue: moment().endOf('M').format(DATE_TIME_FORMAT),
    },
  ]

  return (
    <>
      <TableFilter<InvoiceIndexParams, InvoiceRow[]>
        fields={tableFilterFields}
        validationSchema={tableFilterValidationSchema}
        apiUrl={INVOICE_INDEX_URL}
        onSubmit={() => setIsLoading(true)}
        onResponse={onResponseHandler}
        submitOnInit={true}
        pagination={{
          paginate: 1,
          per_page: pagination.per_page,
          page: pagination.current_page
        }}
      />
      {auth.hasPermission('invoice-create') &&
        <ToolBarButton>
          <button
            className='btn btn-primary btn-sm'
            onClick={() => setInvoiceCreateShowing(true)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Invoice
          </button>
        </ToolBarButton>
      }

      <TableContent
        name='invoices'
        columns={[
          { title: 'Release Date Time', name: 'release_date_time', sort: true },
          { title: 'Invoice No', name: 'invoice_code', sort: true },
          { title: 'Invoice Name', name: 'invoice_name', sort: true },
          { title: 'Site', name: 'site_code', sort: true },
          { title: 'Amount', name: 'amount', sort: false },
          { title: 'Bills', name: 'bills', sort: false },
          { title: 'Receipts', name: 'receipts', sort: false },
          { title: 'Payment Status', name: 'payment_status', sort: false },
          {
            title: {
              [DateTimeOptions.Released]: 'Created At / By',
              [DateTimeOptions.Created]: 'Created At / By',
              [DateTimeOptions.Updated]: 'Updated At / By'
            }[dateTimeOption],
            name: {
              [DateTimeOptions.Released]: 'created_at',
              [DateTimeOptions.Created]: 'created_at',
              [DateTimeOptions.Updated]: 'updated_at'
            }[dateTimeOption],
            sort: true
          },
          { title: 'Actions', name: 'action', sort: false },
        ]}
        contents={invoices}
        isLoading={isLoading}
        filters={filters}
        sortHandler={sortColumnHandler}
        tableRows={
          !isLoading &&
          invoices.map(invoice =>
            <tr key={invoice.invoice_code}>
              <td className='align-middle fw-bold py-4 ps-4'>{invoice.release_date_time}</td>
              <td className='align-middle fw-bold py-4'>{invoice.external_code ?? invoice.invoice_code}</td>
              <td className='align-middle py-4'>{invoice.invoice_name}</td>
              <td className='align-middle py-4'>
                {invoice.site_code === '' ? 'All Sites' : invoice.site_code}
              </td>
              <td className='text-end py-4'>{roundOff(invoice.amount)}</td>
              <td className='align-middle py-4'>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip>View Bill(s)</Tooltip>}
                >
                  <button
                    className='btn btn-icon btn-bg-light btn-sm btn-active-color-primary me-1 mb-1'
                    onClick={() => showInvoiceFilesModal('bill', invoice.bills, invoice.invoice_name, invoice.external_code ?? invoice.invoice_code)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/files/fil003.svg'
                      className='svg-icon-3'
                    />
                  </button>
                </OverlayTrigger>
              </td>
              <td className='align-middle py-4'>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip>View Receipt(s)</Tooltip>}
                >
                  <button
                    className='btn btn-icon btn-bg-light btn-sm btn-active-color-primary me-1 mb-1'
                    onClick={() => showInvoiceFilesModal('receipt', invoice.receipts, invoice.invoice_name, invoice.external_code ?? invoice.invoice_code)}
                    style={{ position: 'relative' }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/files/fil003.svg'
                      className='svg-icon-3'
                    />
                    {invoice.receipts.length > 0 && (
                      <span className='badge badge-danger' style={notiBadge}>
                        {invoice.receipts.length}
                      </span>
                    )}
                  </button>
                </OverlayTrigger>
              </td>
              <td className='align-middle py-4'>
                {
                  {
                    [PaymentStatus.Paid]: <span className='badge badge-light-primary fs-7 fw-semibold'>Paid</span>,
                    [PaymentStatus.Unpaid]: <span className='badge badge-light-danger fs-7 fw-semibold'>Unpaid</span>
                  }[invoice.payment_status]
                }
              </td>
              <td className='align-middle py-4'>
                {
                  {
                    [DateTimeOptions.Released]: invoice.created_at,
                    [DateTimeOptions.Created]: invoice.created_at,
                    [DateTimeOptions.Updated]: invoice.updated_at
                  }[dateTimeOption]
                }
                <br />
                <span className='fw-semibold'>
                  {
                    {
                      [DateTimeOptions.Released]: invoice.created_by,
                      [DateTimeOptions.Created]: invoice.created_by,
                      [DateTimeOptions.Updated]: invoice.updated_by
                    }[dateTimeOption]
                  }
                </span>
              </td>
              <td className='py-4'>
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>{auth.hasPermission('invoice-update') ? 'Edit Invoice' : 'Invoice Details'}</Tooltip>}
                >
                  <button
                    className='btn btn-icon btn-bg-light btn-sm btn-active-color-primary me-1 mb-1'
                    onClick={() => showEditModal(invoice)}
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </OverlayTrigger>
                {
                  auth.hasPermission('invoice-delete') && invoice.payment_status == PaymentStatus.Unpaid &&
                  <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Delete Invoice</Tooltip>}
                  >
                    <button
                      className='btn btn-icon btn-icon-danger btn-bg-light btn-sm btn-active-danger me-1 mb-1'
                      onClick={() => showDeleteModal(invoice)}
                    >
                      <KTSVG path='media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                    </button>
                  </OverlayTrigger>
                }
                {/*<OverlayTrigger // TODO*/}
                {/*  placement='top'*/}
                {/*  overlay={<Tooltip>View History</Tooltip>}*/}
                {/*>*/}
                {/*  <button*/}
                {/*    className='btn btn-icon btn-bg-light btn-sm btn-active-color-primary me-1 mb-1'*/}
                {/*    onClick={() => showHistoryModal(invoice.invoice_code)}*/}
                {/*  >*/}
                {/*    <KTSVG path='/media/icons/duotune/general/gen013.svg' className='svg-icon-3' />*/}
                {/*  </button>*/}
                {/*</OverlayTrigger>*/}
              </td>
            </tr>
          )
        }
        filterTable={onFilterHandler}
        setFilters={setFilters}
        freezeHeader={true}
      />

      {
        pagination &&
          <TablePagination
            pagination={pagination}
            onPaginate={(paginationData: RequestPagination) => {
              onFilterHandler({
                ...filters,
                ...paginationData
              })
              setFilters(prevFilters => ({
                ...prevFilters,
                ...paginationData
              }))
          }}
        />
      }

      <InvoiceCreateModal
        show={invoiceCreateShowing}
        onClose={() => setInvoiceCreateShowing(false)}
        onCreate={() => onFilterHandler(filters)}
      />

      <InvoiceEditModal
        show={invoiceEditShowing}
        onClose={closeEditModal}
        onUpdate={() => onFilterHandler(filters)}
        invoiceCode={selectedInvoice?.invoice_code ?? ''}
      />

      <InvoiceDeleteModal
        show={invoiceDeleteShowing}
        onClose={(success: boolean) => closeDeleteModal(success)}
        invoiceCode={selectedInvoice?.invoice_code ?? ''}
        externalCode={selectedInvoice?.external_code}
      />

      <InvoiceFilesModal
        show={filesModalProps.showing}
        type={filesModalProps.type}
        files={filesModalProps.files}
        onClose={() => setFilesModalProps({ showing: false, type: '', files: [], name: '', code: '' })}
        name={filesModalProps.name}
        code={filesModalProps.code}
      />
    </>
  )
}

export default InvoiceTable

const tableFilterValidationSchema = Yup.object().shape({
  date_time_option: Yup.number().integer().oneOf(DateTimeOptions.values()).required(),
  start_date_time: Yup.date().required(),
  end_date_time: Yup.date().required(),
  agent_id: Yup.number().integer().min(1).optional(),
  site_codes: Yup.array().of(Yup.string()),
  invoice_code: Yup.string().optional(),
  invoice_name: Yup.string().optional(),
  currency_codes: Yup.array().of(Yup.string()),
  payment_status: Yup.number().integer().oneOf(PaymentStatus.values()).optional(),
  created_by: Yup.string().optional(),
})
