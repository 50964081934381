import { useEffect, useState } from "react";
import { Nav, OverlayTrigger, Tab, TabContainer, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import NotificationItem from "./NotificationItem";
import { Notification } from "./NotificationModels";

type NotificationTab = 'read_notifications' | 'unread_notifications'

const tabRead: NotificationTab = 'read_notifications'
const tabUnread: NotificationTab = 'unread_notifications'


const NotificationMenu = ({ unreadNotifications, readNotifications, markAllAsRead, markAsRead, refreshNotifications }: {
  unreadNotifications: Notification[]
  readNotifications: Notification[]
  markAllAsRead: () => void
  markAsRead: (notification: Notification) => void
  refreshNotifications: () => void
}) => {
  const [activeTab, setActiveTab] = useState<NotificationTab>(tabUnread)

  useEffect(() => {
    const menu = document.getElementById('notification-menu')!

    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
    };
    const callback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (!entry.isIntersecting) { // notification menu hidden
          onClose()
        }
      })
    }

    const observer: IntersectionObserver = new IntersectionObserver(callback, options)

    observer.observe(menu)

    return () => {
      observer.disconnect()
    }
  }, [])

  const onClose = () => {
    refreshNotifications()
    setActiveTab(tabUnread)
  }

  const onSelectTabHandler = (tab: string | null) => {
    if (tab !== null) setActiveTab(tab as NotificationTab)
  }

  return (
    <div
      id='notification-menu'
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <TabContainer activeKey={activeTab} onSelect={onSelectTabHandler}>
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top position-relative'
          style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
        >
          <h3 className='text-white fw-bold px-9 mt-10 mb-6'>Notifications</h3>
          {
            activeTab == tabUnread &&
            <OverlayTrigger
              overlay={<Tooltip>Mark all as read</Tooltip>}
              placement='top'
            >
              <button
                className='position-absolute end-0 bottom-0 mb-1 btn btn-icon btn-sm'
                onClick={() => markAllAsRead()}
              >
                <i className="fa-regular fa-envelope-open text-white"></i>
              </button>
            </OverlayTrigger>
          }
          <Nav variant='tabs' className='nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
            <Nav.Item>
              <Nav.Link eventKey={tabUnread} className='text-white opacity-75 opacity-state-100 pb-4'>
                Latest
                {
                  unreadNotifications.length > 0 &&
                  <div className='badge badge-secondary ms-2'>{unreadNotifications.length}</div>
                }
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={tabRead} className='text-white opacity-75 opacity-state-100 pb-4'>
                Archived
                {
                  readNotifications.length > 0 &&
                  <div className='badge badge-secondary ms-2'>{readNotifications.length}</div>
                }
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          <Tab.Pane
            eventKey={tabUnread}
            className='scroll-y mh-325px my-3'
            style={{ overflowY: 'auto' }}
          >
            {
              unreadNotifications.map((notification: Notification) =>
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  type='unread'
                  markAsRead={() => markAsRead(notification)}
                />
              )
            }
            {
              unreadNotifications.length == 0 &&
              <div className='text-muted text-center'>
                <i className="fa-regular fa-check-circle h1 text-muted mt-1"></i>
                <div>No new notification. You're all caught up!</div>
              </div>
            }
          </Tab.Pane>
          <Tab.Pane
            eventKey={tabRead}
            className='scroll-y mh-325px my-3'
            style={{ overflowY: 'auto' }}
          >
            {
              readNotifications.map((notification: Notification) =>
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  type='read'
                />
              )
            }
            {
              readNotifications.length == 0 &&
              <div className='text-muted text-center'>
                <i className="fa-regular fa-folder-open h1 text-muted mt-1"></i>
                <div>There's nothing here yet.</div>
              </div>
            }
          </Tab.Pane>
        </Tab.Content>
      </TabContainer>
    </div>
  )
}

export default NotificationMenu
