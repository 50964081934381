import axios from "axios";
import { InvoiceDeleteParams } from "./Models";

const API_URL = process.env.REACT_APP_API_URL

export const INVOICE_INDEX_URL = `${API_URL}/invoice/index`
export const INVOICE_CREATE_URL = `${API_URL}/invoice/create`
export const INVOICE_STORE_URL = `${API_URL}/invoice/store`
export const INVOICE_GET_URL = `${API_URL}/invoice/get`
export const INVOICE_UPDATE_URL = `${API_URL}/invoice/update`
export const INVOICE_DELETE_URL = `${API_URL}/invoice/delete`
export const INVOICE_BILL_UPLOAD_URL = `${API_URL}/invoice/bills/upload`
export const INVOICE_RECEIPT_UPLOAD_URL = `${API_URL}/invoice/receipts/upload`
export const INVOICE_BILL_DELETE_URL = `${API_URL}/invoice/bills/delete`
export const INVOICE_RECEIPT_DELETE_URL = `${API_URL}/invoice/receipts/delete`

export const invoiceIndex = (params: {}) => {
  return axios.post(INVOICE_INDEX_URL, params)
}

export const invoiceCreate = () => {
  return axios.post(INVOICE_CREATE_URL)
}

export const invoiceStore = (params: {}) => {
  return axios.post(INVOICE_STORE_URL, params)
}

export const invoiceGet = (params: {}) => {
  return axios.post(INVOICE_GET_URL, params)
}

export const invoiceUpdate = (params: {}) => {
  return axios.post(INVOICE_UPDATE_URL, params)
}

export const invoiceDelete = (params: InvoiceDeleteParams) => {
  return axios.post(INVOICE_DELETE_URL, params)
}

export const invoiceBillsUpload = (params: {}) => {
  return axios.post(INVOICE_BILL_UPLOAD_URL, params)
}

export const invoiceReceiptsUpload = (params: {}) => {
  return axios.post(INVOICE_RECEIPT_UPLOAD_URL, params)
}

export const invoiceBillsDelete = (params: {}) => {
  return axios.post(INVOICE_BILL_DELETE_URL, params)
}

export const invoiceReceiptsDelete = (params: {}) => {
  return axios.post(INVOICE_RECEIPT_DELETE_URL, params)
}
