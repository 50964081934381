import { DATE_FORMAT, DATE_TIME_FORMAT } from "../../../helpers/constants";
import { ApiRequest } from "../../../helpers/types/api-types";
import DateTimeShortcut from "../../date-time-shortcut/components/DateTimeShortcut";
import { DateTimeRange, DateTimeShortcutType } from "../../date-time-shortcut/date-time-shortcut-types";
import { DateInput, DateTimeInput, TableFilterDateTimeShortcutProps } from "../table-filter-types";

const TableFilterDateTimeShortcut = <Params extends ApiRequest>(props: TableFilterDateTimeShortcutProps<Params>) => {
  const { formik, dateTimeFields } = props

  if (dateTimeFields.length === 0) return <></>

  let start_field_name: string, end_field_name: string, dateTimeFormat: string
  let shortcutTypes: DateTimeShortcutType[] | undefined

  switch (dateTimeFields[0].type) {
    case 'date':
      start_field_name = (dateTimeFields as DateInput[]).find((field: DateInput) => {
        return field.name.includes('start') || field.name.includes('from')
      })!.name

      end_field_name = (dateTimeFields as DateInput[]).find((field: DateInput) => {
        return field.name.includes('end') || field.name.includes('to')
      })!.name

      dateTimeFormat = DATE_FORMAT
      break
    case 'date-time':
      start_field_name = (dateTimeFields as DateTimeInput[]).find((field: DateTimeInput) => {
        return field.name.includes('start') || field.name.includes('from')
      })!.name
      end_field_name = (dateTimeFields as DateTimeInput[]).find((field: DateTimeInput) => {
        return field.name.includes('end') || field.name.includes('to')
      })!.name

      dateTimeFormat = DATE_TIME_FORMAT
      break
  }

  for (const field of dateTimeFields) {
    if ('shortcutTypes' in field) {
      shortcutTypes = field.shortcutTypes
      break
    }
  }

  return (
    <div className='mt-1 mb-4'>
      <DateTimeShortcut
        onChange={(newValue: DateTimeRange) => {
          if (newValue.start === '' && newValue.end === '') return

          formik.setFieldValue(start_field_name, newValue.start)
          formik.setFieldValue(end_field_name, newValue.end)
        }}
        value={{ start: formik.values[start_field_name], end: formik.values[end_field_name] }}
        dateTimeFormat={dateTimeFormat}
        types={shortcutTypes ?? ['day', 'week', 'month']}
      />
    </div>
  )
}

export default TableFilterDateTimeShortcut
