import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import { KTSVG } from "../../../../../_metronic/helpers";
import { checkRequestResponse, removeEmptyFilters } from "../../../../components/Helpers";
import MessageModal from "../../../../components/MessageModal";
import { TableContent } from "../../../../components/Table";
import TableFilter from "../../../../components/table-filter/components/TableFilter";
import ToolBarButton from "../../../../components/ToolBarButton";
import { DATE_TIME_FORMAT, DEFAULT_PAGINATION } from "../../../../helpers/constants";
import { getSort } from "../../../../helpers/functions";
import {
  ApiResponse,
  FinalRequest,
  RequestPagination,
  RequestSort,
  ResponsePagination
} from "../../../../helpers/types/api-types";
import { useAuth } from "../../../auth";
import TestExecutionLogModal from "../../execution-log/components/modals/TestExecutionLogModal";
import { TestSession, TestSessionIndexParams, TestSessionStatus } from "../TestSessionModels";
import { TEST_SESSION_INDEX_URL, testSessionIndex } from "../TestSessionRequests";
import TestSessionModal from "./test-session-modal/TestSessionModal";
import TestSessionIndexRow from "./TestSessionIndexRow";
import TablePagination, { initialPagination } from "../../../../components/TablePagination/TablePagination";

const initialFilters: TestSessionIndexParams = {
  start_date_time: moment().startOf('day').format(DATE_TIME_FORMAT),
  end_date_time: moment().endOf('day').format(DATE_TIME_FORMAT),
  user_created_only: false,
}

const TestSessionPageContent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [testSessions, setTestSessions] = useState<TestSession[]>([])
  const [filters, setFilters] = useState<FinalRequest<TestSessionIndexParams>>(initialFilters)
  const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
  const [sort, setSort] = useState<RequestSort>()
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  // test session modal
  const [testSessionModalShowing, setTestSessionModalShowing] = useState<boolean>(false)
  const [selectedTestSession, setSelectedTestSession] = useState<number>(0)
  // test execution log modal
  const [testExecutionLogShowing, setTestExecutionLogShowing] = useState<boolean>(false)
  const [selectedTestSessionId, setSelectedTestSessionId] = useState<number>(0)

  const auth = useAuth()

  const onFilterHandler = async (params: TestSessionIndexParams) => {
    setIsLoading(true)

    try {
      const response = await testSessionIndex(removeEmptyFilters(params))

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        return
      }

      const response_data = response.data.data
      let data: TestSession[];

      if (params.paginate) {
        setPagination(response_data.paginations)

        data = response_data.rows
      } else {
        data = response_data
      }

      setTestSessions(data)
      setIsLoading(false)
      setFilters(params)
    } catch (e) {
      console.log(e)
      setIsLoading(false)
      MessageModal({ type: 'failed' })
    }
  }

  const onSortHandler = (event: React.MouseEvent<HTMLElement>) => {
    setSort(getSort(event))
  }

  const updateHandler = (sessionId: number) => {
    setIsUpdate(true)
    setTestSessionModalShowing(true)
    setSelectedTestSession(sessionId)
  }

  const createHandler = () => {
    setIsUpdate(false)
    setTestSessionModalShowing(true)
  }

  const closeHandler = () => {
    setSelectedTestSession(0)
    setIsUpdate(false)
    setTestSessionModalShowing(false)
  }

  const showExecutionLog = (testSessionId: number) => {
    setTestExecutionLogShowing(true)
    setSelectedTestSessionId(testSessionId)
  }

  const closeExecutionLog = () => {
    setTestExecutionLogShowing(false)
    setSelectedTestSessionId(0)
  }

  const onResponseHandler = (response: ApiResponse<TestSession[]>, params: FinalRequest<TestSessionIndexParams>) => {
    let testSessions: TestSession[]
    let pagination: ResponsePagination

    if ('paginations' in response.data) {
      testSessions = response.data.rows ?? []
      pagination = response.data.paginations
    } else {
      testSessions = response.data
      pagination = initialPagination
    }

    setTestSessions(testSessions)
    setPagination(pagination)
    setFilters(params)
    setIsLoading(false)
  }

  return (
    <>
      {auth.hasPermission('test-session-create') &&
        <ToolBarButton>
          <button
            type="button"
            className='btn btn-sm btn-primary'
            onClick={createHandler}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Test Session
          </button>
        </ToolBarButton>
      }

      <TestSessionModal
        show={testSessionModalShowing}
        onClose={closeHandler}
        onSuccess={() => onFilterHandler(filters)}
        selectedTestSessionId={selectedTestSession}
        isUpdate={isUpdate}
      />

      <TestExecutionLogModal
        show={testExecutionLogShowing}
        onClose={closeExecutionLog}
        testSessionId={selectedTestSessionId}
      />

      <TableFilter<TestSessionIndexParams, TestSession[]>
        fields={[
          {
            label: 'From',
            name: 'start_date_time',
            type: 'date-time',
            defaultValue: moment().startOf('day').format(DATE_TIME_FORMAT)
          },
          {
            label: 'To',
            name: 'end_date_time',
            type: 'date-time',
            defaultValue: moment().endOf('day').format(DATE_TIME_FORMAT)
          },
          {
            label: 'Status',
            name: 'status',
            type: 'select',
            options: [
              { value: '', label: 'All' },
              { value: TestSessionStatus.Scheduled.toString(), label: TestSessionStatus[TestSessionStatus.Scheduled] },
              { value: TestSessionStatus.Running.toString(), label: TestSessionStatus[TestSessionStatus.Running] },
              { value: TestSessionStatus.Completed.toString(), label: TestSessionStatus[TestSessionStatus.Completed] },
              { value: TestSessionStatus.Passed.toString(), label: TestSessionStatus[TestSessionStatus.Passed] },
              { value: TestSessionStatus.Failed.toString(), label: TestSessionStatus[TestSessionStatus.Failed] },
            ]
          },
          {
            label: 'Created By',
            name: 'created_by',
            type: 'text',
            placeholder: 'Search name/username',
            newRow: true
          },
          {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Search name'
          },
          {
            label: '',
            type: 'checkbox-group',
            checkboxes: [
              {
                label: 'Show only my test sessions',
                name: 'user_created_only',
                defaultValue: false
              },
            ]
          },
        ]}
        validationSchema={Yup.object().shape({
          start_date_time: Yup.date().required(),
          end_date_time: Yup.date().required(),
          status: Yup.number().optional().integer().oneOf(TestSessionStatus.values()),
          user_created_only: Yup.boolean(),
          created_by: Yup.string().optional(),
          remark: Yup.string().optional(),
          name: Yup.string().optional(),
        })}
        apiUrl={TEST_SESSION_INDEX_URL}
        onSubmit={() => setIsLoading(true)}
        onResponse={onResponseHandler}
        sort={sort}
        pagination={{
          paginate: 1,
          per_page: pagination.per_page,
          page: pagination.current_page
        }}
      />

      <TableContent
        name='test session'
        columns={[
          { title: 'Name', name: 'name', sort: false },
          { title: 'Start At (GMT +08:00)', name: 'start_date_time', sort: true },
          { title: 'Completed At (GMT +08:00)', name: 'end_date_time', sort: false },
          { title: 'Status', name: 'status', sort: true },
          { title: 'Created At / By', name: 'created_by', sort: true },
          { title: 'Actions', name: 'actions', sort: false },
        ]}
        contents={testSessions}
        isLoading={isLoading}
        filters={filters}
        sortHandler={onSortHandler}
        tableRows={
          !isLoading && testSessions.map((testSession: TestSession) =>
            <TestSessionIndexRow
              testSession={testSession}
              onUpdate={updateHandler}
              onShowExecutionLog={showExecutionLog}
              key={testSession.id}
            />
          )
        }
        filterTable={onFilterHandler}
        setFilters={setFilters}
      />
      
      {
        pagination &&
          <TablePagination
            pagination={pagination}
            onPaginate={(paginationData: RequestPagination) => {
              onFilterHandler({
                ...filters,
                ...paginationData
              })
              setFilters(prevFilters => ({
                ...prevFilters,
                ...paginationData
              }))
          }}
        />
      }
    </>
  )
}

export default TestSessionPageContent
