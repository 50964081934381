import { PageData } from "./Models"

const PaginationButton = ({ label, value, isCurrentPage, clickHandler }: PageData) => {
  return (
    <>
      <li key={value ?? label} className={`page-item ${isCurrentPage ? 'active' : ''}`}>
        {
          clickHandler === undefined 
            ? <span 
              className="page-link"
              key={value ?? label}
            >
              {label}
            </span>
            : <button
              type='button'
              onClick={clickHandler}
              className={`page-link ${isCurrentPage ?? 'active'}`}
            >
              {label}
            </button>
        }
      </li>
    </>
  )
}

export default PaginationButton