import clsx from "clsx";
import Select, { ActionMeta, MultiValue, StylesConfig } from "react-select";
import { RequireAtLeastOne, SelectOptionModel } from "./Helpers";
import { RoleUser } from "../modules/roles/Models";
import { useEffect, useState } from "react";

interface Props {
  name: string,
  options?: (SelectOptionModel | RoleUser)[], // RoleUser[] is used as options in RoleModal & RoleHistoryModal
  fixedOptions?: SelectOptionModel[],
  formik: any,
  isMulti: boolean,
  clearable: boolean,
  onTouch: any,
  placeholder: string,
  validate: any,
  menuHeight: number,
  disabled: boolean
}

const styles: StylesConfig<SelectOptionModel> = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'lightgray' } : base
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed ? { ...base, paddingRight: 6 } : base
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base
  },
  menu: (base) => ({
    ...base,
    zIndex: 9999
  })
}

const sortOptions = (values: readonly (SelectOptionModel | RoleUser)[]) =>
  values
    .filter((value) => value.isFixed)
    .concat(values.filter((value) => !value.isFixed))

const addIsFixed = (fixedOptions: SelectOptionModel[]) => fixedOptions.map(fixedOption => {
  return { ...fixedOption, isFixed: true }
})

const mergeOptions = (options?: (SelectOptionModel | RoleUser)[], fixedOptions?: SelectOptionModel[]) => {
  if (options === undefined) return fixedOptions
  if (fixedOptions === undefined) return options
  return sortOptions([...options, ...addIsFixed(fixedOptions)])
}

const ReactSelect = (props: RequireAtLeastOne<Props, 'options' | 'fixedOptions'>) => {
  const [value, setValue] = useState<readonly (SelectOptionModel | RoleUser)[]>([])
  const [mergedOptions, setMergedOptions] = useState<(SelectOptionModel | RoleUser)[]>([])

  useEffect(() => {
    props.formik.setFieldValue(props.name, value)
  }, [value])

  useEffect(() => {
    let fixedOptions

    if (props.fixedOptions !== undefined) {
      fixedOptions = addIsFixed(props.fixedOptions)
      setValue(fixedOptions)
    }

    setMergedOptions(mergeOptions(props.options, fixedOptions) ?? [])
  }, [props.options, props.fixedOptions])

  const handleOnSelectChange = (value: MultiValue<SelectOptionModel | RoleUser>, actionMeta: ActionMeta<SelectOptionModel | RoleUser>) => {
    if (!props.isMulti) {
      setValue(value)
      return
    }

    switch (actionMeta.action) {
      case "pop-value":
      case "remove-value":
        if (actionMeta.removedValue.isFixed) return
        break
      case "clear":
        value = mergedOptions.filter(value => value.isFixed)
        break
    }

    setValue(sortOptions(value))
  }

  return (
    <Select
      value={value}
      options={mergedOptions}
      styles={styles}
      className={props.validate ? clsx(
        'form-control form-control-solid p-1',
        { 'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name] },
        { 'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name] }
      ) : 'form-control form-control-solid p-1'}
      {...props.formik.getFieldProps(props.name)}
      placeholder={props.disabled ? '' : (props.placeholder ?? 'Select ' + props.name.split('_').join(' '))}
      onChange={handleOnSelectChange}
      isMulti={props.isMulti}
      onBlur={props.onTouch}
      isClearable={props.clearable}
      maxMenuHeight={props.menuHeight}
      isDisabled={props.disabled}
    />
  )
}

ReactSelect.defaultProps = {
  isMulti: false,
  clearable: false,
  onTouch: false,
  placeholder: null,
  validate: true,
  menuHeight: false,
  disabled: false
}

export { ReactSelect }