export interface SgpMaintenance {
  maintenance_id: number
  site_code: string
  game_provider_code: string
  currency_code?: string
  category_code?: string
  maintain_from: string
  maintain_to: string
  status: MaintenanceStatus
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export interface SgpMaintenanceIndexParams {
  site_code?: string
  game_provider_code?: string
  status?: number
  start_date_time?: string
  end_date_time?: string
  currency_code?: string
  category_code?: string
}

export interface SgpMaintenanceGetParams {
  maintenance_id: number
}

export interface SgpMaintenanceSetParams {
  site_code: string
  status: MaintenanceStatus
  game_provider_code: string
  maintain_from: string
  maintain_to?: string
  currency_code?: string
  category_code?: string
}

export interface SgpMaintenanceUpdateParams {
  maintenance_id: number
  site_code: string
  game_provider_code: string
  status: MaintenanceStatus
  maintain_from: string
  maintain_to?: string
  currency_code?: string
  category_code?: string
}

export enum MaintenanceStatus {
  Inactive,
  Active
}

export namespace MaintenanceStatus {
  export function values(): number[] {
    return Object.values(MaintenanceStatus).filter((value): value is number => typeof value === 'number')
  }
}
