import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { checkRequestResponse } from "../../../components/Helpers";
import LoadingIndicator from "../../../components/LoadingIndicator";
import MessageModal from "../../../components/MessageModal";
import { currencyMonthlyGgrReport } from "../dashboard-requests";
import { CurrencyMonthlyGgrReport, MonthlyGgrReportChartProps } from "../dashboard-types";

const CurrencyGgrChart = (props: MonthlyGgrReportChartProps) => {
  const { startDate, endDate } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currencyMonthlyGgr, setCurrencyMonthlyGgr] = useState<CurrencyMonthlyGgrReport>()
  const [currencies, setCurrencies] = useState<string[]>([])
  const [series, setSeries] = useState<ApexNonAxisChartSeries>([])
  const [colors, setColors] = useState<string[]>([])

  useEffect(() => {
    void loadData()
  }, [])

  useEffect(() => {
    if (currencyMonthlyGgr === undefined) return

    // The API will return month by month if query more than one month
    // Sum up (just in case) all months
    const currencyGgrs: { [currency: string]: number } = {}

    for (const month in currencyMonthlyGgr) {
      for (const monthlyGgr of currencyMonthlyGgr[month]) {
        const currencyCode: string = monthlyGgr.currency_code

        if (currencyGgrs[currencyCode] === undefined) {
          currencyGgrs[currencyCode] = +monthlyGgr.ggr
          continue
        }

        currencyGgrs[currencyCode] += +monthlyGgr.ggr
      }
    }

    const currencies: string[] = Object.keys(currencyGgrs)
    const ggrs: number[] = Object.values(currencyGgrs)
    const colors: string[] = alternate(getColors(currencies.length))

    setCurrencies(currencies)
    setSeries(ggrs)
    setColors(colors)
  }, [currencyMonthlyGgr])

  const loadData = async () => {
    setIsLoading(true)

    try {
      const response = await currencyMonthlyGgrReport({ start_date: startDate, end_date: endDate })

      if (!checkRequestResponse(response)) return

      const data: CurrencyMonthlyGgrReport = response.data.data as CurrencyMonthlyGgrReport

      setCurrencyMonthlyGgr(data)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error loading GGR by Currency.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='position-relative'>
      <Chart
        type='donut'
        series={series}
        options={{
          colors: colors,
          labels: currencies,
          legend: {
            labels: {
              colors: 'var(--kt-body-color)'
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  value: {
                    color: 'var(--kt-body-color)',
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    color: 'var(--kt-body-color)',
                    formatter: (w: any) => {
                      const total: number = w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0)

                      return total.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }
                  },
                },
              },
            },
          },
          tooltip: {
            fillSeriesColor: false,
          },
          yaxis: {
            labels: {
              style: {
                colors: 'var(--kt-body-color)',
              },
              formatter: value => (value ?? 0).toLocaleString(undefined, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            },
          },
        }}
      />
      {isLoading &&
        <div className='position-absolute w-100 h-100 top-0 start-0'>
          <LoadingIndicator />
        </div>
      }
      {series.length === 0 && !isLoading &&
        <div className='position-absolute w-100 h-100 top-0 start-0'>
          <div className='d-flex align-items-center justify-content-center h-100'>
            <span className='text-muted fs-4'>Data is currently not available</span>
          </div>
        </div>
      }
    </div>
  )
}

export default CurrencyGgrChart

const getColors = (count: number) => {
  const colors: string[] = [];

  for (let i = 0; i < count; i++) {
    const hue = i * (360 / count) % 360
    colors.push(`hsl(${hue}, 80%, 50%)`)
  }

  return colors;
}

const shuffle = <T, >(values: T[]): T[] => {
  for (let i = values.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [values[i], values[j]] = [values[j], values[i]];
  }
  return values;
}

const alternate = <T, >(values: T[]): T[] => {
  const result: T[] = []
  const middle: number = Math.ceil(values.length / 2)

  for (let i = 0; i < middle; i++) {
    result.push(values[i]);
    if (i + middle < values.length) {
      result.push(values[i + middle]);
    }
  }

  return result;
}
