import clsx from "clsx";
import { MouseEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Notification, NotificationType } from "./NotificationModels";

type Props = {
  notification: Notification
  type: 'unread'
  markAsRead: () => void
} | {
  notification: Notification
  type: 'read'
}
const NotificationItem = (props: Props) => {
  const notification = props.notification
  const type = props.type

  let state = '', icon = '';
  switch (notification.type) {
    case NotificationType.INFO:
      state = 'primary'
      icon = 'envelope'
      break
    case NotificationType.ERROR:
      state = 'danger'
      icon = 'triangle-exclamation'
      break
    case NotificationType.WARNING:
      state = 'warning'
      icon = 'circle-exclamation'
      break
  }

  const onMarkAsReadHandler = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if ('markAsRead' in props) props.markAsRead()
  }

  const body: JSX.Element = (
    <>
      <div className='symbol symbol-35px me-4'>
          <span className={`symbol-label bg-light-${state}`}>
            <i className={`fa-solid fa-${icon} text-${state} fs-3`}></i>
          </span>
      </div>
      <div className='flex-grow-1'>
        <div className='mb-0 me-2'>
          <div className='d-flex justify-content-between'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {notification.message_title}
            </div>
            {
              type == 'unread' &&
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Mark as read</Tooltip>}
              >
                <i
                  className='fa-solid fa-times cursor-pointer'
                  onClick={onMarkAsReadHandler}
                ></i>
              </OverlayTrigger>
            }
          </div>
          <div className='text-gray-700 fs-7'>{notification.message_body}</div>
        </div>
        <div className='text-end'>
          <span className='badge badge-light fs-8'>{notification.notification_time}</span>
        </div>
      </div>
    </>
  )

  if (notification.url == null || notification.url == '') {
    return (
      <div
        className={clsx(
          'd-flex align-items-center py-4 bg-hover-light-primary px-3 cursor-default',
          { 'opacity-50': type == 'unread' && notification.is_read == 1 }
        )}
      >
        {body}
      </div>
    )
  } else {
    return (
      <Link
        to={notification.url}
        className={clsx(
          'd-flex align-items-center py-4 bg-hover-light-primary px-3',
          { 'opacity-50': type == 'unread' && notification.is_read == 1 }
        )}
      >
        {body}
      </Link>
    )
  }
}

export default NotificationItem
