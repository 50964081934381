import ActionButtons, { BUTTON_ICONS } from "../../../../components/ActionButtons";
import { TestResult, TestResultStatus } from "../TestResultModels";

const TestResultRow = ({ testResult, viewLog }: { testResult: TestResult, viewLog: (arg0: number) => void}) => {
  const parameters: string = JSON.stringify(testResult.parameter, null, 2)
  const response: string = JSON.stringify(testResult.response, null, 2)

  const StatusLabel = ({ status }: { status: TestResultStatus }) => {
    let badgeType: string

    switch (status) {
      case TestResultStatus.Failed:
        badgeType = 'danger'
        break;
      case TestResultStatus.Success:
        badgeType = 'success'
        break;
      case TestResultStatus.Pending:
        badgeType = 'warning'
        break;
    }

    return (
      <div className={`badge badge-${badgeType}`}>
        {TestResultStatus[testResult.status]}
      </div>
    )
  }

  return (
    <tr className='border-bottom'>
      <td className='py-4 fw-bold'>{testResult.test_template.name}</td>
      <td className='py-4'>{testResult.test_template.route}</td>
      <td className='py-4 align-top min-w-300px mw-300px'>
        <pre className='p-2 code-snippet'>{parameters}</pre>
      </td>
      <td className='py-4 align-top min-w-300px mw-300px'>
        <pre className='p-2 code-snippet'>{response}</pre>
      </td>
      <td className='py-4 text-capitalize'>
        {testResult.test_template.category.replace(/[-_]/g, ' ')}
      </td>
      <td className='py-4 text-capitalize'>
        {testResult.test_template.group}
      </td>
      <td className='py-4 text-center'>{testResult.test_template.run_sequence}</td>
      <td className='py-4 text-capitalize'>{testResult.test_template.tag}</td>
      <td className='py-4'><StatusLabel status={testResult.status} /></td>
      <td className='py-4'>{testResult.completed_at ?? '-'}</td>
      <ActionButtons
        buttons={[
          {key: testResult.id + '_edit_button', function: () => viewLog(testResult.id), icon: BUTTON_ICONS.LOG_ICON, overlay: 'Execution Log'}
        ]}
        removeDflex={true}
      />
    </tr>
  )
}

export default TestResultRow
