import axios from "axios";
import { SiteIndexParams } from "./Models";

const API_URL = process.env.REACT_APP_API_URL

export const INDEX_SITE_URL = `${API_URL}/site/index`
export const CREATE_SITE_URL = `${API_URL}/site/create`
export const STORE_SITE_URL = `${API_URL}/site/store`
export const UPDATE_SITE_URL = `${API_URL}/site/update`
export const DELETE_SITE_URL = `${API_URL}/site/delete`
export const UPDATE_SITE_STATUS_URL = `${API_URL}/site/update-status`


export const siteIndex = (filters: {}) => {
    return axios.post(INDEX_SITE_URL, filters)
}

export const siteCreate = () => {
    return axios.post(CREATE_SITE_URL)
}

export const siteStore = (createSiteParams: {}) => {
    return axios.post(STORE_SITE_URL, createSiteParams)
}

export const siteUpdate = (updateSiteParams: {}) => {
    return axios.post(UPDATE_SITE_URL, updateSiteParams)
}

export const siteDelete = (siteId: number) => {
    return axios.post(DELETE_SITE_URL, {site_id:siteId})
}

export const siteStatusUpdate = (updateSiteStatusParams: {}) => {
    return axios.post(UPDATE_SITE_STATUS_URL, updateSiteStatusParams)
}
