import {useContext, useEffect} from "react";
import ToolbarContext from "../store/toolbar-context";

const ToolBarButton = (props: any) => {
  const { onSetToolbar } = useContext(ToolbarContext)

  useEffect(() => {
    onSetToolbar(props.children)
  }, [])

  return <></>
}

export default ToolBarButton