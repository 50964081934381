import Swal from "sweetalert2";

interface ValidationMessages {
  [field: string]: string[]
}

const isValidationMessages = (obj: any): obj is ValidationMessages => {
  return (
    typeof obj === 'object'
    && obj !== null
    && !Array.isArray(obj)
    && Object.values(obj).every(value => Array.isArray(value) && value.every(item => typeof item === 'string'))
  )
}

const formatMessages = (input: ValidationMessages | string | string[]): string => {
  if (isValidationMessages(input)) {
    const messages: string[] = []

    for (const field in input) {
      messages.push(...input[field])
    }

    return messages.join('<br>')
  }

  if (Array.isArray(input)) return input.join('<br>')

  return input
}

const MessageModal = (props: { type: string, messages?: ValidationMessages | string | string[] }) => {
  let finalMessage: string

  if (props.messages !== undefined) {
    finalMessage = formatMessages(props.messages);
  } else {
    finalMessage = props.type === 'success'
      ? 'Request success!'
      : 'Request failed, please try again.'
  }

  void Swal.fire({
    title: props.type === 'success' ? 'Success' : 'Error',
    html: finalMessage,
    icon: props.type === 'success' ? 'success' : 'error',
    buttonsStyling: false,
    confirmButtonText: "Okay",
    customClass: {
      confirmButton: "btn btn-primary"
    }
  })
}

export default MessageModal
