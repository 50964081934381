interface Props {
  spinnerSize?: string | number
}

const LoadingIndicator = ({ spinnerSize }: Props) => {
  spinnerSize = spinnerSize ?? '5rem'

  return (
    <div className='d-flex justify-content-center align-items-center flex-column h-100'>
      <div
        className="spinner-border text-muted mb-4"
        role="status"
        style={{ width: spinnerSize, height: spinnerSize }}></div>
      <div className='text-muted fs-3'>Loading...</div>
    </div>
  )
}

export default LoadingIndicator
