import { Modal } from "react-bootstrap"
import { ActionHistoryModel, PDPDReportFilter, ReportActionHistoryFilter } from "../../Models"
import { KTSVG } from "../../../../../../_metronic/helpers"
import { TableContent } from "../../../../../components/Table"
import { useEffect, useState } from "react"
import moment from "moment"
import { getReportActionHistory } from "../../Requests"
import { checkRequestResponse } from "../../../../../components/Helpers"
import MessageModal from "../../../../../components/MessageModal"
import ReportActionHistoryRow from "../ReportActionHistoryRow"
import * as Yup from "yup";
import { TableFilterComponent } from "../../../../../components/TableFilter"

interface Props {
    show: boolean
    game_provider_code: string
    startDate: string
    endDate: string
    params: PDPDReportFilter
    onClose: () => void
}

const initialParams = {
    start_date_time: '',
    end_date_time: '',
    game_provider_code: '',
    startDate: '',
    endDate: '',
    type: 'pdpd',
}
const ReportStatusHistoryModal = (props: Props) => {
    const [actionHistory, setActionHistory] = useState<ActionHistoryModel[]>([])
    const [filters, setFilters] = useState<ReportActionHistoryFilter>(initialParams)
    const [isLoading, setLoading] = useState<boolean>(false)

    useEffect(() => { 
        let params: ReportActionHistoryFilter = {
            start_date_time: moment(props.startDate).format('YYYY-MM-DD HH:mm:ss'),
            end_date_time: moment(props.endDate).format('YYYY-MM-DD HH:mm:ss'),
            game_provider_code: props.game_provider_code,
            type: 'pdpd'
        }
        setFilters(params)
    }, [props.show])

    useEffect(() => {
        if (filters.game_provider_code && props.show) {
            filterTable()
        }
    }, [filters])

    const filterTable = async () => {
        setLoading(true)
        try {
            const response = await getReportActionHistory(filters)
            checkRequestResponse(response)

            setActionHistory(response.data.data.action_history)
        } catch (err) {
            console.error(err)
            MessageModal({type: 'failed'})
        }

        setLoading(false)
        return
    }
    
    const onFilterHandler = (filters: any) => {
        setFilters({
            game_provider_code: props.game_provider_code,
            start_date_time: moment(filters.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
            end_date_time: moment(filters.end_date_time).format('YYYY-MM-DD HH:mm:ss'),
            type: 'pdpd'
        })
    }

    return (
        <>
            <Modal
                id='kt_modal_report_status_history'
                tabIndex={-1}
                dialogClassName='modal-dialog modal-dialog-centered mw-100 mx-20'
                show={props.show}
                scrollable
                onHide={() => props.onClose()}
            >
                <div className='modal-header px-10'>
                    <h2>Report Status History ({props.game_provider_code})</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.onClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className='modal-body px-lg-10'>
                    <TableFilterComponent
                        isLoading={isLoading}
                        fields={[
                            {
                                title: 'Start Date Time',
                                name: 'start_date_time',
                                type: 'date',
                                initialValue: moment(props.startDate).format('YYYY-MM-DD HH:mm:ss'),
                                time: true,
                                validate: true,
                            }, {
                                title: 'End Date Time',
                                name: 'end_date_time',
                                type: 'date',
                                initialValue: moment(props.endDate).format('YYYY-MM-DD HH:mm:ss'),
                                time: true,
                                validate: true
                            }
                        ]}
                        filterSchema={Yup.object().shape({
                            start_date_time: Yup.string().required('Start date time is required'),
                            end_date_time: Yup.string().required('End date time is required'),
                        })}
                        onLoad={(isLoading) => { setLoading(isLoading) }}
                        onFilter={onFilterHandler}
                        filterBtn='Search'
                    />
                    <TableContent
                        name='Report Status History'
                        columns={[
                            { title: "Action", name: "message", sort: false },
                            { title: "Hour", name: "hour", sort: false },
                            { title: "Execution Start", name: "execution_start_time", sort: false },
                            { title: "Execution End", name: 'execution_end_time', sort: false },
                            { title: "Status", name: 'status', sort: false },
                            { title: "Details", name: 'details', sort: false },
                        ]}
                        contents={actionHistory}
                        isLoading={isLoading}
                        filters={filters}
                        sortHandler={null}
                        tableRows= {!isLoading && actionHistory.length > 0 && actionHistory.map((actionHistory: ActionHistoryModel, index: number) => {
                            return <ReportActionHistoryRow
                                key={index}
                                index={index}
                                actionHistory={actionHistory}
                            />
                        })}
                        setFilters={() => {}}
                        filterTable={() => {}}
                    />
                </div>
            </Modal>

        </>
    )
}

export default ReportStatusHistoryModal