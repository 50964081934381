import { ReactElement, ReactNode } from "react"
import { badgeContent, generateBadges } from "./Helpers"

export enum TD_ALIGNMENT_X {
    ALIGN_RIGHT = 'justify-content-end',
    ALIGN_CENTER = 'justify-content-center',
    ALIGN_LEFT = 'justify-content-start',
}

export enum TD_ALIGNMENT_Y {
    ALIGN_TOP = 'align-top',
    ALIGN_CENTER = 'align-center',
    ALIGN_BOTTOM = 'align-bottom'
} 

// table row styling options
export enum TR_STYLE {
    HOVER_ROW = 'bg-hover-light-dark',
    BORDER_ROW = 'border-bottom',
} 

interface rowContent {
    key: string
    detail?: number | string | ReactElement
    badges?: badgeContent[]
    extra?: string | ReactElement | Element
    alignX?: TD_ALIGNMENT_X
    alignY?: TD_ALIGNMENT_Y
    noBold?: true
    capitalise?: true
}

interface Props {
    tableDetails?: ReactNode
    contents?: rowContent[]
    action?: ReactNode
    styles?: TR_STYLE[]
}


const TableRow = (props: Props) => {
    const { tableDetails, contents, action, styles } = props

    // table row style
    let styleClass: string = ''
    styles?.map((style: string) => {
        styleClass = styleClass + ' ' + style 
    })

    return (
        <tr className={styleClass}>
            {
                contents && contents.map(content => (
                    <td className={`ps-4 ${content?.alignY ?? ''} ${content?.capitalise ? 'text-capitalize' : ''}`} key={content.key}>
                        <div className={`d-flex ${content?.alignX ?? ''}`}>
                            <div className='flex-column'>
                                <span className={`text-dark ${content.noBold ? '' : 'fw-bold'} mb-1 fs-6'`}>
                                    {content.detail}
                                    {content.extra && <>{content?.extra}</>}
                                </span>
                                { content?.badges && generateBadges(content.badges, content.key) }
                            </div>
                        </div>
                    </td>
                ))
            }
            { tableDetails }
            { action }
        </tr>
    )
}

export default TableRow