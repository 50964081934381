import axios from "axios";
import { TestResultsGetParams } from "./TestResultModels";

const API_URL = process.env.REACT_APP_API_URL

export const TEST_SESSION_RESULTS_GET_URL = `${API_URL}/test-session/{test_session_id}/results`

export const testResultsGet = (testSessionId: string, params?: TestResultsGetParams) => {
  const url = TEST_SESSION_RESULTS_GET_URL.replace('{test_session_id}', testSessionId)

  return axios.post(url, params)
}
