import axios from "axios";
import { NotificationMarkAsReadParams } from "./NotificationModels";

const API_URL = process.env.REACT_APP_API_URL

export const NOTIFICATION_READ_URL = `${API_URL}/notifications/read`
export const NOTIFICATION_UNREAD_URL = `${API_URL}/notifications/unread`
export const NOTIFICATION_MARK_AS_READ_URL = `${API_URL}/notifications/mark-as-read`
export const NOTIFICATION_MARK_ALL_AS_READ_URL = `${API_URL}/notifications/mark-all-as-read`

export const notificationGetRead = () => {
  return axios.post(NOTIFICATION_READ_URL)
}

export const notificationGetUnread = () => {
  return axios.post(NOTIFICATION_UNREAD_URL)
}

export const notificationMarkAsRead = (params: NotificationMarkAsReadParams) => {
  return axios.post(NOTIFICATION_MARK_AS_READ_URL, params)
}

export const notificationMarkAllAsRead = () => {
  return axios.post(NOTIFICATION_MARK_ALL_AS_READ_URL)
}
