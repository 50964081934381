import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  capitalize,
  checkRequestResponse,
  removeEmptyFilters,
  SelectOptionModel,
  sortByKey
} from "../../../../components/Helpers";
import MessageModal from "../../../../components/MessageModal";
import { TableContent } from "../../../../components/Table";
import TableFilter from "../../../../components/table-filter/components/TableFilter";
import { getSort } from "../../../../helpers/functions";
import {
  ApiResponse,
  FinalRequest,
  RequestPagination,
  RequestSort,
  ResponsePagination
} from "../../../../helpers/types/api-types";
import {
  REQUEST_PARAMS_ORDER,
  REQUEST_RESPONSE_ORDER,
  RequestParameters,
  RequestResponse
} from "../../TestModels";
import { TestTemplate, TestTemplateIndexParams, TestTemplateOptions } from "../TestTemplateModels";
import { TEST_TEMPLATE_INDEX_URL, testTemplateIndex, testTemplateOptions } from "../TestTemplateRequests";
import TestTemplateIndexRow from "./TestTemplateIndexRow";
import TablePagination, { initialPagination } from "../../../../components/TablePagination/TablePagination";

const initialSort: RequestSort = {
  sort: 'run_sequence',
  order: 'asc',
}

const TestTemplatePageContent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [testCategoryOptions, setTestCategoryOptions] = useState<SelectOptionModel[]>([])
  const [testGroupOptions, setTestGroupOptions] = useState<SelectOptionModel[]>([])
  const [tagOptions, setTagOptions] = useState<SelectOptionModel[]>([])
  const [testTemplates, setTestTemplates] = useState<TestTemplate[]>([])
  const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
  const [filters, setFilters] = useState<FinalRequest<TestTemplateIndexParams>>({})
  const [sort, setSort] = useState<RequestSort>(initialSort)

  useEffect(() => {
    void loadTemplateOptions()
  }, [])

  const loadTemplateOptions = async () => {
    setIsLoading(true)

    try {
      const response = await testTemplateOptions()

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        console.error('Error loading template options')

        return
      }

      const data: TestTemplateOptions = response.data.data

      const testCategoryOptions: SelectOptionModel[] = data.test_category.map((category: string): SelectOptionModel => {
        return { value: category, label: capitalize(category.replace(/[-_]/g, ' '), true) }
      }).sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))
      testCategoryOptions.unshift({ value: '', label: 'All' })

      const testGroupOptions: SelectOptionModel[] = data.test_groups.map((group: string): SelectOptionModel => {
        return { value: group, label: capitalize(group) }
      }).sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))
      testGroupOptions.unshift({ value: '', label: 'All' })

      const tagOptions: SelectOptionModel[] = Object.entries(data.tags).map(([key, tag]) => {
        return { value: key, label: capitalize(tag) }
      }).sort((a: SelectOptionModel, b: SelectOptionModel) => a.label.localeCompare(b.label))
      tagOptions.unshift({ value: '', label: 'All' })

      setTestCategoryOptions(testCategoryOptions)
      setTestGroupOptions(testGroupOptions)
      setTagOptions(tagOptions)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
      MessageModal({ type: 'failed' })
    }
  }

  const onFilterHandler = async (params: TestTemplateIndexParams) => {
    setIsLoading(true)

    let tempFilters = {
      ...filters,
      paginate: 1,
      page: pagination.current_page,
      per_page: pagination.per_page,
      ...params
    }

    try {
      const response = await testTemplateIndex(removeEmptyFilters(tempFilters))

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        return
      }

      let data = response.data.data

      if (tempFilters.paginate) {
        setPagination(data.paginations)
        data = data.rows
      }

      console.log(data)

      data = data.map((template: TestTemplate) => {
        return {
          ...template,
          required_parameters: sortByKey<RequestParameters>(template.required_parameters, REQUEST_PARAMS_ORDER),
          expected_response: sortByKey<RequestResponse>(template.expected_response, REQUEST_RESPONSE_ORDER)
        }
      })

      setTestTemplates(data)

      setIsLoading(false)
      setFilters(tempFilters)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      MessageModal({ type: 'failed' })

      return
    }
  }

  const onSortHandler = (event: React.MouseEvent<HTMLElement>) => {
    setSort(getSort(event))
  }

  const onResponseHandler = (response: ApiResponse<TestTemplate[]>, params: FinalRequest<TestTemplateIndexParams>) => {
    let templates: TestTemplate[]
    let pagination: ResponsePagination

    if ('paginations' in response.data) {
      templates = response.data.rows ?? []
      pagination = response.data.paginations
    } else {
      templates = response.data
      pagination = initialPagination
    }

    templates = templates.map((template: TestTemplate) => {
      return {
        ...template,
        required_parameters: sortByKey<RequestParameters>(template.required_parameters, REQUEST_PARAMS_ORDER),
        expected_response: sortByKey<RequestResponse>(template.expected_response, REQUEST_RESPONSE_ORDER)
      }
    })

    setTestTemplates(templates)
    setPagination(pagination)
    setFilters(params)
    setIsLoading(false)
  }

  return (
    <>
      <TableFilter<TestTemplateIndexParams, TestTemplate[]>
        fields={[
          {
            label: 'Template Name',
            name: 'name',
            type: 'text',
            placeholder: 'Search template name'
          },
          {
            label: 'Route',
            name: 'route',
            type: 'text',
            placeholder: 'Search route',
            colSizeLg: 6
          },
          {
            label: 'Test Category',
            name: 'category',
            type: 'select',
            options: testCategoryOptions,
            newRow: true
          },
          {
            label: 'Test Group',
            name: 'group',
            type: 'select',
            defaultValue: 'success',
            options: testGroupOptions
          },
          {
            label: 'Tag',
            name: 'tag',
            type: 'select',
            options: tagOptions
          },
        ]}
        validationSchema={Yup.object().shape({
          name: Yup.string().optional(),
          route: Yup.string().optional(),
          group: Yup.string().optional(),
          category: Yup.string().optional(),
          tag: Yup.number().optional()
        })}
        apiUrl={TEST_TEMPLATE_INDEX_URL}
        onSubmit={() => setIsLoading(true)}
        onResponse={onResponseHandler}
        sort={sort}
        pagination={{
          paginate: 1,
          per_page: pagination.per_page,
          page: pagination.current_page
        }}
      />

      <TableContent
        name='test template'
        columns={[
          { title: 'Name', name: 'name', sort: true },
          { title: 'Route', name: 'route', sort: true },
          { title: 'Required Parameters', name: 'required_parameters', sort: false },
          { title: 'Expected Response', name: 'expected_response', sort: false },
          { title: 'Category', name: 'category', sort: true },
          { title: 'Group', name: 'group', sort: true },
          { title: 'Run Sequence', name: 'run_sequence', sort: true },
          { title: 'Tag', name: 'tag', sort: false },
        ]}
        contents={testTemplates}
        isLoading={isLoading}
        filters={filters}
        sortHandler={onSortHandler}
        tableRows={
          !isLoading && testTemplates.map((testTemplate: TestTemplate) =>
            <TestTemplateIndexRow testTemplate={testTemplate} key={testTemplate.group + '_' + testTemplate.name} />
          )
        }
        filterTable={onFilterHandler}
        setFilters={setFilters}
        freezeHeader={true}
      />
      {
        pagination &&
          <TablePagination
            pagination={pagination}
            onPaginate={(paginationData: RequestPagination) => {
              onFilterHandler({
                ...filters,
                ...paginationData
              })
              setFilters(prevFilters => ({
                ...prevFilters,
                ...paginationData
              }))
          }}
        />
      }
    </>
  )
}

export default TestTemplatePageContent
