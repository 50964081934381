/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'
import {setLanguage, useLang} from '../../../i18n/Metronici18n'
import {MenuComponent} from "../../../assets/ts/components";
import {useEffect} from "react";

type Props = {
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}


const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'zh',
    name: '中文',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  // {
  //   lang: 'es',
  //   name: 'Spanish',
  //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
  // },
  // {
  //   lang: 'ja',
  //   name: 'Japanese',
  //   flag: toAbsoluteUrl('/media/flags/japan.svg'),
  // },
  // {
  //   lang: 'de',
  //   name: 'German',
  //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
  // },
  // {
  //   lang: 'fr',
  //   name: 'French',
  //   flag: toAbsoluteUrl('/media/flags/france.svg'),
  // },
]

const Languages = ({
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'click'}",
}: Props) => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      {/* begin::Menu toggle */}
      <a 
        href='#' 
        className={clsx('btn btn-icon w-30px h-30px w-md-40px h-md-40px btn-active-light-primary')}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <span className='menu-title position-relative'>
          <img
            className='w-20px h-20px rounded-1'
            src={currentLanguage?.flag}
            alt='metronic'
          />
        </span>
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
          {languages.map((l) => (
            <div
              className='menu-item px-3'
              key={l.lang}
              onClick={() => {
                setLanguage(l.lang)
              }}
            >
              <a
                href='#'
                className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={l.flag} alt='metronic' />
                </span>
                {l.name}
              </a>
            </div>
          ))}
      </div>
      {/* end::Menu */}

    </>
  )
}

export {Languages}
