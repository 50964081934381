import ActionButtons, { BUTTON_ICONS } from "../../../components/ActionButtons";
import TableRow, { TR_STYLE } from "../../../components/TableRow";
import { SgpMaintenance } from "../Models";
import { maintenanceStatusBadge } from "../../../components/Helpers";

const SgpMaintenanceIndexRow = (props: { sgpMaintenance: SgpMaintenance, onUpdate: VoidFunction }) => {
    const { sgpMaintenance } = props

    return (
        <TableRow 
            key={sgpMaintenance.maintenance_id}
            styles={[TR_STYLE.HOVER_ROW, TR_STYLE.BORDER_ROW]}
            contents={[
                {key: sgpMaintenance.maintenance_id + '_site_code', detail: sgpMaintenance.site_code},
                {key: sgpMaintenance.maintenance_id + '_game_provider_code', detail: sgpMaintenance.game_provider_code},
                {key: sgpMaintenance.maintenance_id + '_currency_code', detail: sgpMaintenance.currency_code ?? 'All Currencies'},
                {key: sgpMaintenance.maintenance_id + '_category_code', detail: sgpMaintenance.category_code ?? 'All Categories'},
                {key: sgpMaintenance.maintenance_id + '_maintain_from', detail: sgpMaintenance.maintain_from},
                {key: sgpMaintenance.maintenance_id + '_maintain_to', detail: sgpMaintenance.maintain_to},
                {key: sgpMaintenance.maintenance_id + '_status', extra: maintenanceStatusBadge(sgpMaintenance.maintain_from, sgpMaintenance.maintain_to, sgpMaintenance.status)},
                {key: sgpMaintenance.maintenance_id + '_created_by', detail: sgpMaintenance.created_by, extra: <p>{sgpMaintenance.created_at}</p>},
                {key: sgpMaintenance.maintenance_id + '_updated_by', detail: sgpMaintenance.updated_by, extra: <p>{sgpMaintenance.updated_at}</p>}
            ]}
            action={
                <ActionButtons 
                    buttons={[
                        {key: sgpMaintenance.maintenance_id + '_update_button', function: props.onUpdate, icon: BUTTON_ICONS.EDIT_ICON, overlay: 'Edit'}
                    ]}
                />
            }
        />
    )

}

export default SgpMaintenanceIndexRow
