import { ChangeEvent, useEffect } from "react";
import { ApiRequest } from "../../../helpers/types/api-types";
import {
  CheckboxCheckedValue,
  CheckboxInput, CheckboxUncheckedValue,
  CheckboxValue,
  TableFilterCheckboxGroupProps
} from "../table-filter-types";

const TableFilterCheckboxGroup = <Params extends ApiRequest>(props: TableFilterCheckboxGroupProps<Params>) => {
  const {
    field,
    formik,
  } = props

  useEffect(() => {
    field.checkboxes.forEach((checkbox: CheckboxInput) => {
      const checked: CheckboxValue = checkbox.defaultValue ?? false
      const checkedValue: CheckboxCheckedValue = checkbox.checkedValue ?? true
      const uncheckedValue: CheckboxUncheckedValue = checkbox.uncheckedValue ?? false

      formik.setFieldValue(checkbox.name, checked ? checkedValue : uncheckedValue)
    })
  }, [])
  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, checkbox: CheckboxInput) => {
    const checked: boolean = event.currentTarget.checked
    const checkedValue: CheckboxCheckedValue = checkbox.checkedValue ?? true
    const uncheckedValue: CheckboxUncheckedValue = checkbox.uncheckedValue ?? false

    formik.setFieldValue(checkbox.name, checked ? checkedValue : uncheckedValue)

    if (checkbox.onChange !== undefined) checkbox.onChange(event)
  }

  return (
    <>
      {field.newRow && <div className='row-break'></div>}
      <div className={`card-title mb-3 col-sm-12 col-md-6 col-lg-${field.colSizeLg ?? 3} offset-lg-${field.offsetLg ?? 0}`}>
        <label
          className='form-label text-muted text-uppercase fw-semibold fs-7 ms-1'
          hidden={field.hidden}
        >
          {field.label == '' ? <>&nbsp;</> : field.label}
        </label>
        <div className='form-control-plaintext px-1'>
          {field.checkboxes.map((checkbox: CheckboxInput) => (
            <div key={checkbox.name} className='form-check form-check-inline me-6'>
              <input
                type='checkbox'
                name={checkbox.name}
                disabled={checkbox.disabled}
                hidden={field.hidden || checkbox.hidden}
                id={checkbox.name}
                className='form-check-input'
                value={formik.values[checkbox.name]}
                checked={formik.values[checkbox.name] ?? false}
                onChange={(event: ChangeEvent<HTMLInputElement>) => onCheckboxChange(event, checkbox)}
              />
              <label
                htmlFor={checkbox.name}
                className='text-muted fw-semibold fs-7 text-uppercase user-select-none'
              >
                {checkbox.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default TableFilterCheckboxGroup
