import clsx from "clsx";
import { DateTimeShortcutButtonProps } from "../date-time-shortcut-types";
import "../date-time-shortcut-styles.scss"

const DateTimeShortcutButton = (props: DateTimeShortcutButtonProps) => {
  const { dateTimeShortcut, onClick, selected } = props

  return (
    <button
      type='button'
      className={clsx(
        'dt-shortcut-button btn btn-sm btn-light',
        { 'selected text-primary fw-bold': selected }
      )}
      onClick={onClick}
    >
      {dateTimeShortcut}
    </button>
  )
}

export default DateTimeShortcutButton
