import TableRow, { TR_STYLE } from "../../../../components/TableRow"
import { ActionHistoryModel } from "../Models"

interface Props {
    index: number
    actionHistory: ActionHistoryModel
}

const ReportActionHistoryRow = (props: Props) => {
    const action_history: ActionHistoryModel = props.actionHistory
    const details: string = JSON.stringify(action_history.details, null, 2)

    return (
        <TableRow
            key={props.index}
            styles={[TR_STYLE.HOVER_ROW]}
            contents={[
                {key: props.index + '_action', detail: <div className="fw-bold">{action_history.action}</div>},
                {key: props.index + '_hour', detail: action_history.hour},
                {key: props.index + '_execution_start_time', detail: action_history.execution_start_time},
                {key: props.index + '_execution_end_time', detail: action_history.execution_end_time},
                {key: props.index + '_status', detail: action_history.status},
                {key: props.index + '_details', extra: <pre className='p-2 code-snippet'>{details}</pre>}
            ]}
        />
    )
}

export default ReportActionHistoryRow