import { FormControl, Modal, Spinner } from "react-bootstrap";
import { createPortal } from "react-dom";
import { Invoice, InvoiceUpdateParams, PaymentStatus, S3File, Site } from "../../Models";
import { ChangeEvent, useEffect, useState } from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import MessageModal from "../../../../components/MessageModal";
import { checkRequestResponse, regexDecimalPlaces, removeEmptyFilters, SelectOptionModel } from "../../../../components/Helpers";
import {
  invoiceBillsDelete,
  invoiceBillsUpload,
  invoiceCreate,
  invoiceGet,
  invoiceReceiptsDelete,
  invoiceReceiptsUpload,
  invoiceUpdate
} from "../../Requests";
import clsx from "clsx";
import moment from "moment";
import File from "../File";
import Swal from "sweetalert2";
import { useAuth } from "../../../auth";

const validationSchema = Yup.object().shape({
  invoice_code: Yup.string().required('Invoice code is required'),
  external_code: Yup.string().required('Invoice No. is required')
    .matches(/^[a-zA-Z0-9-_]+$/, {
      message: 'invoice number must be alpha dash'
    }),
  site_code: Yup.string().nullable(),
  currency_code: Yup.string().nullable(),
  name: Yup.string().optional(),
  amount: Yup.number().positive().moreThan(0, 'invoice amount required')
    .test(
      "is-decimal",
      "Amount can only have 4 decimal places",
      (val: any) => {
        if (val != undefined) {
          return  regexDecimalPlaces(4).test(val);
        }
        return true;
      }
    ).required(),
  content: Yup.string().optional(),
  release_date_time: Yup.date().optional(),
  payment_status: Yup.number().integer().oneOf(PaymentStatus.values()).optional(),
  remark: Yup.string().nullable().optional()
})

const initialValues: InvoiceUpdateParams = {
  invoice_code: "",
  external_code: "",
  site_code: "",
  currency_code: "",
  name: "",
  amount: 0,
  content: "",
  release_date_time: "",
  payment_status: PaymentStatus.Unpaid,
}

const defaultInvoice: Invoice = {
  bills: [],
  code: "",
  external_code: "",
  content: "",
  created_at: "",
  created_by: "",
  currency_code: "",
  name: "",
  amount: 0,
  payment_status: PaymentStatus.Unpaid,
  receipts: [],
  release_date_time: "",
  site_code: "",
  updated_at: "",
  updated_by: ""
}

interface Props {
  show: boolean
  onClose: () => void
  onUpdate: () => void
  invoiceCode: string
}

const InvoiceEditModal = (props: Props) => {
  const [data, setData] = useState<InvoiceUpdateParams>(initialValues)
  const [invoice, setInvoice] = useState<Invoice>(defaultInvoice)
  const [sites, setSites] = useState<Site[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])
  const [selectedSiteCode, setSelectedSiteCode] = useState<string>('')
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>('')
  const [availableCurrencies, setAvailableCurrencies] = useState<SelectOptionModel[]>(currencyOptions)
  const [billInputs, setBillInputs] = useState<JSX.Element[]>([])
  const [receiptInputs, setReceiptInputs] = useState<JSX.Element[]>([])
  const [bills, setBills] = useState<S3File[]>([])
  const [receipts, setReceipts] = useState<S3File[]>([])
  const [billsToDelete, setBillsToDelete] = useState<string[]>([])
  const [receiptsToDelete, setReceiptsToDelete] = useState<string[]>([])
  const auth = useAuth()
  const disableUpdate = !auth.hasPermission('invoice-update')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (props.invoiceCode == '') {
      setInvoice(defaultInvoice)
      return
    }

    loadOptions().then(() => loadSelectedInvoiceDetails(props.invoiceCode))

    addBillInput()
    addReceiptInput()
  }, [props.invoiceCode])

  useEffect(() => {
    const supportedCurrencies = sites.find(site => site.code == selectedSiteCode)?.currency_codes ?? []

    if (selectedSiteCode != invoice.site_code) {
      supportedCurrencies.includes('USD') ? setSelectedCurrencyCode('USD') : setSelectedCurrencyCode('')
    } else {
      setSelectedCurrencyCode(invoice.currency_code)
    }

    setAvailableCurrencies(supportedCurrencies.map(currency => {
      return { value: currency, label: currency }
    }))

    formik.values.site_code = selectedSiteCode
  }, [selectedSiteCode])

  useEffect(() => {
    formik.values.currency_code = selectedCurrencyCode
  }, [selectedCurrencyCode])

  const loadSelectedInvoiceDetails = async (invoiceCode: string) => {
    const response = await invoiceGet({ invoice_code: invoiceCode })

    if (!checkRequestResponse(response)) {
      handleClose()
      MessageModal({ type: 'failed', messages: 'Error loading invoice details' })
      return
    }

    const invoice = response.data.data
    setInvoice(invoice)
    setSelectedSiteCode(invoice.site_code)
    formik.values.invoice_code = invoice.code
    formik.values.external_code = invoice.external_code
    formik.values.name = invoice.name
    setSelectedCurrencyCode(invoice.currency_code)
    formik.values.amount = invoice.amount
    formik.values.content = invoice.content ?? ''
    // for input of valid value possibly falsy (0, '', null)
    formik.values.payment_status = invoice.payment_status
    formik.values.remark = invoice.remark ?? ''

    setBills(invoice.bills)
    setReceipts(invoice.receipts)
  }

  const loadOptions = async () => {
    const createResponse = await invoiceCreate()

    if (!checkRequestResponse(createResponse)) return false

    const createData = createResponse.data.data

    setSites(createData.sites)

    const localStorageCurrencyOptions = localStorage.getItem('currency_options')
    const tempCurrencies = JSON.parse(localStorageCurrencyOptions ?? '[{}]')
    tempCurrencies.sort((a: SelectOptionModel, b: SelectOptionModel) => a.value.localeCompare(b.value))
    tempCurrencies.length > 0 && setCurrencyOptions(tempCurrencies)
  }

  const addBillInput = () => {
    setBillInputs([
      ...billInputs,
      <input
        key={`bill-${billInputs.length}`}
        name='bills[]'
        className='form-control col-6 mt-2'
        type='file'
        accept='.pdf,.jpg,.jpeg,.png,.xls,.xlsx'
      />
    ])
  }

  const addReceiptInput = () => {
    setReceiptInputs([
      ...receiptInputs,
      <input
        key={`receipt-${receiptInputs.length}`}
        name='receipts[]'
        className='form-control col-6 mt-2'
        type='file'
        accept='.pdf,.jpg,.jpeg,.png,.xls,.xlsx'
      />
    ])
  }

  const uploadBills = async (bills: Blob[], notification: boolean) => {
    if (!bills.length) return true

    const formData = new FormData()

    formData.append('invoice_code', invoice.code)
    bills.forEach(bill => formData.append("files[]", bill))
    formData.append('notification', notification.toString())

    const success = checkRequestResponse(await invoiceBillsUpload(formData))

    if (!success) MessageModal({ type: 'failed', messages: 'Failed to upload bill(s)' })

    return success
  }

  const uploadReceipts = async (receipts: Blob[], notification: boolean) => {
    if (!receipts.length) return true

    const formData = new FormData()

    formData.append('invoice_code', invoice.code)
    receipts.forEach(receipt => formData.append("files[]", receipt))
    formData.append('notification', notification.toString())

    const success = checkRequestResponse(await invoiceReceiptsUpload(formData))

    if (!success) MessageModal({ type: 'failed', messages: 'Failed to upload receipt(s)' })

    return success
  }

  const uploadFiles = async () => {    
    let invoiceUneditted: boolean = JSON.stringify(data) === JSON.stringify(formik.values)

    // check receipt inputs
    const receiptInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="file"][name="receipts[]"]')
    const receipts = Array.from(receiptInputs)
      .map(input => input.files?.item(0) as Blob)
      .filter(receipt => receipt) // remove null

    let receiptNotification = receipts.length > 0 && invoiceUneditted && receiptsToDelete.length == 0 && billsToDelete.length == 0

    // check bill inputs
    const billInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="file"][name="bills[]"]')
    const bills = Array.from(billInputs)
      .map(input => input.files?.item(0) as Blob)
      .filter(bill => bill) // remove null

    let billNotification = !receiptNotification && bills.length > 0 && invoiceUneditted && receiptsToDelete.length == 0 && billsToDelete.length == 0

    return await uploadBills(bills, billNotification) && await uploadReceipts(receipts, receiptNotification);
  }

  const deleteFiles = async () => {
    let invoiceUneditted: boolean = JSON.stringify(data) === JSON.stringify(formik.values)

    let receiptNotification = invoiceUneditted && receiptsToDelete.length > 0
    let billNotification = !receiptNotification && invoiceUneditted && billsToDelete.length > 0

    return await deleteBills(billNotification) && await deleteReceipts(receiptNotification)
  }

  const deleteBills = async (notification: boolean) => {
    if (!billsToDelete.length) return true

    let newUploadBillsCount = 0;
    const billInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="file"][name="bills[]"]')

    billInputs.forEach(billInput => newUploadBillsCount += billInput.files?.length ?? 0)

    const totalBillsCount = invoice.bills.length + newUploadBillsCount - billsToDelete.length

    if (!totalBillsCount) {
      MessageModal({ type: 'failed', messages: 'An invoice must have at least one bill' })
      return false
    }

    const deleteBillsResponse = await invoiceBillsDelete({ invoice_code: invoice.code, uuids: billsToDelete, notification: notification.toString() })

    if (!checkRequestResponse(deleteBillsResponse)) {
      MessageModal({ type: 'failed', messages: 'Failed to delete bill(s)' })
      return false
    }

    return true
  }

  const deleteReceipts = async (notification: boolean) => {
    if (!receiptsToDelete.length) return true

    const deleteReceiptsResponse = await invoiceReceiptsDelete({ invoice_code: invoice.code, uuids: receiptsToDelete, notification: notification.toString() })

    if (!checkRequestResponse(deleteReceiptsResponse)) {
      MessageModal({ type: 'failed', messages: 'Failed to delete receipt(s)' })
      return false
    }

    return true
  }

  const fireDeleteConfirmation = async () => {
    return (await Swal.fire<boolean>({
      title: 'Confirm Delete?',
      html: "<div>Are you sure you want to delete the selected file(s)?<div>" +
        "<div><b>Note:</b> This action cannot be undone.</div>",
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-secondary'
      }
    })).value
  }

  const handleClose = () => {
    props.onClose()
    formik.setStatus(null)
    formik.resetForm()
    delete formik.values.remark
    setData(initialValues)
    setSelectedSiteCode('')
    setSelectedCurrencyCode('')
    setBills([])
    setReceipts([])
    setBillInputs([])
    setReceiptInputs([])
    setBillsToDelete([])
    setReceiptsToDelete([])
  }

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      setIsLoading(true)

      try {
        if (billsToDelete.length || receiptsToDelete.length) {
          const confirmDelete = await fireDeleteConfirmation()

          if (!confirmDelete) {
            setSubmitting(false)
            setIsLoading(false)
            return
          }
        }

        if (!await uploadFiles() || !await deleteFiles()) {
          setSubmitting(false)
          setIsLoading(false)
          return false
        }

        if (values.release_date_time) {
          values.release_date_time = moment(values.release_date_time).format('YYYY-MM-DD HH:mm:ss')
        }

        const params = removeEmptyFilters(values)
        params.site_code = selectedSiteCode
        params.currency_code = selectedCurrencyCode

        if (values.content === '') params.content = '' // removed content
        if (values.remark === '') params.remark = '' // removed remark

        const response = await invoiceUpdate(params)

        if (!checkRequestResponse(response)) {
          const errorMessages = response.data.messages
          console.error(errorMessages)
          MessageModal({ type: 'failed', messages: errorMessages })

          setSubmitting(false)
          setIsLoading(false)

          return false
        }

        MessageModal({ type: 'success', messages: 'Changes saved successfully.' })

        props.onUpdate()
        setSubmitting(false)
        setIsLoading(false)
        handleClose()
      } catch (error) {
        console.error(error)
        MessageModal({ type: 'failed' })
      }
    }
  })

  return createPortal(
    <Modal
      id='kt_modal_invoice_edit'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={props.show}
      scrollable
      onHide={handleClose}
    >
      <div className="modal-header px-10 row">
        <div className="col">
          <h2>{disableUpdate ? 'Invoice Details' : 'Edit Invoice'   } ({props.invoiceCode})</h2>
          {
            invoice.code != '' &&
            <div className='mt-2'>Last modified on <b>{invoice.updated_at}</b> by <b>{invoice.updated_by}</b></div>
          }
        </div>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <form
        className={clsx('modal-body px-lg-10', { 'overlay overlay-block': invoice.code == '' })}
        onSubmit={formik.handleSubmit}
        id='invoice-edit-form'
      >
        <div className='row'>
          <div className='col-md-6'>
            <div className='mb-6'>
              <label className='form-label required'>Site</label>
              <select
                {...formik.getFieldProps('site_code')}
                className='form-select form-select-solid'
                disabled={disableUpdate}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setSelectedSiteCode(e.currentTarget.value)
                  formik.handleChange(e)
                }}
                value={selectedSiteCode}
              >
                {sites.map(site => (
                  <option key={site.code} value={site.code}>
                    {site.code}
                  </option>
                ))}
              </select>
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Invoice Code</label>
              <input
                type='text'
                {...formik.getFieldProps('invoice_code')}
                className='form-control form-control-solid'
                placeholder='Enter invoice code'
                value={formik.values.invoice_code || invoice.code}
                disabled
              />
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Invoice No.</label>
              <input
                type='text'
                {...formik.getFieldProps('external_code')}
                className={clsx(
                  'form-control form-control-solid',
                  {'is-invalid': formik.touched.external_code && formik.errors.external_code},
                  {'is-valid': formik.touched.external_code && !formik.errors.external_code,}
                )}
                placeholder='Enter invoice number'
                value={formik.values.external_code}
                readOnly={disableUpdate}
              />
              {formik.touched.external_code && formik.errors.external_code && (
                  <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-danger'>{formik.errors.external_code}</span>
                  </div>
              )}
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Invoice Name</label>
              <input
                type='text'
                {...formik.getFieldProps('name')}
                className='form-control form-control-solid'
                placeholder='Enter invoice name'
                value={formik.values.name}
                readOnly={disableUpdate}
              />
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Currency</label>
              <select
                {...formik.getFieldProps('currency_code')}
                className='form-select form-select-solid'
                disabled={disableUpdate}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  setSelectedCurrencyCode(e.currentTarget.value)
                  formik.handleChange(e)
                }}
                value={selectedCurrencyCode}
              >
                <option value=''>All Currencies</option>
                {availableCurrencies.map(currency =>
                  <option key={currency.value} value={currency.value}>{currency.label}</option>
                )}
              </select>
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Invoice Amount</label>
              <input
                {...formik.getFieldProps('amount')}
                type='number'
                className={clsx(
                  'form-control form-control-solid',
                  {'is-invalid': formik.touched.amount && formik.errors.amount},
                  {'is-valid': formik.touched.amount && !formik.errors.amount,}
                )}
                placeholder='Enter invoice amount'
                value={formik.values.amount}
                readOnly={disableUpdate}
              />
              {formik.touched.amount && formik.errors.amount && (
                  <div className='fv-plugins-message-container'>
                      <span role='alert' className='text-danger'>{formik.errors.amount}</span>
                  </div>
              )}
            </div>
            <div className='mb-6'>
              <label className='form-label'>Invoice Content <span className='text-muted fs-7'>(Optional)</span></label>
              <textarea
                {...formik.getFieldProps('content')}
                className='form-control form-control-solid'
                placeholder='Enter invoice details'
                value={formik.values.content}
                readOnly={disableUpdate}
              />
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Release Date</label>
              <FormControl
                {...formik.getFieldProps('release_date_time')}
                type='datetime-local'
                className='form-control-solid'
                step={1}
                value={formik.values.release_date_time || invoice.release_date_time}
                readOnly={disableUpdate}
              />
            </div>
            <div className='mb-6'>
              <label className='form-label required'>Payment Status</label>
              <div>
                {PaymentStatus.values().map(status =>
                  <div
                    className="form-check form-check-inline me-10"
                    key={`status-${PaymentStatus[status]}`}
                    style={{ height: '3rem', lineHeight: 'normal' }}
                  >
                    <div className='d-flex align-items-center h-100 gap-3'>
                      <input
                        className="form-check-input"
                        type="radio"
                        {...formik.getFieldProps('payment_status')}
                        value={status}
                        id={`status-${PaymentStatus[status]}`}
                        checked={
                          invoice.code != '' &&
                          formik.values.payment_status == status
                        }
                        disabled={disableUpdate}
                      />
                      <label className="form-check-label fs-6" htmlFor={`status-${PaymentStatus[status]}`}>
                        {PaymentStatus[status]}
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-6'>
              <label className='form-label'>Remark <span className='text-muted fs-7'>(Optional)</span></label>
              <textarea
                {...formik.getFieldProps('remark')}
                className='form-control form-control-solid'
                placeholder='Enter remark'
                value={formik.values.remark}
                readOnly={disableUpdate}
              />
            </div>
          </div>

          <div className='col-md-6'>
            <div className='mb-6'>
              <div className="row">
                <label className='form-label required col mb-0 mt-1'>Bills</label>
                {!disableUpdate && 
                  <div className='col d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-active-color-primary py-1 px-2 fs-7'
                      onClick={addBillInput}
                    >
                      + Add Bill
                    </button>
                  </div>
                }
              </div>
              {
                bills.map(bill =>
                  <File
                    key={bill.uuid}
                    file={bill}
                    deletable={true}
                    onDelete={uuid => setBillsToDelete([...billsToDelete, uuid])}
                    onUndo={uuid => setBillsToDelete(billsToDelete.filter(billUuid => billUuid !== uuid))}
                  />
                )
              }
              {!disableUpdate && billInputs}
            </div>

            <div className='mb-6'>
              <div className="row">
                <label className='form-label col mb-0 mt-1'>Receipts <span
                  className='text-muted fs-7'>(Optional)</span></label>
                {!disableUpdate && 
                  <div className='col d-flex justify-content-end'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-active-color-primary py-1 px-2 fs-7'
                      onClick={addReceiptInput}
                    >
                      + Add Receipt
                    </button>
                  </div>
                }
              </div>
              {
                receipts.map(receipt =>
                  <File
                    key={receipt.uuid}
                    file={receipt}
                    deletable={true}
                    onDelete={uuid => setReceiptsToDelete([...receiptsToDelete, uuid])}
                    onUndo={uuid => setReceiptsToDelete(receiptsToDelete.filter(receiptUuid => receiptUuid !== uuid))}
                  />
                )
              }
              {!disableUpdate && receiptInputs}
            </div>
          </div>
        </div>

        {
          invoice.code == '' &&
          <div className="overlay-layer bg-dark bg-opacity-5">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      </form>

      <div className='modal-footer'>
        <button
          type='button'
          disabled={formik.isSubmitting || Object.keys(formik.errors).length !== 0}
          className='btn btn-sm btn-primary me-3'
          onClick={() => formik.handleSubmit()}
          hidden={disableUpdate}
        >
          {isLoading ? 
            <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Saving... </>
            : "Save Changes"
          }
        </button>
        <button
          type='button'
          className='btn btn-sm btn-light-primary'
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
    , document.getElementById('root-modals') || document.body)
}

export default InvoiceEditModal
