import { DateTimeShortcutType } from "./date-time-shortcut-types";

export enum DateTimeShortcuts {
  CustomRange = 'Custom Range',
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This Week',
  LastWeek = 'Last Week',
  Past7Days = 'Past 7 Days',
  ThisMonth = 'This Month',
  LastMonth = 'Last Month',
  Past30Days = 'Past 30 Days',
  ThisYear = 'This Year',
  LastYear = 'Last Year',
}

export const SHORTCUTS_BY_TYPE: {[type in DateTimeShortcutType]: DateTimeShortcuts[]} = {
  day: [
    DateTimeShortcuts.Today,
    DateTimeShortcuts.Yesterday,
  ],
  week: [
    DateTimeShortcuts.ThisWeek,
    DateTimeShortcuts.LastWeek,
    DateTimeShortcuts.Past7Days,
  ],
  month: [
    DateTimeShortcuts.ThisMonth,
    DateTimeShortcuts.LastMonth,
    DateTimeShortcuts.Past30Days,
  ],
  year: [
    DateTimeShortcuts.ThisYear,
    DateTimeShortcuts.LastYear,
  ],
}
