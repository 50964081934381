import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { checkRequestResponse } from "../../../components/Helpers";
import LoadingIndicator from "../../../components/LoadingIndicator";
import MessageModal from "../../../components/MessageModal";
import { monthlyGgrReport } from "../dashboard-requests";
import { MonthlyGgrReport, MonthlyGgrReportChartProps } from "../dashboard-types";

const MonthlyGgrChart = (props: MonthlyGgrReportChartProps) => {
  const { startDate, endDate } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [months, setMonths] = useState<string[]>([])
  const [monthlyGgr, setMonthlyGgr] = useState<MonthlyGgrReport>()
  const [series, setSeries] = useState<ApexAxisChartSeries>([])

  useEffect(() => {
    void loadData()
  }, [])

  useEffect(() => {
    if (monthlyGgr === undefined) return

    const series: ApexAxisChartSeries = []
    const ggrs: number[] = Object.values(monthlyGgr).map((ggr: string) => +ggr)

    series.push({ name: 'GGR (USD)', data: ggrs })
    setSeries(series)
  }, [monthlyGgr])

  const loadData = async () => {
    setIsLoading(true)

    try {
      const response = await monthlyGgrReport({ start_date: startDate, end_date: endDate })

      if (!checkRequestResponse(response)) return

      const data: MonthlyGgrReport = response.data.data as MonthlyGgrReport

      setMonths(Object.keys(data))
      setMonthlyGgr(data)
    } catch (e) {
      console.error(e)
      MessageModal({ type: 'failed', messages: 'Error loading monthly GGR.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='position-relative'>
      <Chart
        type='line'
        series={series}
        options={{
          chart: {
            id: '-monthly-ggr',
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            categories: months,
            labels: {
              style: {
                colors: 'var(--kt-body-color)',
              },
            },
            title: {
              text: 'Month',
              style: {
                color: 'var(--kt-body-color)',
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            title: {
              text: 'GGR (USD)',
              style: {
                color: 'var(--kt-body-color)',
              },
            },
            labels: {
              style: {
                colors: 'var(--kt-body-color)',
              },
              formatter: value => (value ?? 0).toLocaleString(undefined, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            },
          },
        }}
      />
      {isLoading &&
        <div className='position-absolute w-100 h-100 top-0 start-0'>
          <LoadingIndicator />
        </div>
      }
    </div>
  )
}

export default MonthlyGgrChart
