import { AgentPt } from "../../Models";
import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";

interface Props {
  data: AgentPt
  onChange: (gameProviderCode: string, positionTaking: number) => void
  onCancel: (gameProviderCode: string) => void
  edited: boolean
  valid: boolean
  updateSuccess: boolean
  resetUpdateSuccess: () => void
}

const AgentPtRow = (props: Props) => {
  const [enabled, setEnabled] = useState<boolean>(props.data.enabled)
  const [editing, setEditing] = useState<boolean>(false)
  const [pt, setPt] = useState<number>(props.data.position_taking)

  useEffect(() => {
    if (props.updateSuccess) {
      setEditing(false)
      props.resetUpdateSuccess()
    }
  }, [props.updateSuccess])

  const onToggleHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.data.enabled) return // not allowed to disable if initially enabled

    const currentEnabled = e.currentTarget.checked

    setEnabled(currentEnabled)

    if (currentEnabled) {
      setEditing(true)
    } else {
      discardChanges()
    }
  }

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newPtValue: number = +e.currentTarget.value

    setPt(newPtValue)
    props.onChange(props.data.game_provider_code, newPtValue)
  }

  const discardChanges = () => {
    setEditing(false)
    setPt(props.data.position_taking)

    if (!props.data.enabled && enabled) setEnabled(false)

    if (pt != props.data.position_taking) props.onCancel(props.data.game_provider_code)
  }

  return (
    <tr>
      <td className='align-middle fw-bold py-4'>{props.data.game_provider_code}</td>
      <td className='align-middle fw-bold py-4 text-capitalize'>{props.data.game_provider_name}</td>
      <td className='align-middle fw-bold py-4'>
        <div className="form-check form-switch form-check-custom form-check-solid">
          <input
            className="form-check-input w-50px cursor-pointer"
            type="checkbox"
            checked={enabled}
            disabled={props.data.enabled}
            onChange={onToggleHandler}
            id={`enable-${props.data.game_provider_code}`}
          />
        </div>
      </td>
      <td className='align-middle fw-bold py-4 pe-4'>
        <input
          id={props.data.game_provider_code}
          name={props.data.game_provider_code}
          type='number'
          className={clsx(
            'form-control text-end pt-input d-inline-block',
            { 'is-valid': props.edited && props.valid },
            { 'is-invalid': props.edited && !props.valid }
          )}
          style={{ width: '84%' }}
          value={
            editing
              ? pt
              : (Math.round(pt * 100) / 100).toFixed(2)
          }
          onChange={onChangeHandler}
          disabled={!editing}
        />
        <span className='ms-3 fs-6'>{"/ " + (Math.round(props.data.max_pt * 100) / 100).toFixed(2)}</span>
      </td>
      <td className='align-middle position-relative'>
        {
          !editing && enabled &&
          <i
            className='far fa-edit position-absolute start-25 top-50 cursor-pointer'
            style={{ fontSize: '1.5rem', transform: 'translateY(-50%)' }}
            onClick={() => setEditing(true)}
          ></i>
        }
        {
          editing && enabled &&
          <i
            className='fas fa-times position-absolute start-25 top-50 cursor-pointer'
            style={{ fontSize: '2rem', transform: 'translateY(-50%)' }}
            onClick={discardChanges}
          ></i>
        }
      </td>
    </tr>
  )
}

export default AgentPtRow