import { Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import TestTemplatePageContent from "./components/TestTemplatePageContent";


const breadcrumbs: Array<PageLink> = [
  {
    title: 'Test Plans',
    path: '#',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TestTemplatePage = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={breadcrumbs}>Test Templates</PageTitle>
            <TestTemplatePageContent />
          </>
        }
      />
    </Routes>
  )
}

export default TestTemplatePage
