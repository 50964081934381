export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  // remove leading "/"
  pathname = pathname.replace(/^\//, '')
  url = url.replace(/^\//, '')

  const current = getCurrentUrl(pathname)

  if (!current || !url) {
    return false
  }

  return current === url;
}
