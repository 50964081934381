import axios from "axios";
import { TestSessionParams } from "../template/TestTemplateModels";
import { TestSessionIndexParams } from "./TestSessionModels";

const API_URL = process.env.REACT_APP_API_URL

export const TEST_SESSION_INDEX_URL = `${API_URL}/test-session/index`
export const TEST_SESSION_CREATE_URL = `${API_URL}/test-session/store`
export const TEST_SESSION_EDIT_URL = `${API_URL}/test-session/edit`
export const TEST_SESSION_UPDATE_URL = `${API_URL}/test-session/update`



export const testSessionIndex = (params: TestSessionIndexParams) => {
  return axios.post(TEST_SESSION_INDEX_URL, params)
}

export const testSessionCreate = (params: TestSessionParams) => {
  return axios.post(TEST_SESSION_CREATE_URL, params)
}

export const testSessionEdit = (params: {test_session_id: number}) => {
  return axios.post(TEST_SESSION_EDIT_URL, params)
}

export const testSessionUpdate = (params: TestSessionParams) => {
  return axios.post(TEST_SESSION_UPDATE_URL, params)
}
