import MessageModal from "./MessageModal";
import { AxiosResponse } from "axios";
import { MaintenanceStatus } from '../modules/maintenance-site-provider/Models';
import moment from 'moment';

const Helpers = () => {

}

export const formatDateTime = (dateTime: any) => {
  const dateObject = new Date(dateTime)
  const year = dateObject.getFullYear()
  const month = ('0' + (dateObject.getMonth() + 1)).slice(-2)
  const date = ('0' + dateObject.getDate()).slice(-2)
  const hour = ('0' + dateObject.getHours()).slice(-2)
  const minute = ('0' + dateObject.getMinutes()).slice(-2)
  const second = ('0' + dateObject.getSeconds()).slice(-2)

  return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second;
}

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const removeEmptyFilters = (filterParams: any) => {
  const newFilters = { ...(filterParams) }
  let key: keyof typeof newFilters;
  for (key in newFilters) {
    if (newFilters[key]?.toString().trim().length === 0)
      delete newFilters[key]
  }

  return newFilters
}

export const checkRequestResponse = (response: AxiosResponse) => {
  if (response.data.status !== 1 || response.status !== 200) {
    const messages: string | object = response?.data.messages[0] ?? response?.data.messages
    const messagesArray: string[] = []

    if (typeof messages === 'string')
      messagesArray.push(messages)
    else
      Object.entries(messages).map(item => {
        return messagesArray.push(item[1][0])
      })
    MessageModal({ type: 'failed', messages: messagesArray })

    return false
  }

  return true
}

export const isEmpty = (obj: {}) => {
  return Object.keys(obj).length === 0;
}

export const isNumber = (value: any) => {
  value = Number(value)
  return !isNaN(value)
}

export const roundOff = (value: number, dp: number = 2) => {
  const dp_multiplier = Math.pow(10, dp)
  return (Math.round(Math.abs(value) * dp_multiplier) / dp_multiplier * Math.sign(value)).toFixed(dp)
}

export const roundUp = (value: number, dp: number = 2) => {
  const value_plus_one_dp_multiplier = Math.pow(10, dp + 1)
  const dp_multiplier = Math.pow(10, dp)

  const value_plus_one_dp = Math.round(Math.abs(value) * value_plus_one_dp_multiplier) / 10

  return (Math.ceil(value_plus_one_dp) / dp_multiplier * Math.sign(value)).toFixed(dp)
}

export const roundDown = (value: number, dp: number = 2) => {
  const dp_multiplier = Math.pow(10, dp)
  const whole_number = (Math.abs(value) * dp_multiplier)

  return (Math.floor(whole_number) / dp_multiplier).toFixed(dp)
}

export const capitalize = (value: string, everyWord: boolean = false): string => {
  if (value.length == 0) return ''

  if (!everyWord) return value.charAt(0).toUpperCase() + value.slice(1);

  return value
    .split(' ')
    .map((value: string) => capitalize(value))
    .join(' ')
}

export const convertTransferStatus = (value: number | string, badge = false) => {
  let status
  if (!badge) {
    switch (value) {
      case 1:
        return status = 'success'
      case 2:
        return status = 'pending'
      default:
        return status = 'failed'
    }
  } else {
    switch (value) {
      case 'success':
      case 1:
        return status = 'success'
      case 'pending':
      case 2:
        return status = 'warning'
      default:
        return status = 'danger'
    }
  }
}

/**
 * @param key Key of the item in local storage.
 * @param includeAll Determine if the options return should include the "All" option. Default `true`.
 * @param allLabel Label of the "All" option. Default "All".
 */
export const getSelectOptions = (key: string, includeAll: boolean = true, allLabel: string = 'All') => {
  const item = localStorage.getItem(key)
  const parsedOptions = JSON.parse(item ?? '[{}]')

  parsedOptions.sort((a: SelectOptionModel, b: SelectOptionModel) => a.value.localeCompare(b.value))
  if (includeAll) parsedOptions.unshift({ value: '', label: allLabel })

  return parsedOptions.length > 0 ? parsedOptions : []
}

export const sortByKey = <T extends object>(target: T, order: (keyof T)[]) => {
  const sorted: Partial<T> = {}
  const unsortedKeys: Partial<T> = target

  order.forEach((key: keyof T) => {
    if (target.hasOwnProperty(key)) {
      sorted[key] = target[key]
      delete unsortedKeys[key]
    }
  })

  return { ...sorted, ...unsortedKeys } as T
}

export interface SelectOptionModel {
  value: string
  label: string
  isFixed?: boolean
}

// decimal places regex
export const regexDecimalPlaces = (dp: number) => {
  var regex = new RegExp("^\\d+(\\.\\d{0," + dp + "})?$");
  return regex;
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
  Pick<T, Exclude<keyof T, Keys>>
  & {
  [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
}[Keys]

export interface badgeContent {
  text: string | string[]
  colour: string
  fullColour?: boolean
}

export const generateBadges = (badges: badgeContent[], key: string | number) => {
  return badges.map((badge: badgeContent, index: number) => {
    const badgeClass = `badge badge-${badge?.fullColour ? '' : 'light-'}${badge.colour} fs-7 fw-semibold ${badge?.fullColour ? 'text-light' : ''}`

    if (Array.isArray(badge.text)) {
      return badge.text.map((text: string, index2: number) => {
        return (
          <span className={`${badgeClass} fs-6 ms-2 mb-1`} key={key + text + '_badge_' + index + index2}>{text}</span>
        )
      })
    } else {
      return badge.text !== ''
        ? <div key={key + badge.text + '_badge_' + index}>
          <span className={badgeClass}>{badge.text}</span><br />
        </div>
        : <div key={key + badge.text + '_badge_' + index}></div>
    }
  })
}

export const maintenanceStatusBadge = (maintain_from: string, maintain_to: string, status: MaintenanceStatus) => {
  const now = moment()
  const start = moment(maintain_from)
  const end = moment(maintain_to).set('seconds', 0)

  let badge: string = '', text: string = ''

  if (status == MaintenanceStatus.Inactive) {
    badge = 'danger'
    text = 'Inactive'
  } else if (status == MaintenanceStatus.Active) {
    if (now.isBefore(start)) {
      badge = 'warning'
      text = 'Upcoming'
    } else if (now.isBetween(start, end)) {
      badge = 'success'
      text = 'Ongoing'
    } else if (now.isAfter(end)) {
      badge = 'primary'
      text = 'Finished'
    }
  }

  return <span className={`badge badge-light-${badge} fs-7 fw-semibold text`}>{text}</span>
}


export const freezeColumn = (columns: number) => {
  let freezeColHeaderClass: string = columns > 0 ? 'freeze-col-header-' + columns : ''
  return freezeColHeaderClass
}

export const formatValue = (value: number, dp: number = 2) => {
  if (!isNaN(value)) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: dp,
      maximumFractionDigits: dp,
    });
  }
  return value
}

export default Helpers
